// import _ from 'lodash';
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import blobHelper from "../../../blobUrlFormatHelper";
// import { IMoveableProps } from '../../../Moveable/models/IMoveableProps';
// import MoveableClass from '../../../Moveable/MoveableClass';
// import { handleKeyPress } from '../../../ObjectPropertyBox/functions/PropertyBoxFunctions';
// import Symbol from '../../../Symbol/Symbol';
// import ISymbol from '../../../Symbol/models/ISymbol';
import { IIGA, IIGAHotSpot, IIGAImageHotspot } from "../../models/IIGA";
import HotSpotCanvas from "../HotSpotGenerator/HotSpotCanvas";

type IGSType = {
  // annotationProps: AnnotationType,
  // containerRef: React.RefObject<HTMLDivElement>
  currentIGAStep: IIGA;
  currentImagePath?: string;
  currentStep: number;
  // imgRef: React.RefObject<HTMLImageElement>,
  isHotSpotActive: boolean;
  // moveableProps: IMoveableProps,
  question: string;
  addInteractiveGraphic: () => void;
  addOrUpdateImageDims: (imgDims: DOMRect) => void;
  handleHotspotChange: (value: IIGAHotSpot, childDims: DOMRect) => void;
  handleTaskChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setHotSpot: (hotSpot: IIGAHotSpot) => void;
};

const IGSInteractive = (props: IGSType) => {
  const {
    currentIGAStep,
    currentImagePath,
    isHotSpotActive,
    question,
    addInteractiveGraphic,
    handleHotspotChange,
    handleTaskChange,
    setHotSpot,
  } = props;
  const imgRef: React.RefObject<HTMLImageElement> = useRef(null);
  const [childDims, setChildDims]: [DOMRect | undefined, Dispatch<SetStateAction<DOMRect | undefined>>] = useState<
    DOMRect | undefined
  >();
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const imgRect: DOMRect = e.currentTarget.getBoundingClientRect() as DOMRect;
    setChildDims(imgRect);
    setIsLoading(false);
  };

  // function getOldHotspotLocation(imgRect: DOMRect) {
  //   const oldMax: number = 400;

  //   const percent: number = imgRect.height > imgRect.width ? imgRect.height / oldMax : imgRect.width / oldMax;

  //   let clone: IIGAHotSpot = _.cloneDeep((currentIGAStep.images as IIGAImageHotspot).imageHotSpot) as IIGAHotSpot;
  //   clone = {
  //     top: clone.top * percent,
  //     bottom: clone.bottom * percent + clone.top * percent,
  //     left: clone.left * percent,
  //     right: clone.right * percent + clone.left * percent
  //   }
  //   return _.cloneDeep(clone);
  // }

  return (
    <React.Fragment>
      <div className="iga-interactive-task-container">
        <label htmlFor="iga-task-description">Task Description:</label>
        <textarea
          id="iga-task-description"
          name="iga-task-description"
          value={question}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTaskChange(e)}
        ></textarea>
        {/* {displayAddOrEdit()} */}
      </div>
      <div
        className={`iga-interactive-graphic-container${isHotSpotActive ? "" : " interactive-hovering"}`}
        onClick={addInteractiveGraphic}
      >
        <div className="img-aspect-container">
          <div className="aspect-container-inside">
            <div className="aspect-centering">
              {/* {isHotSpotActive === true ? <><InteractionHelper imageOne={blobHelper(currentImagePath)} currentStepObject={currentIGAStep} currentHotSpot={currentIGAStep.images?.imageHotSpot} handleHotspotChange={handleHotspotChange} /> </> : <> */}
              {
                isHotSpotActive === true ? (
                  <React.Fragment>
                    {!isLoading && childDims ? (
                      <HotSpotCanvas
                        // currentStepObject={currentIGAStep}
                        childDims={childDims as DOMRect}
                        currentHotSpot={(currentIGAStep.images as IIGAImageHotspot).imageHotSpot}
                        currentStep={currentIGAStep}
                        handleHotspotChange={handleHotspotChange}
                        setHotSpot={setHotSpot}
                      />
                    ) : (
                      <></>
                    )}
                    <img
                      ref={imgRef}
                      className="igs-interactive-graphic-img"
                      id="graphicImage"
                      src={blobHelper(currentImagePath as string)}
                      alt=""
                      onLoad={(e: React.SyntheticEvent<HTMLImageElement, Event>) => handleImageLoad(e)}
                    />
                  </React.Fragment>
                ) : (
                  // <React.Fragment>
                  <img
                    className="igs-interactive-graphic-img"
                    id="graphicImage"
                    src={blobHelper(currentImagePath as string)}
                    alt=""
                  />
                )
                //   {annotationProps.annotations && annotationProps.annotations.length > 0 ? <Annotation {...annotationProps} /> : <></>}
                //   {annotationProps.symbols && annotationProps.symbols.length > 0 ? <Symbol kp={annotationProps.kp} symbols={annotationProps.symbols as ISymbol[]} handleKeyPress={handleKeyPress} markSelected={annotationProps.markSelected} /> : <></>}
                //   {moveableProps.target
                //     ?
                //     <MoveableClass {...moveableProps} />
                //     :
                //     <></>
                //   }
                // </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IGSInteractive;
