import React from "react";
import _ from "lodash";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

import "./BackgroundColorChooser.css";

import { PageContext } from "../../../../routes/builderContexts";
import { usePageColor } from "../../../../contexts/PageColorProvider/PageColorProvider";
const DEFAULT_SHOW_CPAT_ICON = true;
const GRADIENT_COLORS_AMOUNT = 2;

export default function BackgroundColorChooser() {
  const pageContext = React.useContext(PageContext);
  const [selectedBox, setSelectedBox] = React.useState(0);
  const [showing, setShowing] = React.useState(false);
  const [pageColorState, setPageColorState] = usePageColor();
  return (
    <div
      className="pageEditorActionListerRow"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "3px",
        backgroundColor: showing ? "var(--buttons-color)" : "",
        color: "white",
        padding: "4px",
        width: "95%",
        borderRadius: "4px",
      }}
    >
      <div
        className="pageListerActionButton"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowing(!showing);
        }}
      >
        Change Canvas Color
      </div>
      {showing && (
        <>
          <hr className="background-coolor-hr" />
          <div>
            ColorFill
            <br />
            <select
              style={{
                alignSelf: "center",
                width: "100%",
              }}
              value={pageContext.pageManifest?.theme?.colorFill ?? "solid"}
              onChange={(option) => {
                if (option.target.value === "solid") {
                  setSelectedBox(0);
                }
                const pm = _.cloneDeep(pageContext.pageManifest);
                // lazy initialize theme
                pm.theme ?? (pm.theme = {});
                // lazy initialize colors
                pm.theme.colors ?? (pm.theme.colors = []);
                // lazy initialize colorFill
                pm.theme.colorFill ?? (pm.theme.colorFill = "solid");
                pm.theme.colorFill = option.target.value;
                if (pm.theme.colorFill === "gradient") {
                  if (!pm.theme.colors[0]) {
                    pm.theme.colors[0] = { color: "#ffffff" };
                  }
                  if (!pm.theme.colors[1]) {
                    pm.theme.colors[1] = { color: "#aaaaff" };
                  }
                }
                pageContext.updatePageManifest(pm);
              }}
            >
              <option value="solid">Solid</option>
              <option value="gradient">Gradient</option>
            </select>
          </div>
          <HexAlphaColorPicker
            style={{ width: "88%", height: "185px", alignSelf: "center" }}
            color={pageContext.pageManifest?.theme?.colors?.[selectedBox]?.color ?? "#ffffff"}
            onChange={(color) => {
              const pm = _.cloneDeep(pageContext.pageManifest);
              // lazy initialize theme
              pm.theme ?? (pm.theme = {});
              // lazy initialize colors
              pm.theme.colors ?? (pm.theme.colors = []);
              // lazy initialize colorFill
              pm.theme.colorFill ?? (pm.theme.colorFill = "solid");
              pm.theme.colors[selectedBox] = { color };
              if (pageColorState.applyingToNewPage === true) {
                setPageColorState({
                  type: "UPDATE_THEME",
                  payload: pm.theme,
                });
              }
              pageContext.updatePageManifest(pm);
            }}
          />

          {pageContext.pageManifest?.theme?.colorFill !== "gradient" ? (
            <>
              <HexColorInput
                className={`chooser-color-${0}`}
                style={{
                  width: "95%",
                  alignSelf: "center",
                }}
                onMouseDown={() => {
                  setSelectedBox(0);
                }}
                color={pageContext.pageManifest?.theme?.colors?.[0]?.color}
                onChange={(color) => {
                  const pm = _.cloneDeep(pageContext.pageManifest);
                  // lazy initialize theme
                  pm.theme ?? (pm.theme = {});
                  // lazy initialize colors
                  pm.theme.colors ?? (pm.theme.colors = []);
                  // lazy initialize colorFill
                  pm.theme.colorFill ?? (pm.theme.colorFill = "solid");
                  pm.theme.colors[0] = { color };
                  if (pageColorState.applyingToNewPage === true) {
                    setPageColorState({
                      type: "UPDATE_THEME",
                      payload: pm.theme,
                    });
                  }
                  pageContext.updatePageManifest(pm);
                }}
              />
            </>
          ) : null}

          {pageContext.pageManifest?.theme?.colorFill === "gradient"
            ? Array(GRADIENT_COLORS_AMOUNT)
                .fill(undefined)
                .map((nothing, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <input
                        style={{
                          width: "10px",
                        }}
                        type="radio"
                        checked={selectedBox === i}
                        onClick={() => {
                          setSelectedBox(i);
                        }}
                      />
                      <HexColorInput
                        className={`chooser-color-${i}`}
                        onMouseDown={() => {
                          setSelectedBox(i);
                        }}
                        color={pageContext.pageManifest?.theme?.colors?.[i]?.color}
                        onChange={(color) => {
                          const pm = _.cloneDeep(pageContext.pageManifest);
                          // lazy initialize theme
                          pm.theme ?? (pm.theme = {});
                          // lazy initialize colors
                          pm.theme.colors ?? (pm.theme.colors = []);
                          // lazy initialize colorFill
                          pm.theme.colorFill ?? (pm.theme.colorFill = "solid");
                          pm.theme.colors[i] = { color };
                          if (pageColorState.applyingToNewPage === true) {
                            setPageColorState({
                              type: "UPDATE_THEME",
                              payload: pm.theme,
                            });
                          }
                          pageContext.updatePageManifest(pm);
                        }}
                      />
                    </div>
                  );
                })
            : null}
          <hr className="background-coolor-hr" />
          <button
            className="color-picker-button"
            onClick={() => {
              pageContext.updateAllPagesTheme(pageContext.pageManifest.theme);
            }}
          >
            Apply to All pages
          </button>

          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "75%",
              margin: "0",
            }}
          >
            Apply to new pages
            <input
              type="checkbox"
              checked={pageColorState.applyingToNewPage ?? false}
              onChange={() => {
                setPageColorState({ type: "TOGGLE_APPLYING_TO_NEW_PAGE" });
                if (pageColorState.applyingToNewPage === false) {
                  setPageColorState({
                    type: "UPDATE_THEME",
                    payload: pageContext.pageManifest.theme,
                  });
                } else {
                  setPageColorState({ type: "UPDATE_THEME", payload: {} });
                }
              }}
            />
          </label>
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "75%",
              margin: "0",
            }}
          >
            Show CPaT Icon
            <input
              type="checkbox"
              checked={pageContext.pageManifest?.theme?.showCPaTIcon ?? DEFAULT_SHOW_CPAT_ICON}
              onChange={() => {
                const pm = _.cloneDeep(pageContext.pageManifest);
                // lazy initialize theme
                pm.theme ?? (pm.theme = {});
                pm.theme.showCPaTIcon = !(pm.theme.showCPaTIcon ?? DEFAULT_SHOW_CPAT_ICON);
                pageContext.updatePageManifest(pm);
              }}
            />
          </label>
          <hr className="background-coolor-hr" />
          <button
            className="color-picker-button"
            onClick={() => {
              const pm = _.cloneDeep(pageContext.pageManifest);
              // lazy initialize theme
              pm.theme = {};
              pageContext.updatePageManifest(pm);
              setSelectedBox(0);
              if (pageColorState.applyingToNewPage === true) {
                setPageColorState({ type: "UPDATE_THEME", payload: {} });
              }
            }}
          >
            Default Background
          </button>
        </>
      )}
    </div>
  );
}
