/**
 * The useObjectPosition hook returns the position of an object in the viewport.
 *
 * specification:
 *
 * -----------OLD-----------CURRENT (final output)------------------
 * width:      %   ------>    %
 * height:     %   ------>    %
 * left:       %   ------>    px - transformX
 * top:        %   ------>    px - transformY
 *
 *
 * notes:
 *
 *
 *
 */

import { useMemo } from "react";
import { useMovableElementsPlaneState } from "../contexts/MovableElementsPlaneProvider";
import { useInterpolatedFrame } from "./useInterpolatedFrame";
import {
  percentageToValue,
  percentageToPX,
  xAxisViewPercentageToPixel,
  yAxisViewPercentageToPixel,
} from "../utils/Conversion";
import { isNumber } from "../utils";

export function useObjectPosition(
  objectId: string,
  percentageTop: number,
  percentageLeft: number,
  percentWidth?: number,
  percentHeight?: number,
  rotationDeg?: number,
  opacity?: number,
) {
  const { viewportDOMElementWidth, viewportDOMElementHeight } = useMovableElementsPlaneState();
  const {
    transformX,
    transformY,
    height: interHeight,
    width: interWidth,
    rotation,
    interpolatedOpacity,
  } = useInterpolatedFrame(objectId);

  const position = useMemo(() => {
    const masterX = isNumber(transformX)
      ? transformX
      : isNumber(percentageLeft)
      ? xAxisViewPercentageToPixel(percentageLeft)
      : 0;

    const masterY = isNumber(transformY)
      ? transformY
      : isNumber(percentageTop)
      ? yAxisViewPercentageToPixel(percentageTop)
      : 0;

    return [masterX, masterY];
  }, [transformX, percentageLeft, viewportDOMElementWidth, transformY, percentageTop, viewportDOMElementHeight]);
  const size = useMemo(() => {
    const masterWidth = isNumber(interWidth)
      ? xAxisViewPercentageToPixel(interWidth) // percent inside frames
      : isNumber(percentWidth)
      ? xAxisViewPercentageToPixel(percentWidth)
      : 0;

    const masterHeight = isNumber(interHeight)
      ? yAxisViewPercentageToPixel(interHeight) // percent inside frames
      : isNumber(percentHeight)
      ? yAxisViewPercentageToPixel(percentHeight)
      : 0;
    return [masterWidth, masterHeight];
  }, [interWidth, percentWidth, interHeight, percentHeight, viewportDOMElementWidth, viewportDOMElementHeight]);

  const rotationValue = useMemo(() => {
    const masterRotation = isNumber(rotation) ? rotation : isNumber(rotationDeg) ? rotationDeg : 0;
    return masterRotation;
  }, [rotation, rotationDeg]);

  const opacityValue = useMemo(() => {
    if (typeof interpolatedOpacity !== "number") {
      return opacity;
    } else {
      return interpolatedOpacity;
    }
  }, [opacity, interpolatedOpacity]);

  return { position, size, rotation: rotationValue, opacity: opacityValue }; // in px
}
