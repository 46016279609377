import _ from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SVGAnnotationProps } from "../index";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { handleKeyPress, handleKeyUp } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGSquare({
  x,
  y,
  objectId,
  onMouseDown,
  isDisplayed,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
}: SVGAnnotationProps) {
  const { annotations } = useObjectsState();
  const squareAnnotations = annotations.filter((annotation: any) => annotation.type === "squareLetter");
  const index = squareAnnotations.findIndex((annotation: any) => annotation.objectId === objectId);
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  return (
    <svg
      // viewBox={`0 0 100 auto`}
      width="100"
      data-objectid={objectId}
      id={`square-${index}`}
      tabIndex={0}
      name="square"
      className="annotation"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      style={{
        transform: transformString,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
    >
      <HighlightAnnotation objectId={objectId} id="glow-rect-for-square" />
      <rect
        x=".5%"
        y=".5%"
        height="98.5%"
        width="99%"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth || "4"}`}
      ></rect>
    </svg>
  );
}
