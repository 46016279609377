import React from "react";

import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGRoundedSquare({
  strokeWidth,
  borderColor,
  height,
  width,
  objectId,
  onMouseDown,
  opacity,
  rotate,
  x,
  y,
  backgroundColor,
  zIndex,
  isDisplayed,
}: SVGAnnotationProps) {
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  return (
    <svg
      // viewBox={`0 0 100 auto`}
      width="100"
      id={`roundedSquare-${objectId}`}
      data-objectid={objectId}
      tabIndex={0}
      name="roundedSquare"
      className="annotation"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      // preserveAspectRatio="xMinYMin meet"
      opacity={opacity}
      style={{
        transform: transformString,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
    >
      <HighlightAnnotation objectId={objectId} id="glow-rect-for-square" />
      <rect
        x=".5%"
        y=".5%"
        height="98.5%"
        width="99%"
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth || "4"}`}
        rx="6"
      ></rect>
    </svg>
  );
}
