import React, { useRef, useMemo, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const hsParams = {
  square: {
    lineWidth: 2,
    lineHeight: 2,
    dashSize: 0.02,
    gapSize: 0.01,
  },
  sphere: {
    lineWidth: 100,
    dashSize: 0.005,
    gapSize: 0.005,
  },
};
type VisualHotspotProps = {
  meshRef: React.RefObject<any> | undefined | null;
  isSphere: boolean;
  selected: boolean;
  opacity?: number;
};
function VisualHotspot(props: VisualHotspotProps) {
  const lineRef = useRef<THREE.Line>();
  const materialRef = useRef<THREE.MeshPhysicalMaterial>(null);
  const { meshRef, isSphere, selected } = props;
  const lineParams = isSphere ? hsParams.sphere : hsParams.square;
  const { dashSize, gapSize } = lineParams;
  const cubeGeometry = useMemo(() => new THREE.BoxBufferGeometry(1, 1, 1), []);
  const sphereGeometry = useMemo(() => new THREE.SphereBufferGeometry(1, 32, 32), []);
  const alias = isSphere ? sphereGeometry : cubeGeometry;

  useEffect(() => {
    let isUnMounted = false;
    if (!lineRef.current || isUnMounted || isSphere) return;
    lineRef.current.computeLineDistances(); // this is necessary to get the dashed lines
    return () => {
      isUnMounted = true;
    };
  }, [lineRef.current, isSphere]);

  useFrame((state) => {
    if (!selected || !materialRef.current) return;
    // this just makes the opacity fluctuate if the hotspot is selected
    const opacity = Math.abs(Math.sin(state.clock.getElapsedTime() * 2)) * 0.85; // multiply getElapsedTime by different scales to get faster or slower fluctuations
    materialRef.current.opacity = opacity;
  });

  return (
    <>
      <mesh ref={meshRef} geometry={alias}>
        {!isSphere && (
          <lineSegments ref={lineRef} rotation={[0, 0, 0]} renderOrder={1}>
            <edgesGeometry attach="geometry" args={[cubeGeometry]} />
            <lineDashedMaterial dashSize={dashSize} gapSize={gapSize} attach="material" color={"#90EE90"} />
          </lineSegments>
        )}
        <meshPhysicalMaterial
          ref={materialRef}
          attach="material"
          color={"#7cb342"}
          transparent
          opacity={props.opacity ? props.opacity : selected ? 0.65 : 0.25}
        />
      </mesh>
    </>
  );
}

export default VisualHotspot;
