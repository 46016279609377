import * as AssetManagerTypes from "../AssetManager.types";
import IAsset from "../../../models/IAsset";

export const selectAsset: AssetManagerTypes.selectAsset = (state, action) => {
  function massageAsset(asset: IAsset): AssetManagerTypes.CurrentSelectedAsset {
    return {
      assetId: asset.assetVersionId,
      blobPath: asset.blobPath,
      name: asset.name,
      description: asset.description,
      assetTypeName: asset.assetTypeName,
      isLibraryItem: asset.isLibraryItem,
      errorMessage: null,
      tags: asset.tags,
      manufacturerFleetVariantDtos: asset.manufacturerFleetVariantDtos,
      isArchived: asset.isArchived,
    };
  }

  return { ...state, currentSelectedAsset: massageAsset(action.payload) };
};

export const setSelectedAircraftData: AssetManagerTypes.setSelectedAircraftData = (state, action) => {
  return {
    ...state,
    currentlySelectedAircraftData: {
      ...state.currentlySelectedAircraftData,
      ...action.payload,
    },
  };
};

export const setRetrievedAssets: AssetManagerTypes.setRetrievedAssets = (state, action) => {
  return { ...state, retrievedData: [...action.payload] };
};

export const setIsBusy: AssetManagerTypes.setIsBusy = (state, action) => {
  return { ...state, assetsAreLoading: action.payload };
};
