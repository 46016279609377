import React, { PropsWithChildren, useMemo } from "react";

import { useGlobalAudioState } from "../../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { TOP_STRIP_HEIGHT, WAVE_HEIGHT } from "../TimelinePanel";

import HorizontallyScrollableArea from "./HorizontallyScrollableArea";
import ObjectRow from "./ObjectRow/ObjectRow";
import SequenceLengthIndicator from "./SequenceLengthIndicator";
import { useLessonPagesState } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";
import Scrubber from "./Scrub/Scrubber";
const ROW_HEIGHT = 28;
function calculateHeight(objs: any[]): number {
  let totLength = 0;
  objs.forEach((o) => {
    totLength += ROW_HEIGHT;
  });
  return totLength;
}
function Right() {
  const [timeline] = useTimeline();
  const height = useMemo(() => {
    if (timeline.heightInPx) {
      return TOP_STRIP_HEIGHT + Math.max(timeline.heightInPx, calculateHeight(timeline.objs)) + 6;
    } else {
      return TOP_STRIP_HEIGHT + calculateHeight(timeline.objs) + 300 + 6;
    }
  }, [timeline.heightInPx, timeline.objs]);
  return (
    <HorizontallyScrollableArea height={height}>
      <ListContainer>
        <ObjectRow />
      </ListContainer>
      <SequenceLengthIndicator />
    </HorizontallyScrollableArea>
  );
}

export const contentWidth = 1000000;
/**
 *
 * @param param0
 * @returns
 */
function ListContainer({ children }: PropsWithChildren<any>) {
  const gas = useGlobalAudioState();
  return (
    <ul
      style={{
        margin: 0,
        padding: 0,
        listStyle: "none",
        position: "absolute",
        left: 0,
        width: `${contentWidth}px`,
        top: TOP_STRIP_HEIGHT + (gas.audioOnPageExists ? WAVE_HEIGHT : 0),
        overflow: "hidden",
        paddingBottom: "6px",
      }}
    >
      {children}
    </ul>
  );
}
export default Right;
