import React, { useState, useEffect, Suspense } from "react";
import { connect } from "react-redux";
import Controls from "./controls";
import Cabin from "./cabin";
import HSR from "./rigger";
import ListIGA from "../../../components/ListIGA/IGA";
import MagicCover from "./loadingScreen";
import { blankStep } from "../../../components/IGA/helpers/initialSteps";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import {
  setBuildGrid,
  setdisplayStyle,
  setCurtain,
  setStepType,
  setMove,
  setMoveTarget,
} from "../../../store/actions/actions";
import { Boundaries } from "../hotSpotEditor/types";
import { PageSceneProps } from "./types";
import SceneLights from "../../Generic3DFiles/lights";

type IGAProps = {
  currentIGA: any;
  displayStyle: string;
  handleSave: (IGAObject: any) => void;
  assetManagerProps: any;
  handleIGAClose: () => void;
  hsIndex: number;
};
const IGADisplay = (props: IGAProps) => {
  let IGAObject = props.currentIGA;
  if (!IGAObject) {
    IGAObject = blankStep;
  }
  if (props.displayStyle === "block") {
    return (
      <div className="listIGA-backdrop">
        <div className="absolute-container">
          <div id="procedures-iga-container">
            <ListIGA
              assetManagerProps={props.assetManagerProps}
              currentIGA={IGAObject}
              handleSave={props.handleSave ? props.handleSave : () => {}}
              handleClose={props.handleIGAClose}
              hsIndex={props.hsIndex}
            />
            <div></div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
const PageScene = (props: PageSceneProps) => {
  THREE.Cache.enabled = true;

  const { modelSetPath: modelPath } = props;
  const [modelNumber, setModelNumber] = useState("");
  const [boundaries, setBoundaries] = useState<Boundaries | null>(null);
  const [sceneWidth, setSceneWidth] = useState(99);
  const [modelLoaded, setModelLoaded] = useState(false);
  useEffect(() => {
    if (!modelPath) return;
    const tempModelArray = modelPath.split("/");
    const tempModelNumber = tempModelArray[tempModelArray.length - 1];
    setModelNumber(tempModelNumber);
  }, [modelPath]);

  const checkActiveHotSpot = (index: number) => {
    props.setHsIndex(index);
    props.setdisplayStyle("block");
  };

  const handleIGAClose = () => {
    props.setdisplayStyle("");
  };

  const finishedLoadingModel = () => setModelLoaded(true);
  const finishedLoading = () => {
    props.setCurtain(false);
    setSceneWidth(100);
  };

  useEffect(() => {
    if (modelLoaded) {
      finishedLoading();
    }
    return () => {
      props.setCurtain(true);
    };
  }, [modelLoaded]);

  return (
    <>
      <MagicCover />
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: `${sceneWidth}%`,
          backgroundColor: `${props.displayStyle === "block" ? "#283D55" : "inherit"}`,
        }}
      >
        <Canvas>
          <SceneLights env={"cabin"} />
          <Controls
            currentHSPosition={props.HotSpotList?.[props.hsIndex]?.position ?? [0, 7, 0]}
            hsIndex={props.hsIndex}
            boundaries={boundaries}
            isSphere={props.isSphere}
          />
          <Suspense>
            <HSR
              isSphere={Boolean(props.isSphere)}
              HotSpotList={props.HotSpotList ?? []}
              checkActiveHotSpot={checkActiveHotSpot}
              proximityData={props.proximityData}
              hsIndex={props.hsIndex}
            />
            <Cabin
              setBoundaries={setBoundaries}
              modelPath={modelPath ?? ""}
              callBack={finishedLoadingModel}
            />
          </Suspense>
        </Canvas>
      </div>
      <IGADisplay
        currentIGA={props.currentIGA}
        displayStyle={props.displayStyle}
        handleSave={props.handleSave ? props.handleSave : () => {}}
        assetManagerProps={props.assetManagerProps}
        handleIGAClose={handleIGAClose}
        hsIndex={props.hsIndex}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    move: state.Current3dPage.move,
    moveTarget: state.Current3dPage.moveTarget,
    stepSystemDisplay: state.Current3dPage.stepSystemDisplay,
    displayStyle: state.Current3dPage.displayStyle,
    role: state.Current3dPage.Manifest.role,
    condition: state.Current3dPage.condition,
    buildGrid: state.Current3dPage.walkAroundBuildGrid,
  };
};

export default connect(mapStateToProps, {
  setBuildGrid,
  setdisplayStyle,
  setCurtain,
  setStepType,
  setMove,
  setMoveTarget,
})(PageScene);
