import React from "react";
import { GlobalAudioProvider } from "./GlobalAudioProvider/GlobalAudioProvider";
import { LessonDataProvider } from "./LessonDataProvider";
import { LessonLibraryProvider } from "./LessonLibrary/LessonLibraryProvider";
import { PageListProvider } from "./PageListProvider/PageListProvider";
import { MiscUIProvider } from "./MiscUI/MiscUIProvider";
import { SelectedObjectProvider } from "./SelectedObjectProvider/SelectedObjectProvider";
import { LessonPagesProvider } from "./LessonPagesProvider/LessonPagesProvider";
import { ObjectsProvider } from "./ObjectsProvider";
import { InteractivityProvider } from "../components/Interactivity/Provider/InteractivityProvider";
import { TimelineProvider } from "./TimelineProvider/TimelineProvider";
import { MovableElementsPlaneProvider } from "./MovableElementsPlaneProvider";
import { Tab, Table } from "react-bootstrap";
import { TablesDataProvider } from "../components/Tables/TablesDataProvider";

// in here we can stack providers utilized by all components
//so the main builder file does not have to appear so cluttered
const GlobalContextState = React.createContext(null);
const GlobalContextDispatch = React.createContext(null);

const GlobalProvider = ({ children }: any) => {
  //   const [state, reducer] = React.useReducer()
  return (
    <LessonPagesProvider>
      <GlobalContextState.Provider value={null}>
        <GlobalContextDispatch.Provider value={null}>
          <LessonDataProvider>
            <MiscUIProvider>
              <GlobalAudioProvider>
                <TimelineProvider>
                  <InteractivityProvider>
                    <MovableElementsPlaneProvider>
                      <ObjectsProvider>
                        <SelectedObjectProvider>
                          <LessonLibraryProvider>
                            <PageListProvider>{children}</PageListProvider>
                          </LessonLibraryProvider>
                        </SelectedObjectProvider>
                      </ObjectsProvider>
                    </MovableElementsPlaneProvider>
                  </InteractivityProvider>
                </TimelineProvider>
              </GlobalAudioProvider>
            </MiscUIProvider>
          </LessonDataProvider>
        </GlobalContextDispatch.Provider>
      </GlobalContextState.Provider>
    </LessonPagesProvider>
  );
};

export default GlobalProvider;
