import { useMemo } from "react";
import { ReactComponent as XIcon } from "../../../../assets/icons/x-Icon.svg";
import { ObjectActionsType, useObjectsState, useObjectsDispatch } from "../../../../contexts/ObjectsProvider";
import { HotspotTarget } from "../../../../types";
import { NON_TARGETABLE_BY_HOTSPOT_OBJECTS } from "./ClickGroup";

export function ListedObject({
  onTargetChange,
  target,
  alreadyUsedIds,
  onDeleteTarget,
  clickIndex,
  hotspotObjectId,
}: {
  onTargetChange: (target: string) => void;
  onDeleteTarget: (target: string) => void;
  hotspotObjectId: string;
  clickIndex: number;
  target: HotspotTarget;
  alreadyUsedIds: string[];
}) {
  const objectsDispatch = useObjectsDispatch();
  const rotationValue = target.tasks.find((task) => {
    return task.mappedAction === "ROTATE";
  })?.actionValue;
  const visibilityValue = target.tasks.find((task) => {
    return task.mappedAction === "SHOW/HIDE";
  })?.actionValue;
  const { objectList } = useObjectsState();

  const filteredList = useMemo(() => {
    return objectList.filter((obj) => {
      if (NON_TARGETABLE_BY_HOTSPOT_OBJECTS.includes(obj.type)) {
        return false;
      }

      if (obj.objectId === target.objectId) {
        return true;
      }

      if (alreadyUsedIds.includes(obj.objectId)) {
        return false;
      }

      return true;
    });
    // these might be failing referential equality, no time to check for optimization
    //TODO memoize this expensive operation
  }, [objectList, alreadyUsedIds]);

  return (
    <div className="task-container">
      <div className="property-box-object-list">
        <XIcon
          onClick={() => {
            // delete target
            onDeleteTarget(target.objectId);
          }}
        />
        <select
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            paddingLeft: "10px",
            paddingRight: "25px",
            height: "auto",
          }}
          value={target.objectId}
          onChange={(e) => {
            // change the target id, as well as the ids in the tasks
            const selectedId = e.currentTarget.value;
            onTargetChange(selectedId);
          }}
        >
          {filteredList.map((obj, i) => {
            return (
              <option value={obj.objectId} key={obj.objectId + i}>
                {obj.displayName ?? obj.name}
              </option>
            );
          })}
        </select>
      </div>

      <div className="hotspot-action-rotation">
        {/* <div className="hotspot-action-rotation" style={type === "table" ? disabledStyle : {}}> */}
        <div>
          <div>Rotation (Degrees)</div>
        </div>
        <input
          value={rotationValue?.toString() ?? 0}
          onChange={(e) => {
            const value = Number(e.currentTarget.value);
            objectsDispatch({
              type: ObjectActionsType.UPDATE_HOTSPOT,
              payload: {
                objectId: hotspotObjectId,
                action: {
                  type: "UPDATE_CLICK_TASK",
                  payload: {
                    mappedAction: "ROTATE",
                    clickIndex,
                    targetId: target.objectId,
                    value,
                  },
                },
              },
            });
          }}
          type="number"
          max={360}
          min={0}
          className="number-property hotspot-rotation-property"
          onPaste={(e) => {
            // e.clipboardData.items[0].getAsString((text) => {
            //   const value = sanitizeRotation(text);
            //   e.currentTarget.value = value.toString();
            //   mergedTask.setRotationValue(value);
            //   const newTree = mergedTask.action.level.getRoot().printTree();
            //   lessonPagesDispatch({
            //     type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
            //     payload: {
            //       interactivity: newTree,
            //     },
            //   });
            // });
          }}
        />
      </div>

      <div className="visibity-input-hotspot-action">
        <label style={{ marginLeft: "0px" }}> Visible</label>
        <input
          type="checkbox"
          name="visibility-property"
          // style={{ marginRight: "3px" }}
          checked={visibilityValue ?? true}
          onChange={() => {
            objectsDispatch({
              type: ObjectActionsType.UPDATE_HOTSPOT,
              payload: {
                objectId: hotspotObjectId,
                action: {
                  type: "UPDATE_CLICK_TASK",
                  payload: {
                    mappedAction: "SHOW/HIDE",
                    clickIndex,
                    targetId: target.objectId,
                    value: !visibilityValue,
                  },
                },
              },
            });

            // mergedTask.setVisibilityValue(!mergedTask.getVisibilityValue());
            // const newTree = mergedTask.action.level.getRoot().printTree();
            // lessonPagesDispatch({
            //   type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
            //   payload: {
            //     interactivity: newTree,
            //   },
            // });
          }}
        />
      </div>
    </div>
  );
}

export function handleAddingNewDefaultTasks(
  action: any,
  objectList: any[],
  lessonPagesDispatch: any,
  objectsDispatch: any,
) {
  // add a new object. if there is one on screen. if there are multiple default to the first on in list.

  if (action) {
    const alreadyUsedObjectIds = action?.getTargetsIdLists();
    const objectsNotAlreadyTargeted = objectList.filter((object: any) => {
      if (alreadyUsedObjectIds.has(object.objectId)) {
        return false;
      }
      if (object.type === "SCORM" || object.type === "video") {
        return false;
      }

      return true;
    });
    if (objectsNotAlreadyTargeted.length) {
      if (!("rotation" in objectsNotAlreadyTargeted[0])) {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_OBJECT,
          payload: {
            object: {
              rotation: 0,
            },
            objectId: objectsNotAlreadyTargeted[0].objectId,
          },
        });
      }
      const newTargetRotation =
        objectsNotAlreadyTargeted[0].rotation ?? objectsNotAlreadyTargeted[0]?.transform?.rotate ?? 0;
      const newTargetVisibility = objectsNotAlreadyTargeted[0].isDisplayed
        ? objectsNotAlreadyTargeted[0].isDisplayed
        : true;
      action.createMultipleTasks([
        {
          targetId: objectsNotAlreadyTargeted[0].objectId,
          actionValue: newTargetRotation,
          actionValueUnit: "DEG",
          mappedAction: "ROTATE",
        },
        {
          targetId: objectsNotAlreadyTargeted[0].objectId,
          actionValue: newTargetVisibility,
          actionValueUnit: null,
          mappedAction: "SHOW/HIDE",
        },
      ]);
      const newTree = action.level.getRoot().printTree();
      lessonPagesDispatch({
        type: LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST,
        payload: {
          interactivity: newTree,
        },
      });
    }
  }
}
