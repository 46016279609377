import ILessonPage from "../models/ILessonPage";
import React, { SetStateAction } from "react";
import { AssetManagerController } from "./builder";
import IAsset, { fmsCDU } from "../models/IAsset";
import ILessonPageDto from "../models/ILessonPageDto";
import ILessonMetaRequest from "../models/ILessonMetaRequest";
import { ICdu } from "../pageTypes/FMS_Player/Interfaces/ICdu";
import { ThemeDTO } from "../contexts/PageColorProvider/PageColorProvider";
export interface IPagesManagementContext {
  lessonPages: ILessonPage[];
  changePageOrder(lessonPages: [any]): void;
  switchCurrentlyDisplayedPage(pageIndexToDisplay: number, currentRow?: number): void;
  pagePullout: boolean;
  currentlyDisplayedPageIndex: number;
  lessonModes: any[];
}

// export interface IAssetsContext {
//     assetVersionId: number,
//     lessonVersionId: number,
//     pageVersionId: number,
// }

export interface IPageContext {
  currentlyDisplayedPageIndex: number;
  helperReference?: number | null;
  pageAssetIds: number[];
  pageType: string;
  page: any;
  pageId: number;
  pageManifest: any;
  isThereA3DPage: boolean;
  isAnnotationsShown: boolean;
  addNewPageAndDisplay(
    pageOrder: number,
    pageType: string,
    name: string,
    pageVersionId?: number,
    lpOverride?: ILessonPageDto[] | void,
    basePageType?: string,
  ): void;
  // compareClientSideAsset(assetVersionId: number, newAssetBeingDeleted: boolean): void;
  handleApiError(response: any, flag?: string): void;
  setPageAssetIds(val: number[]): void;
  setSelectorModalInfo(actionName: string): void;
  setProceduresSelectorBoolean(x: boolean): void;
  showAdvancedEditor(shouldShowAdvancedEditor: boolean): void;
  showLiveryEditor(shouldShowLiveryEditor: boolean): void;
  updateHelperReference(number: number | null): void;
  updatePageAsset(assetToAdd: number | null, assetToRemove: number | null): void;
  updatePageManifest(newPageManifest: any): void;
  getObjectIncrementor(): number;
  doesObjectIncrementorExist(): boolean;
  updateAllPagesTheme(theme: ThemeDTO): void;
  fmsDataWorksheetURL: string | null;
  //showAddStepGraphicEditor(shouldShowAddStepGraphicEditor: boolean): void;
  // addAssetToCurrentLessonPage(lessonAsset: number): void;
  // removeAssetFromCurrentLessonPage(lessonAsset: number): void;
}

export interface IFMSObject {
  cdu: string;
  cduPath: string;
  assetVersionId: number;
  cduObject: ICdu;
}

export interface IProceduresObject {
  modelSet: string;
  assetVersionId: number | undefined;
  role: "Captain" | "FO";
}

export interface ILessonAdditionalSettingsContext {
  getColorsList(standardColors: string[]): string[];
  settings: any; // (this is generic "any" to allow expansion for disparate uses); these are to be derived from LessonVersion.LessonSettingsJson data in DB
}

export interface ILessonManagementContext {
  assetManagerController: AssetManagerController;
  currentlyDisplayedPageIndex: number;
  errorMessage: string;
  fmsObject: Record<string, IFMSObject>; //lodash _.isEmpty -- key value pairs "cdu" and "cduPath"
  isSaved: boolean;
  lessonPages: any;
  proceduresObject: Record<string, IProceduresObject>; //key "modelSet"
  timeSpent: number;
  addNewPageAndDisplay(
    pageOrder: number,
    pageType: string,
    name: string,
    pageVersionId?: number,
    lpOverride?: ILessonPageDto[] | void,
    basePageType?: string,
  ): Promise<void>;
  copyPage(number: number, pages: ILessonPageDto[]): void;
  deletePage(): void;
  handleApiError(response: any): void;
  pageIndexIsSafeToDelete(): boolean;
  saveLesson(lessonPages?: ILessonPageDto[]): Promise<ILessonPageDto[] | void>;
  setAssetManagerController: React.Dispatch<SetStateAction<AssetManagerController>>;
  setLessonMetaData(metaData: ILessonMetaRequest): void;
  setSelectorModalInfo(actionName: string): void;
  // this function is dead code, not used anywhere; warrants cleanup
  toggleLessonDataModal(): void;
}

export interface ILessonPageAction {
  triggerAction(): void;
  actionName: string;
  iconPath: string;
}
//selectedEditorLessonModes, setSelectedEditorLessonModes
export interface ILessonPageActionsContext {
  dirt: number;
  fmsCdus: fmsCDU[];
  fmsObject: IFMSObject;
  deleteWarning: boolean;
  dirtyFmsObject: IFMSObject; //to compare if selected in settings so old fms pages can be deleted
  isAnnotationsShown: boolean;
  lessonModes: any;
  lessonPageActions: ILessonPageAction[];
  previewMode: "Demo" | "Practice" | "Perform";
  proceduresObject: IProceduresObject;
  selectedEditorLessonModes: number;
  threeDModels: IAsset[];
  handleRightSide(action: string, iDpayload?: number[]): void;
  setSelectedEditorLessonModes(value: number): void;
  setDeleteWarning(value: boolean): void;
  setCurrent3DObject(threeD: IProceduresObject | ((old3d: IProceduresObject) => IProceduresObject)): void;
  setDirt(n: number): void;
  setFmsObject(fms: IFMSObject): void;
  setLessonPageActions(newLessonPageActions: ILessonPageAction[]): void;
  setIsAnnotationsShown(boolean: boolean): void;
  setPageTypeToDelete(value: string): void;
  setPreviewMode(mode: string): void;
  assetManagerController: AssetManagerController;
  setAssetManagerController: React.Dispatch<SetStateAction<AssetManagerController>>;
  setLessonSettingsController: React.Dispatch<React.SetStateAction<{ isOpen: boolean }>>;
  showFMSSelector(): void;
  setWalkaroundSelectorShowing(toggle: boolean): void;
  currentWalkAroundObject: IProceduresObject;
  setCabinSelectorShowing(toggle: boolean): void;
  currentCabinObject: IProceduresObject;
  walkaroundModels: IAsset[];
  cabinModels: IAsset[];
}

export interface IAssetManagementContext {
  assetTypeId: number;
  assetIndex: number;
  setAssetIndex(number: number): void;
  setAssetTypeId(number: number): void;
  showBlobUploader(bool: boolean): void;
  aircraftId: number | undefined;
  lessonDataSettings: any;
  lessonMetaData: any;
  setAssetManagerController: any;
}
export interface IEditorModeContext {
  lessonModeIds: number[];
  selectedEditorLessonModes: number;
  setSelectedEditorLessonModes(value: number): void;
  lessonModes: any[];
  pageType: string;
}

export interface ILessonDataModalContext {}

export const AssetContext: React.Context<IAssetManagementContext> = React.createContext<IAssetManagementContext>(
  {} as IAssetManagementContext,
);
export const PagesManagementContext: React.Context<IPagesManagementContext> =
  React.createContext<IPagesManagementContext>({} as IPagesManagementContext);
export const PageContext: React.Context<IPageContext> = React.createContext<IPageContext>({} as IPageContext);
export const LessonManagementContext: React.Context<ILessonManagementContext> =
  React.createContext<ILessonManagementContext>({} as ILessonManagementContext);
export const LessonPageActionsContext: React.Context<ILessonPageActionsContext> =
  React.createContext<ILessonPageActionsContext>({} as ILessonPageActionsContext);
export const EditorModeContext: React.Context<IEditorModeContext> = React.createContext<IEditorModeContext>(
  {} as IEditorModeContext,
);
export const LessonAdditionalSettingsContext: React.Context<ILessonAdditionalSettingsContext> =
  React.createContext<ILessonAdditionalSettingsContext>({} as ILessonAdditionalSettingsContext);
