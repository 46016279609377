export const validation = {
  /**
   *
   * @param value should be pretty much anything but references
   * @returns a number, if it is below zero then it returns 0. If it is NaN also returns 0
   */
  noLessThanZero(value: number): number {
    if (value < 0) {
      return 0;
    }
    return value;
  },
};

export function parseStringToNumber(str: string): {
  val: number | null;
  unit: string | null;
} | null {
  const res = str.match(/(-?[\d.]+)([a-z%]*)/);
  if (res) {
    return {
      val: parseFloat(res[1]),
      unit: res[2] ?? null,
    };
  } else {
    return null;
  }
}
