import React, { useState } from "react";
import MenuNewTableSize from "./MenuNewTableSize";
import AddColumnButton from "./AddColumnButton";
import AddRowButton from "./AddRowButton";
import RowHeightControls from "./RowHeightControls";
import ColumnWidthControls from "./ColumnWidthControls";
import BorderColor from "./BorderColor";
import FillColor from "./FillColor";

import { useTablesDataDispatch, useTablesDataState } from "./TablesDataProvider";
import { useObjectsState } from "../../contexts/ObjectsProvider";

function EditTableMenu() {
  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();
  const objectsState = useObjectsState();

  // if objectsState.tables has the selectedObjectId, then render the menu
  return objectsState.tables.some((table) => table.objectId === objectsState.selectedObjectId[0]) ? (
    <div id="custom-table" className="flex-row">
      <hr className="wysiwyg-toolbar-divider" />
      <AddRowButton />
      <AddColumnButton />
      {/* <ColumnWidthControls/> */}
      <RowHeightControls />
      <ColumnWidthControls />

      <BorderColor />
      <FillColor />
    </div>
  ) : (
    <></>
  );
}

export default EditTableMenu;
