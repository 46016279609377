export function updateImage(index: number, pageManifest: any, pageContext: any, igsIndex?: number) {
  if (pageManifest) {
    addPageDimsToManifest(pageManifest, pageContext);
  }

  const quizImage = document.getElementById("quiz-image-" + index);
  if (quizImage) {
    const quizImageRect = quizImage.getBoundingClientRect();

    if (pageManifest.image) {
      pageManifest.image[index].rawData = {
        pixelHeight: quizImageRect.height,
        pixelWidth: quizImageRect.width,
        pixelTop: quizImageRect.top,
        pixelLeft: quizImageRect.left,
      };

      pageManifest.image[index].version = 1;
      pageContext.updatePageManifest(pageManifest);
    }

    if (pageManifest.IGSObject) {
      if (pageManifest.IGSObject[igsIndex as number].images) {
        pageManifest.IGSObject[igsIndex as number].images.rawData = {
          pixelHeight: quizImageRect.height,
          pixelWidth: quizImageRect.width,
          pixelTop: quizImageRect.top - pageManifest.designerEnvironmentData.quizImageContainer!.pixelTop,
          pixelLeft: quizImageRect.left - pageManifest.designerEnvironmentData.quizImageContainer!.pixelLeft,
        };
        pageManifest.IGSObject[igsIndex as number].images.version = 1;
      }

      if (!pageManifest.IGSObject[igsIndex as number].images && pageManifest.IGSObject[igsIndex as number].choices) {
        pageManifest.IGSObject[igsIndex as number].choices[index].rawData = {
          pixelHeight: quizImageRect.height,
          pixelWidth: quizImageRect.width,
          pixelTop: quizImageRect.top - pageManifest.designerEnvironmentData.quizImageContainer!.pixelTop,
          pixelLeft: quizImageRect.left - pageManifest.designerEnvironmentData.quizImageContainer!.pixelLeft,
        };
        pageManifest.IGSObject[igsIndex as number].choices[index].version = 1;
      }
    }

    updatePageManifestDimensionPercentages(index, pageManifest, pageContext, igsIndex);
  }
}

export function updatePageManifestDimensionPercentages(
  index: number,
  pageManifest: any,
  pageContext: any,
  igsIndex?: number,
) {
  if (pageManifest.image) {
    // image pixel percent from top of pagePlayerArea
    pageManifest.image[index].top = Number(
      (
        (pageManifest.image[index].rawData!.pixelTop /
          pageManifest.designerEnvironmentData.quizImageContainer!.pixelHeight) *
        100
      ).toFixed(2),
    );
    // image pixel percent from left of pagePlayerArea
    pageManifest.image[index].left = Number(
      (
        (pageManifest.image[index].rawData!.pixelLeft /
          pageManifest.designerEnvironmentData.quizImageContainer!.pixelWidth) *
        100
      ).toFixed(2),
    );
    // image pixel percent height relative to pagePlayerArea
    pageManifest.image[index].height = Number(
      (
        (pageManifest.image[index].rawData!.pixelHeight /
          pageManifest.designerEnvironmentData.quizImageContainer!.pixelHeight) *
        100
      ).toFixed(2),
    );
    // image pixel percent width relative to pagePlayerArea
    pageManifest.image[index].width = Number(
      (
        (pageManifest.image[index].rawData!.pixelWidth /
          pageManifest.designerEnvironmentData.quizImageContainer!.pixelWidth) *
        100
      ).toFixed(2),
    );
  }

  if (pageManifest.IGSObject) {
    if (pageManifest.IGSObject[igsIndex as number].images) {
      // image pixel percent from top of pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].images.top = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelTop /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelHeight) *
          100
        ).toFixed(2),
      );
      // image pixel percent from left of pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].images.left = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelLeft /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelWidth) *
          100
        ).toFixed(2),
      );
      // image pixel percent height relative to pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].images.height = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelHeight /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelHeight) *
          100
        ).toFixed(2),
      );
      // image pixel percent width relative to pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].images.width = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].images.rawData!.pixelWidth /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelWidth) *
          100
        ).toFixed(2),
      );
    }

    if (!pageManifest.IGSObject[igsIndex as number].images && pageManifest.IGSObject[igsIndex as number].choices) {
      // image pixel percent from top of pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].choices[index].top = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelTop /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelHeight) *
          100
        ).toFixed(2),
      );
      // image pixel percent from left of pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].choices[index].left = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelLeft /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelWidth) *
          100
        ).toFixed(2),
      );
      // image pixel percent height relative to pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].choices[index].height = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelHeight /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelHeight) *
          100
        ).toFixed(2),
      );
      // image pixel percent width relative to pagePlayerArea
      pageManifest.IGSObject[igsIndex as number].choices[index].width = Number(
        (
          (pageManifest.IGSObject[igsIndex as number].choices[index].rawData!.pixelWidth /
            pageManifest.designerEnvironmentData.quizImageContainer!.pixelWidth) *
          100
        ).toFixed(2),
      );
    }
  }

  pageContext.updatePageManifest(pageManifest);
}

export function addPageDimsToManifest(pageManifest: any, pageContext: any) {
  if (pageManifest) {
    const pagePlayerArea = document.getElementById("pageplayerarea");
    const designBoxWrapper = document.getElementById("designBoxWrapper");
    const containerForContentArea = document.getElementById("containerForContentArea");
    const containerForApp = document.getElementById("containerForApp");
    const quizImageContainer = document.getElementById("quiz-image-container");
    const imageAspectContainer = document.getElementById("img-aspect-container-0");
    const igaInteractiveGraphicWrapper = document.getElementById("iga-graphic-wrapper");
    const igaStandardGraphicContainer = document.getElementById("iga-standard-quiz-graphic-container");

    let pagePlayerAreaRect: any;
    let designBoxWrapperRect: any;
    let containerForContentAreaRect: any;
    let containerForAppRect: any;
    let quizImageContainerRect: any;

    if (pagePlayerArea) {
      pagePlayerAreaRect = pagePlayerArea.getBoundingClientRect();
    }

    if (quizImageContainer) {
      quizImageContainerRect = quizImageContainer.getBoundingClientRect();
    }

    if (imageAspectContainer) {
      quizImageContainerRect = imageAspectContainer.getBoundingClientRect();
    }

    if (igaInteractiveGraphicWrapper) {
      quizImageContainerRect = igaInteractiveGraphicWrapper.getBoundingClientRect();
    }

    if (igaStandardGraphicContainer) {
      quizImageContainerRect = igaStandardGraphicContainer.getBoundingClientRect();
    }

    if (designBoxWrapper) {
      designBoxWrapperRect = designBoxWrapper.getBoundingClientRect();
    }

    if (containerForContentArea) {
      containerForContentAreaRect = containerForContentArea.getBoundingClientRect();
    }

    if (containerForApp) {
      containerForAppRect = containerForApp.getBoundingClientRect();
    }

    pageManifest.designerEnvironmentData = {
      pagePlayerArea: {
        pixelHeight: pagePlayerAreaRect?.height,
        pixelWidth: pagePlayerAreaRect?.width,
        pixelTop: pagePlayerAreaRect?.top,
        pixelLeft: pagePlayerAreaRect?.left,
        pixelRight: pagePlayerAreaRect?.right,
        pixelBottom: pagePlayerAreaRect?.bottom,
        pixelX: pagePlayerAreaRect?.x,
        pixelY: pagePlayerAreaRect?.y,
      },
      quizImageContainer: {
        pixelHeight: quizImageContainerRect?.height,
        pixelWidth: quizImageContainerRect?.width,
        pixelTop: quizImageContainerRect?.top,
        pixelLeft: quizImageContainerRect?.left,
        pixelRight: quizImageContainerRect?.right,
        pixelBottom: quizImageContainerRect?.bottom,
        pixelX: quizImageContainerRect?.x,
        pixelY: quizImageContainerRect?.y,
      },
      designBoxWrapper: {
        pixelHeight: designBoxWrapperRect?.height,
        pixelWidth: designBoxWrapperRect?.width,
        pixelTop: designBoxWrapperRect?.top,
        pixelLeft: designBoxWrapperRect?.left,
        pixelRight: designBoxWrapperRect?.right,
        pixelBottom: designBoxWrapperRect?.bottom,
        pixelX: designBoxWrapperRect?.x,
        pixelY: designBoxWrapperRect?.y,
      },
      containerForContentArea: {
        pixelHeight: containerForContentAreaRect?.height,
        pixelWidth: containerForContentAreaRect?.width,
        pixelTop: containerForContentAreaRect?.top,
        pixelLeft: containerForContentAreaRect?.left,
        pixelRight: containerForContentAreaRect?.right,
        pixelBottom: containerForContentAreaRect?.bottom,
        pixelX: containerForContentAreaRect?.x,
        pixelY: containerForContentAreaRect?.y,
      },
      containerForApp: {
        pixelHeight: containerForAppRect?.height,
        pixelWidth: containerForAppRect?.width,
        pixelTop: containerForAppRect?.top,
        pixelLeft: containerForAppRect?.left,
        pixelRight: containerForAppRect?.right,
        pixelBottom: containerForAppRect?.bottom,
        pixelX: containerForAppRect?.x,
        pixelY: containerForAppRect?.y,
      },
      version: 1,
    };

    pageContext.updatePageManifest(pageManifest);
  }
}
