import React, { useMemo } from "react";
import { useInteracitvityHotspotState } from "../../../contexts/InteractivityHotspotProvider";
import { handleKeyUp, handleKeyPress } from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { AnnotationPropType } from "../Annotation";
import HighlightAnnotation from "../hooks/HighlightAnnotation";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { getFramesNearCurrentTime } from "../../../objects/Images/Image";
import { useInterpolatedFrame } from "../../../hooks/useInterpolatedFrame";

const AnnotationLineArrow = (props: AnnotationPropType) => {
  const { annotation, index, kp, markSelected, objectIsInTimeline } = props;
  const isDisplayed = typeof annotation.isDisplayed === "undefined" || annotation.isDisplayed;
  const opacity = 1;
  const rotateDeg: number | undefined = annotation?.transform?.rotate ? annotation?.transform?.rotate : 0;

  const values = useMemo(() => {
    const values = {
      point1: 18,
      point1b: 10,
      point2: 3,
      point2b: 18,
      point3b: 25,
      lineStart: 7,
    };
    if (annotation.strokeWidth) {
      values.point2 = annotation.strokeWidth;
      values.point1 = 12 + annotation.strokeWidth;
      values.point1b = 12 - annotation.strokeWidth * 0.5;
      values.point3b = 23 + annotation.strokeWidth * 0.5;
      switch (annotation.strokeWidth) {
        case 1:
          values.lineStart = 2;
          break;
        case 2:
          values.lineStart = 4;
          break;
        case 3:
          values.lineStart = 6;
          break;
        case 4:
          values.lineStart = 8;
          break;
        case 5:
          values.lineStart = 10;
          break;
        case 6:
          values.lineStart = 10;
          break;
        case 7:
          values.lineStart = 12;
          break;
        case 8:
          values.lineStart = 14;
          break;
        case 9:
          values.lineStart = 16;
          break;
      }
    }
    return values;
  }, [annotation.strokeWidth]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      id={`fillArrow-${index}`}
      data-objectid={annotation.objectId}
      tabIndex={0}
      name="lineArrow"
      className="annotation"
      version="1.1"
      // viewBox={`0 0 auto 20`}
      height={`36`}
      // preserveAspectRatio="xMinYMid meet"
      preserveAspectRatio="xMinYMin meet"
      style={{
        top: `${annotation.top}%`,
        left: `${annotation.left}%`,
        transform: `rotate(${rotateDeg}deg)`,
        // scale: `${scaleString}`,
        width: `${annotation.width}%`,
        zIndex: annotation.zIndex,
      }}
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        markSelected(e.currentTarget, index, "annotations");
      }}
      onKeyDown={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyPress(e, kp)}
      onKeyUp={(e: React.KeyboardEvent<SVGSVGElement>) => handleKeyUp(e, kp)}
      // ref={svgRef}
    >
      <HighlightAnnotation annotation={annotation} />

      <svg height="100%" opacity={opacity}>
        <polygon
          points={`${values.point1}, ${values.point1b} ${values.point2},${values.point2b} ${values.point1},${values.point3b}`}
          stroke={`${annotation.borderColor || "white"}`}
          fill={`${annotation.borderColor || "white"}`}
          strokeWidth={`${annotation.strokeWidth || "4"}`}
        />
      </svg>
      <line
        x1={`${values.lineStart}`}
        x2={`100%`}
        y1={`50%`}
        y2={`50%`}
        style={{
          fill: `${annotation.borderColor || "white"}`,
          stroke: `${annotation.borderColor || "white"}`,
          strokeLinecap: "butt",
          strokeWidth: `${annotation.strokeWidth || "4"}`,
          opacity,
        }}
      />
    </svg>
  );
};

export default AnnotationLineArrow;
