import { BaseObject } from "../../types";

export function getObjectListFromManifest(pageManifest: any) {
  const objectList = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    switch (key) {
      case "annotations":
      case "pageImage":
      case "symbols":
        (value as any).forEach((obj: any) => {
          objectList.push(obj);
        });
        break;
    }
  }

  return null;
}
type ObjectType =
  | "annotations"
  | "pageImage"
  | "symbols"
  | "tables"
  | "textBlock"
  | "pageScormObject"
  | "hotspots"
  | "video";
export function runOnObjects(
  pageManifest: any,
  objects: ObjectType[] | "all",
  callback: (obj: any, objectType: ObjectType) => void,
) {
  for (const [key, value] of Object.entries(pageManifest)) {
    if (objects !== "all" && !objects.includes(key as any)) {
      continue;
    }
    if (
      key === "annotations" ||
      key === "pageImage" ||
      key === "symbols" ||
      key === "tables" ||
      key === "textBlock" ||
      key === "pageScormObject" ||
      key === "hotspots"
    ) {
      if (!value) {
        continue;
      }
      (value as any).forEach((obj: any) => {
        callback(obj, key);
      });
    }
    if (key === "video") {
      callback(value, key);
    }
  }
}

export function getObjectsFromManifest(pageManifest: any, objects: ObjectType[] | "all") {
  const objectsFlat: any[] = [];
  runOnObjects(pageManifest, objects, (obj) => {
    objectsFlat.push(obj);
  });
  return objectsFlat;
}

export function getObjectsFromManifestByType(pageManifest: any) {
  const types: { [key in ObjectType]: any } = {} as any;
  runOnObjects(pageManifest, "all", (obj, type) => {
    types[type] = [...(types[type] || []), obj];
  });
  return types;
}
export function getObjectsInFlatListAndByType(pageManifest: any) {
  const types: { [key in ObjectType]: any } = {} as any;
  const objectsFlat: any[] = [];
  runOnObjects(pageManifest, "all", (obj, type) => {
    if (obj) {
      objectsFlat.push(obj);
      types[type] = [...(types[type] || []), obj];
    } else if (typeof obj === "undefined") {
      console.error(`The object of type ${type} is undefined`);
    }
  });

  return { objectsFlat, types };
}
export function getInteractivityHotspotLocations(pageManifest: any) {
  const interactivityStruct = pageManifest.interactivity;
  const allHotspots = [];
  goLevels(interactivityStruct);

  function goLevels(level: any) {
    level.levelBuffer.forEach((lvl: any) => {
      goLevels(lvl);
    });
    level.actionBuffer.forEach((action: any) => {
      if (action.type === "hotspot") {
        allHotspots.push(action);
      }
    });
  }
}

export function isInTimeline(object: any) {
  return typeof object?.start === "number" && (typeof object?.end === "number" || object?.end === null);
}
