import React from "react";
import lodash from "lodash";
import { useLessonData } from "../../contexts/LessonDataProvider";
import { useLessonSettingsState, useLessonSettingsDispatch } from "./LessonSettingsContext/LessonSettingsProvider";
import AircraftSpecificitySelector from "./components/AircraftSpecificitySelector";
import AdvancedSettings from "./components/AdvancedSettings";
import ReferencesSettings from "./components/ReferencesSettings";
import ExtraOptionsSettings from "./components/ExtraOptionsSettings";
import { ReactComponent as RemoveIcon } from "../../assets/icons/Common/remove-tag-icon.svg";

const LessonSettingsContainer = (props: any) => {
  const [lessonData, formattedAircrafts] = useLessonData({
    formattedAircrafts: true,
  });
  const [cancelConfirmationModal, setCancelConfirmationModal] = React.useState(false);
  const [emptyTitle, setEmptytitle] = React.useState(true);

  const resolve: any = React.useRef();
  const loadingDone = React.useMemo(() => {
    return Object.entries(lessonData).length > 0 && Object.entries(formattedAircrafts).length > 0;
  }, [lessonData, formattedAircrafts]);

  const settingsState = useLessonSettingsState();
  const settingsDispatch = useLessonSettingsDispatch();

  React.useEffect(() => {
    if (settingsState.lessonMetaDataLocalCopy.lessonName.length === 0) {
      setEmptytitle(true);
    } else {
      setEmptytitle(false);
    }
  }, [settingsState.lessonMetaDataLocalCopy.lessonName]);
  const aircraftSpecificityObject = {
    manufacturerId: settingsState.lessonMetaDataLocalCopy.manufacturerId,
    aircraftFamilyId: settingsState.lessonMetaDataLocalCopy.aircraftFamilyId,
    aircraftId: settingsState.lessonMetaDataLocalCopy.aircraftId,
  };

  const handleCancel = async () => {
    const equal = lodash.isEqual(settingsState.lessonMetaData, settingsState.lessonMetaDataLocalCopy) ? true : false;

    if (equal) {
      props.setLessonSettingsController({ isOpen: false });
    } else {
      if (await CancelConfirmation.show()) {
        props.setLessonSettingsController({ isOpen: false });
        settingsDispatch({
          type: "RECONCILE_LESSON_META_DATA",
          payload: settingsState.lessonMetaData,
        });
      } else {
        setCancelConfirmationModal(false);
      }
    }
  };

  const handleSave = async () => {
    if (!emptyTitle) {
      const lpCopy = lodash.cloneDeep(props.lessonPages).map((page: any) => {
        let thisPageDirty = false;
        if (page?.pageManifest?.Audio?.length) {
          page.pageManifest.Audio.map((audio: any, index: number) => {
            if (index < 3) {
              if (
                audio.language !== settingsState?.lessonMetaDataLocalCopy?.additionalSettings?.studentLanguages?.[0]
              ) {
                audio.language =
                  settingsState?.lessonMetaDataLocalCopy?.additionalSettings?.studentLanguages?.[0] ?? "";
                thisPageDirty = true;
              }
            } else if (index < 6) {
              if (
                audio.language !== settingsState?.lessonMetaDataLocalCopy?.additionalSettings?.studentLanguages?.[1]
              ) {
                audio.language =
                  settingsState?.lessonMetaDataLocalCopy?.additionalSettings?.studentLanguages?.[1] ?? "";
                thisPageDirty = true;
              }
            }
            return audio;
          });
        }
        return { ...page, pageManifestIsDirty: thisPageDirty };
      });
      props.setLessonSettingsController({ isOpen: false });
      await props.saveLesson(lpCopy, settingsState.lessonMetaDataLocalCopy);
    }
  };
  const CancelConfirmation = () => {
    const handleConfirmationCancel = () => {
      resolve.current(false);
    };
    const handleConfirmationSave = () => {
      resolve.current(true);
    };
    return cancelConfirmationModal ? (
      <div className="lessonSettings-handleCancel-backdrop">
        <div>
          <div>
            <p>Lesson Settings</p>
            <RemoveIcon onClick={handleConfirmationCancel} />
          </div>
          <div>
            <p>
              Please, save the lesson settings prior cancelling any actions or all the changes that have been made will
              be lost. Are you sure you would like to continue?
            </p>
            <button onClick={handleConfirmationSave}>Yes</button>
            <button onClick={handleConfirmationCancel}>No</button>
          </div>
        </div>
      </div>
    ) : null;
  };
  CancelConfirmation.show = () => {
    setCancelConfirmationModal(true);
    return new Promise((res) => {
      resolve.current = res;
    });
  };
  const disabled = {
    manufacturer: settingsState.lessonMetaData.manufacturerId,
    aircraftFamily: settingsState.lessonMetaData.aircraftFamilyId,
    aircraft: settingsState.lessonMetaData.aircraftId,
  };
  // const disabled = {
  //   manufacturer: false,
  //   aircraftFamily:false,
  //   aircraft: false
  // }
  if (loadingDone) {
    return (
      <div className="lessonSettings-backdrop" onMouseDown={handleCancel}>
        <div className="lessonSettings-container" onMouseDown={(e) => e.stopPropagation()}>
          <div className="lessonSettings-title">
            <p>Lesson Settings</p>
            <div>
              <div className="settings-lms-container">
                <div className="settings-lms">
                  <p>
                    <strong>LMS:</strong>
                    <span>({settingsState.lessonMetaDataLocalCopy.lessonVersionId})</span>
                    {settingsState.lessonMetaDataLocalCopy.lmsName}
                  </p>
                </div>
              </div>
              <RemoveIcon onMouseDown={handleCancel} />
            </div>
          </div>
          <div className="lessonSettings-general">
            <div className="lessonSettings-general-left-up">
              <div className="lessonSettings-lessonTitle">
                <div className={emptyTitle ? "title-message on" : "title-message off"}>
                  *Can Not have an empty Lesson Title
                </div>
                <p>Lesson Title</p>
                <input
                  type="text"
                  value={settingsState.lessonMetaDataLocalCopy.lessonName}
                  onChange={(e) => {
                    settingsDispatch({
                      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                      payload: { lessonName: e.target.value },
                    });
                  }}
                  style={
                    emptyTitle
                      ? {
                          background: "rgba(223,21,21,0.15)",
                        }
                      : {}
                  }
                />
              </div>
            </div>

            <div className="lessonSettings-general-right-up">
              <div className="lessonSettings-lessonDescription">
                <p>Lesson Description</p>
                <input
                  value={settingsState.lessonMetaDataLocalCopy.description}
                  onChange={(e) => {
                    settingsDispatch({
                      type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                      payload: { description: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
            <div className="lessonSettings-general-left-down">
              <div>
                <div className="lessonSettings-lessonAuthor">
                  <p>Author</p>
                  <input type="text" disabled value={settingsState.lessonMetaDataLocalCopy.authorName} />
                </div>
                <div className="lessonSettings-lessonStyle">
                  <p>Lesson Style</p>
                  <select disabled={true} value={settingsState.lessonMetaDataLocalCopy.lessonStyle.name}>
                    {lessonData.lessonStyles.map((lessonStyle) => {
                      return <option key={"lslsk" + lessonStyle.name}>{lessonStyle.name}</option>;
                    })}
                  </select>
                </div>
                <div className="lessonSettings-lessonType">
                  <p>Lesson Type</p>
                  <select
                    value={
                      settingsState.lessonMetaDataLocalCopy.lessonType.nickname
                        ? settingsState.lessonMetaDataLocalCopy.lessonType.nickname
                        : settingsState.lessonMetaDataLocalCopy.lessonType.name
                    }
                    onChange={(e) => {
                      const name = e.target.value;
                      let nickname: string | null = null;
                      let lessonTypeId = -1;
                      lessonData.lessonTypes.forEach((lt, index) => {
                        if (lt.name === name || lt.nickname === name) {
                          nickname = lt.nickname;
                          lessonTypeId = lt.lessonTypeId;
                        }
                      });
                      settingsDispatch({
                        type: "UPDATE_LESSON_META_DATA_LOCAL_COPY",
                        payload: {
                          lessonType: {
                            name,
                            nickname,
                            lessonTypeId,
                          },
                        },
                      });
                    }}
                  >
                    {lessonData.lessonTypes.map((lessonType) => {
                      return (
                        <option key={"lsltk" + lessonType.name}>
                          {lessonType.nickname ? lessonType.nickname : lessonType.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="lessonSettings-general-right-down">
              {/* <div>
                    <p>Developer Notes</p>
                    <textarea></textarea>
                </div> */}
              <AircraftSpecificitySelector
                className={"lessonSettings-aircraftSpecificity"}
                specificity={aircraftSpecificityObject}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="lessonSettings-tabs-container">
            <div className="lessonSettings-tab-chooser">
              <div
                className={
                  "lessonSettings-advanced-tab" +
                  (settingsState.lessonSettingsTab === "Advanced Settings" ? " selected-settings-tab" : "")
                }
                onClick={() => {
                  settingsDispatch({
                    type: "SET_LESSON_SETTINGS_TAB",
                    payload: "Advanced Settings",
                  });
                }}
              >
                <p>Advanced Settings</p>
              </div>
              <div
                className={
                  "lessonSettings-references-tab" +
                  (settingsState.lessonSettingsTab === "References" ? " selected-settings-tab" : "")
                }
                onClick={() => {
                  settingsDispatch({
                    type: "SET_LESSON_SETTINGS_TAB",
                    payload: "References",
                  });
                }}
              >
                <p>References</p>
              </div>
              <div
                className={
                  "lessonSettings-extra-tab" +
                  (settingsState.lessonSettingsTab === "Extra Options" ? " selected-settings-tab" : "")
                }
                onClick={() => {
                  settingsDispatch({
                    type: "SET_LESSON_SETTINGS_TAB",
                    payload: "Extra Options",
                  });
                }}
              >
                <p>Extra Options</p>
              </div>
            </div>
            <div className="lessonSettings-tab-content">
              {settingsState.lessonSettingsTab === "Advanced Settings" && (
                <AdvancedSettings
                  fmsCdus={props.fmsCdus}
                  threeDModels={props.threeDModels}
                  lessonPages={props.lessonPages}
                />
              )}
              {settingsState.lessonSettingsTab === "References" && (
                <ReferencesSettings setAssetManagerController={props.setAssetManagerController} />
              )}
              {settingsState.lessonSettingsTab === "Extra Options" && (
                <ExtraOptionsSettings lessonData={lessonData} isCpatUser={props.isCpatUser} />
              )}
            </div>
          </div>
          <div className="lessonSettings-buttons">
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleSave}>Save</button>
          </div>
          <CancelConfirmation />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default LessonSettingsContainer;
