import React, { useEffect, useLayoutEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import { useGlobalAudioDispatch, useGlobalAudioState } from "../../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { clippedSpaceFromUnitSpace, useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../../hooks/useDomRef";
import { simpleDebounce } from "../../../utils";
import { getDevicePixelRatio } from "../GridBackground";
import { TOP_STRIP_HEIGHT, WAVE_HEIGHT } from "../TimelinePanel";
import { useInteractivityBuilderState } from "../../../contexts/InteractivityBuilderProvider";
import { useObjectsState } from "../../../contexts/ObjectsProvider";

const debounce = simpleDebounce(350);
export default function AudioBar() {
  const [ref, setRef] = useDomRef();
  const globalAudioState = useGlobalAudioState();
  const [timeline, td] = useTimeline();
  const iState = useInteractivityBuilderState();

  const clippedStart = clippedSpaceFromUnitSpace(
    0,
    timeline.scaledSpace.leftPadding,
    timeline.clippedSpace,
    timeline.widthInPx,
  );
  const clippedEnd = clippedSpaceFromUnitSpace(
    globalAudioState.globalAudioManager.audioLength,
    timeline.scaledSpace.leftPadding,
    timeline.clippedSpace,
    timeline.widthInPx,
  );
  useEffect(() => {
    if (!ref) return;
    if (!globalAudioState.globalAudioManager.buffers.blob) return;
    const _ac = globalAudioState.globalAudioManager.audioContext;

    const wavesurfer = WaveSurfer.create({
      container: ref,
      waveColor: "black",
      progressColor: "green",
      height: WAVE_HEIGHT,
      responsive: true,
      pixelRatio: getDevicePixelRatio(),
      mediaControls: true,
      audioContext: _ac,
      hideScrollbar: true,
      barGap: 10,
      backgroundColor: "rgba(255, 255, 255, 0.99)",
    });

    // wavesurfer.on("ready", (e) => {});
    wavesurfer.on("audioprocess", (seconds) => {
      td({
        type: "SET_SECONDS",
        payload: seconds,
      });
    });
    wavesurfer.on("pause", () => {
      td({
        type: "SNAP_TO_GRID",
      });
    });
    globalAudioState.globalAudioManager.setWavesurfer(wavesurfer);

    return () => {
      globalAudioState.globalAudioManager.removeWavesurfer();
    };
  }, [globalAudioState.globalAudioManager.buffers.blob, ref, globalAudioState.loading]);

  useLayoutEffect(() => {
    if (!globalAudioState.globalAudioManager.audioNodes.wavesurfer?.drawer) return;
    debounce(() => {
      globalAudioState.globalAudioManager.audioNodes.wavesurfer!.drawer.fireEvent("redraw");
    });
  }, [clippedStart, clippedEnd]);

  return (
    <>
      {globalAudioState.globalAudioManager.buffers.blob && (
        <div
          className="waveform"
          ref={setRef}
          style={{
            pointerEvents: "auto",
            left: clippedStart,
            width: clippedEnd - clippedStart,
            height: WAVE_HEIGHT,
            position: "absolute",
            top: TOP_STRIP_HEIGHT,
            backgroundColor: "rgba(255, 255, 255, 0.99)",
          }}
        ></div>
      )}
    </>
  );
}
