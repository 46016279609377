import React, { useEffect, useState } from "react";
import { useGlobalAudioState } from "../../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { ReactComponent as PauseIcon } from "../../../assets/icons/NarratorTab/narrator-text-pause-icon.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/NarratorTab/narrator-text-play-icon.svg";
import { ReactComponent as LoadingSvg } from "../../../assets/icons/NarratorTab/Dual Ring-1s-32px.svg";
import { ReactComponent as ZIn } from "../../../assets/icons/Common/zoom-in.svg";
import { ReactComponent as ZOut } from "../../../assets/icons/Common/zoom-out.svg";

import { TOP_STRIP_HEIGHT, WAVE_HEIGHT } from "../TimelinePanel";
import { useTimeline, useTimelineDispatch } from "../../../contexts/TimelineProvider/TimelineProvider";
const ZOOM_STRENGTH = 3;
let animationFrameId: number;
export default function LeftOverlay() {
  const { globalAudioManager, audioOnPageExists, audioName, loading, audioPlaying } = useGlobalAudioState();
  const [tState, tDispatch] = useTimeline();
  const [playToggle, setPlayToggle] = useState(false);
  const { heightInPx } = tState;
  return (
    <div
      className="left-overlay"
      style={{
        position: "absolute",
        width: "30%",
        height: heightInPx,
        left: 0,
        top: 0,
        zIndex: 3,
      }}
    >
      <div
        className="timeline-left-top"
        style={{
          height: TOP_STRIP_HEIGHT,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <TimelineTimeCounter />
        {!audioPlaying && playToggle === false && (
          <div
            className="control-button"
            onClick={() => {
              if (loading) return;
              globalAudioManager.play();
              if (!globalAudioManager.audioNodes.wavesurfer) {
                let accumulatedTime = tState.scrubbingCurrentTime;
                let lastTimestamp = 0;
                cancelAnimationFrame(animationFrameId);
                const startTimeline = (timestamp = 0) => {
                  // if (frame % 2 === 0) {
                  if (lastTimestamp) {
                    const deltaTime = (timestamp - lastTimestamp) / 1000;
                    accumulatedTime += deltaTime;
                  }
                  tDispatch({
                    type: "SET_SECONDS",
                    payload: accumulatedTime,
                  });
                  lastTimestamp = timestamp;
                  // }
                  // frame++;
                  animationFrameId = requestAnimationFrame(startTimeline);
                };

                startTimeline();
              }
              setPlayToggle(true);
            }}
          >
            <PlayIcon />
          </div>
        )}
        {(audioPlaying || playToggle === true) && (
          <div
            className="control-button"
            onClick={() => {
              if (loading) return;
              globalAudioManager.pause();
              setPlayToggle(false);
              tDispatch({
                type: "SNAP_TO_GRID",
              });
              cancelAnimationFrame(animationFrameId);
            }}
          >
            <PauseIcon />
          </div>
        )}
        <div
          style={{
            marginLeft: "auto",
            marginRight: "10px",
            display: "flex",
            justifySelf: "center",
            cursor: "pointer",
          }}
        >
          <div className="timeline-zoom-in">
            <ZIn
              style={{ pointerEvents: "all" }}
              onClick={() => {
                tDispatch({
                  type: "ADD_RANGE",
                  payload: {
                    start: 0,
                    end: -ZOOM_STRENGTH,
                  },
                });
              }}
            />
          </div>
          <div className="timeline-zoom-out">
            <ZOut
              style={{ pointerEvents: "all", marginLeft: "10px" }}
              onClick={() => {
                tDispatch({
                  type: "ADD_RANGE",
                  payload: {
                    start: 0,
                    end: ZOOM_STRENGTH,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      {audioOnPageExists ? (
        <div
          style={{
            height: WAVE_HEIGHT,
            marginTop: TOP_STRIP_HEIGHT,
            paddingLeft: 10,
            backgroundColor: "#f5f5f5",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "flex",
          }}
        >
          {audioName}
          {loading && <LoadingSvg height={19} />}
        </div>
      ) : null}
      <div
        className="left-right-border"
        style={{
          position: "absolute",
          height: heightInPx,
          width: "1px",
          backgroundColor: "darkgrey",
          right: 0,
        }}
      ></div>
    </div>
  );
}

function TimelineTimeCounter() {
  const { globalAudioManager, lastKnownTime, currentAudioUrl } = useGlobalAudioState();
  const tDispatch = useTimelineDispatch();
  const [currentSongTime, setCurrentSongTime] = useState(globalAudioManager.startPosition);
  const minutes = Math.floor(currentSongTime / 60);
  const seconds = Math.floor(currentSongTime % 60);
  const milliseconds = Math.floor((currentSongTime % 1) * 100);

  const uiMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const uiSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const uiMilliseconds = milliseconds < 10 ? `0${milliseconds}` : milliseconds;

  useEffect(() => {
    globalAudioManager.listenToCounter((seconds) => {
      setCurrentSongTime(seconds);
    });
    setCurrentSongTime(globalAudioManager.startPosition);
    return () => {
      globalAudioManager.stopListeningToCounter();
      setCurrentSongTime(globalAudioManager.startPosition);
    };
  }, [lastKnownTime, currentAudioUrl]);

  return (
    <div className="timline-timer">
      <strong>Timeline: </strong>
      <span>
        {uiMinutes}:{uiSeconds}:{uiMilliseconds}
      </span>
    </div>
  );
}
