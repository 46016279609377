import React from "react";
import { AnnotationMenuIconType } from "../AnnotationMenu";
import AnnotationCircle from "../icons/AnnotationCircle";
import AnnotationRoundedSquare from "../icons/AnnotationRoundedSquare";
import AnnotationSquare from "../icons/AnnotationSquare";
import AnnotationTriangle from "../icons/AnnotationTriangle";

export const AnnotationShapIcons: AnnotationMenuIconType[] = [
  {
    name: "circle",
    icon: <AnnotationCircle />,
  },
  {
    name: "roundedSquare",
    icon: <AnnotationRoundedSquare />,
  },
  {
    name: "square",
    icon: <AnnotationSquare />,
  },
  {
    name: "triangle",
    icon: <AnnotationTriangle />,
  },
];
