import React, { useState } from "react";
import MenuNewTableSize from "./MenuNewTableSize";

function AddTableButton() {
  const [isSizeMenuOpen, setIsSizeMenuOpen] = useState(false);

  return (
    <>
      <button
        id="wysiwyg-table-button"
        className="wysiwyg-toolbar-btn"
        onClick={() => {
          setIsSizeMenuOpen(true);
        }}
      >
        <span className="icon-table-icon"></span>
      </button>
      <MenuNewTableSize isSizeMenuOpen={isSizeMenuOpen} setIsSizeMenuOpen={setIsSizeMenuOpen} />
    </>
  );
}

export default AddTableButton;

{
  /* <button id="wysiwyg-table-button" className="wysiwyg-toolbar-btn" >
          <span className="icon-table-icon"></span>
        </button> */
}
