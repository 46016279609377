import React, { PropsWithChildren, useEffect, useLayoutEffect } from "react";
import { useGlobalAudioState } from "../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { ProvidePointerCapturing } from "../../contexts/TimelineProvider/PointerCapturing";
import PointerEventsHandler from "../../contexts/TimelineProvider/PointerEventsHandler";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../hooks/useDomRef";
import GridBackground from "./GridBackground";
import Left from "./Left/Left";
import Right from "./Right/Right";
import RightOverlay from "./Right/RightOverlay";
import LeftOverlay from "./Left/LeftOverlay";
import "./TimelinePanel.css";
import VerticalScrollContainer, { useReceiveVerticalWheelEvent } from "./VerticalScrollContainer";
import useRefAndState from "../../hooks/useRefAndState";
export const TOP_STRIP_HEIGHT = 42;
export const WAVE_HEIGHT = 22;
export const OBJECT_ROW_HEIGHT = 28;
export const BAR_HEIGHT = OBJECT_ROW_HEIGHT - 1;
export const zIndexes = (() => {
  const s = {
    rightBackground: 0,
    scrollableArea: 0,
    rightOverlay: 0,
    lengthIndicatorCover: 0,
    lengthIndicatorStrip: 0,
    playhead: 0,
    currentFrameStamp: 0,
    marker: 0,
    horizontalScrollbar: 0,
  };

  // sort the z-indexes
  let i = -1;
  for (const key of Object.keys(s)) {
    // @ts-ignore
    s[key] = i;
    i++;
  }

  return s;
})();
function RightSideContainer({ children }: PropsWithChildren<any>) {
  const [divRef, setDivRef] = useDomRef();
  const [timeline, dispatch] = useTimeline();
  const gas = useGlobalAudioState();

  useEffect(() => {
    return () => {
      gas.globalAudioManager.pause();
    };
  }, []);
  useEffect(() => {
    if (divRef) {
      dispatch({ type: "SET_WIDTH_PX", payload: divRef.clientWidth });
      dispatch({ type: "SET_HEIGHT_PX", payload: divRef.clientHeight });
    }
  }, [divRef?.clientWidth, divRef?.clientHeight]);

  return (
    <div
      ref={setDivRef}
      className="right-side-container"
      style={{
        width: "70%",
        height: "100%",
        position: "absolute",
        right: 0,
      }}
    >
      {children}
    </div>
  );
}
export default function TimelinePanel() {
  return (
    <ProvidePointerCapturing>
      <PointerEventsHandler>
        <div className="timeline-panel-container">
          <div className="relative-container">
            <div className="left-background"></div>
            <GridBackground />

            <VerticalScrollContainer>
              <LeftScrollableArea />
              <Left />

              <RightSideContainer>
                <Right />
              </RightSideContainer>
            </VerticalScrollContainer>
            <LeftOverlay />
            <RightOverlay />
          </div>
        </div>
      </PointerEventsHandler>
    </ProvidePointerCapturing>
  );
}
const listenerOptions = {
  capture: true,
  passive: false,
};
function LeftScrollableArea() {
  const [ref, refState] = useRefAndState<HTMLDivElement | null>(null);
  const receiveVerticalWheelEvent = useReceiveVerticalWheelEvent();

  useLayoutEffect(() => {
    if (!refState) {
      return;
    }
    const handleWheel = (e: WheelEvent) => {
      receiveVerticalWheelEvent(e);
      e.preventDefault();
      e.stopPropagation();
    };
    refState.addEventListener("wheel", handleWheel, listenerOptions);

    return () => {
      refState.removeEventListener("wheel", handleWheel, listenerOptions);
    };
  }, [refState]);

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        pointerEvents: "all",
        width: "30%",
        height: "100%",
        left: "0",
        top: "0",
      }}
    ></div>
  );
}
