import React, { useEffect, Dispatch, SetStateAction, useState, useRef, useContext } from "react";
import { TwitterPicker } from "react-color";
import _ from "lodash";
import { formatFunctions } from "../WYSIWYGToolbarFunctions/WYSIWYGToolbarFunctions";
// import { bgColors, colors } from '../../Annotation/AnnotationTextMenu/constants';
import { ILessonAdditionalSettingsContext, LessonAdditionalSettingsContext } from "../../../routes/builderContexts";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { attach } from "@react-three/fiber/dist/declarations/src/core/utils";

type ColorPickerType = {
  colors: string[];
  isShown: boolean;
  keyValue: "fontColor" | "backgroundColor" | "borderColor" | "annotationFontColor";
  handleFormatChange(e: any, funct: any, color?: any, keyValue?: string): void;
  setIsShown(boolean: boolean): void;
};

const ColorPicker = (props: ColorPickerType): JSX.Element => {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const { colors, isShown, keyValue, handleFormatChange, setIsShown } = props;
  const pickerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  // const [selectedColor, setSelectedColor]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [selectedColor, setSelectedColor]: [string, Dispatch<SetStateAction<string>>] = useState<string>("");
  const [transparent, setTransparent]: [HTMLDivElement | null, Dispatch<SetStateAction<HTMLDivElement | null>>] =
    useState<HTMLDivElement | null>(null);

  const currentColors = useContext<ILessonAdditionalSettingsContext>(LessonAdditionalSettingsContext)?.getColorsList(
    colors,
  );

  useEffect(() => {
    const transparentDiv: HTMLDivElement | null = document.querySelector("div[title='transparent']");
    // keyValue !== 'fontColor' ? setdColors(bgColors) : setdColors(colors);
    setTransparent(transparentDiv);
  }, []);

  const useCloseArrowFillPicker = (ref: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
      const handleClickOutside = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
          setIsShown(false);
        }
      };
      if (isShown) {
        document.addEventListener("mousedown", handleClickOutside);
      }
      return () => document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);
  };

  useCloseArrowFillPicker(pickerRef);

  if (transparent) {
    transparent.onclick = () => handleFormatChange(null, formatFunctions.changeColor, "transparent", keyValue);
  }

  function handleChange(color: any, e: any) {
    // setSelectedColor(color);
    if (objectsState.selectedObjects[0] && objectsState.selectedObjects[0].type !== "table") {
      if (keyValue === "backgroundColor") {
        objectsDispatch({
          type: ObjectActionsType.SET_BACKGROUND_COLOR,
          payload: { color: color, objectId: objectsState.selectedObjects[0].objectId },
        });
      } else if (keyValue === "borderColor") {
        objectsDispatch({
          type: ObjectActionsType.SET_BORDER_COLOR,
          payload: { color: color, objectId: objectsState.selectedObjects[0].objectId },
        });
      } else if (keyValue === "fontColor") {
        objectsDispatch({
          type: ObjectActionsType.SET_ANNOTATION_FONT_COLOR,
          payload: { color: color, objectId: objectsState.selectedObjects[0].objectId },
        });
      }
    } else if (objectsState.selectedObjects[0] && objectsState.selectedObjects[0].type === "table") {
      if (keyValue === "backgroundColor") {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_TABLE,
          payload: { type: "backgroundColor", value: color.hex },
        });
      } else if (keyValue === "borderColor") {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_TABLE,
          payload: { type: "borderColor", value: color.hex },
        });
      }
    } else {
      handleFormatChange(e, formatFunctions.changeColor, color.hex, keyValue);
    }
    setSelectedColor(color);
    setIsShown(false);
  }

  return (
    <div
      ref={pickerRef}
      //  style={{left: pickerPosition}}
    >
      <TwitterPicker
        color={selectedColor}
        colors={
          //colors
          currentColors
        }
        onChange={(color: any, e: any) => handleChange(color, e)}
      />
      <span></span>
    </div>
  );
};

export default ColorPicker;
