import React, { useState, useContext } from "react";
// import genericRepositoryService from '../services/genericRepositoryService'
// import previewIcon from '../assets/icons/ld-preview-icon.png';
// import loadingGif from  '../assets/Bean_Eater-1s-200px.gif';

// import { Link, Route } from 'react-router-dom';
import { ReactComponent as PreviewLessonIcon } from "../assets/icons/HeaderIcons/preview-lesson-icon.svg";
import { ReactComponent as ArrowIcon } from "../assets/icons/HeaderIcons/drop-down.svg";
// import {animated, useSpring} from 'react-spring'
import { ILessonPageActionsContext, LessonPageActionsContext } from "../routes/builderContexts";
// import Page from './Page';
import { AbstractTooltip as Tooltip } from "./ToolTip/ToolTip";
import { useObjectsState } from "../contexts/ObjectsProvider";
import { useInteracitvityHotspotState } from "../contexts/InteractivityHotspotProvider";
import { useLessonPagesState } from "../contexts/LessonPagesProvider/LessonPagesProvider";
// function FilePart (bigPath: string) : string {

//     let group = bigPath.split("/");
//     let filename = group[group.length -1];
//     return filename;
// }
type LessonModes = "Demo" | "Practice" | "Perform";
const LessonPreview: React.FC<any> = (props: any) => {
  const lessonPageActions: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const [dropDownVisible, setDropDownVisible]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const { currentLessonPage } = useLessonPagesState();
  const link = "/api/PassThru/cpat/lesson/lessonpreview/" + props.lessonVersionId + ".preview/index.html";
  const objectsState = useObjectsState();
  const interactivityHotspotState = useInteracitvityHotspotState();
  // const isDirty = objectsState.objectList.find((o) => {
  //   return o.
  // })
  const isFreeForm =
    currentLessonPage?.pagePlayerType === "Base" && currentLessonPage?.pageManifest?.basePageType === "freeForm";
  const needsSave = isFreeForm ? objectsState.needSave : lessonPageActions.dirt !== 0;
  let previewLinkI = link + "?clasmode=Instructional";
  let previewLinkP = link + "?clasmode=Practice";
  let previewLinkT = link + "?clasmode=Test";

  let previewText = "Preview";
  if (undefined != props.pagenumber) {
    previewLinkI = previewLinkI + "&previewpageindex=" + props.pagenumber;
    previewLinkP = previewLinkP + "&previewpageindex=" + props.pagenumber;
    previewLinkT = previewLinkT + "&previewpageindex=" + props.pagenumber;
    previewText = "Page Preview";
  } else {
  }

  function canUse() {
    switch (lessonPageActions.previewMode) {
      case "Demo":
        return previewLinkI;
      case "Practice":
        return previewLinkP;
      case "Perform":
        return previewLinkT;
    }
    return "";
  }

  return (
    <div className="preview-component" data-tooltip-id="Preview Lesson">
      <Tooltip id="Preview Lesson" />
      <div className="previewButtonContainer">
        {!needsSave ? (
          <a href={canUse()} target="_blank" rel="noreferrer">
            {" "}
            <div className="header-icon">
              {objectsState.needSave ? (
                <PreviewLessonIcon className="disabled-preview" />
              ) : (
                <>
                  <PreviewLessonIcon />{" "}
                </>
              )}
            </div>
          </a>
        ) : (
          <div className="header-icon">
            <PreviewLessonIcon className="disabled-preview" />
          </div>
        )}
        <div className="preview-modes-dropdown-container" onClick={() => setDropDownVisible(!dropDownVisible)}>
          <p>{lessonPageActions.previewMode}</p>
          <ArrowIcon style={dropDownVisible ? { transform: "rotate(180deg)" } : {}} className="header-preview-svg" />
          <div className="preview-modes-selection-container" style={dropDownVisible ? {} : { display: "none" }}>
            <div className="preview-modes-selection-choices" onClick={() => lessonPageActions.setPreviewMode("Demo")}>
              Demo
            </div>
            <div
              className="preview-modes-selection-choices"
              onClick={() => lessonPageActions.setPreviewMode("Practice")}
            >
              Practice
            </div>
            <div
              className="preview-modes-selection-choices"
              onClick={() => lessonPageActions.setPreviewMode("Perform")}
            >
              Perform
            </div>
          </div>
        </div>
      </div>
      <p>Lesson Preview {needsSave && "(Save Lesson)"}</p>
    </div>
  );
};

export default LessonPreview;
