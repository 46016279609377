import { useUIStore } from "../../contexts/MovableElementsPlaneProvider";

export const conversion = {
  secondToMillisecond(value: number): number {
    return value * 1000;
  },
  millisecondToNearestSecond(value: number): number {
    return Math.round(value / 1000);
  },
  millisecondsToQuarterSeconds(milliseconds: number): number {
    const seconds = milliseconds / 1000;
    const quarterSeconds = Math.round(seconds * 4) / 4;
    return quarterSeconds;
  },
};

export function percentageToValue(percentage: number, total: number): number {
  return (percentage / 100) * total;
}
export function xAxisViewPercentageToPixel(percentage: number) {
  const viewWidth = useUIStore.getState().designerViewportDims.width;
  return percentageToValue(percentage, viewWidth);
}
export function yAxisViewPercentageToPixel(percentage: number) {
  const viewHeight = useUIStore.getState().designerViewportDims.height;
  return percentageToValue(percentage, viewHeight);
}
export function valueToPercentage(value: number, total: number): number {
  return (value / total) * 100;
}
export function percentageToPX(percentage: number, total: number): number {
  return Math.round((percentage / 100) * total);
}
