/**
 * LessonPages
 * lessonPages
 */

import { PropsWithChildren, createContext, useContext, useReducer } from "react";

import { produce } from "immer";
import { getObjectsFromManifest, runOnObjects } from "../../utils/PageManifestParsing";
import { builderHelper } from "../../routes/builderHelper";

/**
 * the time sheet is a data structure to help with the viewing of a sheet with objects
 * in relation to time for example maybe something like this:
 * TimeSheet {
 *   objectList: Object[]
 * }
 *
 */

export enum LessonPagesActions {
  UPDATE_CURRENT_PAGE,
  UPDATE_LESSON_PAGES, // currently dumbly setting the lesson pages
  UPDATE_PAGE_ORDER,
  SET_CURRENT_PAGE_INDEX,
  UPDATE_CURRENT_PAGE_MANIFEST,
  ADD_ANIMATED_OBJECT,
  ADD_NEW_PAGE,
  UPDATE_SEQUENCE_LENGTH,
  UPDATE_SELECTED_OBJECT_FRAMES,
  SET_OBJECTS_ON_PAGE_MANIFEST,
  DELETE_PAGE,
}

interface LessonPagesState {
  lessonPages: any[];
  currentLessonPage: any;
  currentLessonPageIndex: number;
  objects: any[];
}

type LessonPagesAction = {
  type:
    | LessonPagesActions.UPDATE_CURRENT_PAGE
    | LessonPagesActions.UPDATE_LESSON_PAGES
    | LessonPagesActions.SET_CURRENT_PAGE_INDEX
    | LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST
    | LessonPagesActions.ADD_ANIMATED_OBJECT
    | LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST
    | LessonPagesActions.ADD_NEW_PAGE
    | LessonPagesActions.DELETE_PAGE
    | LessonPagesActions.UPDATE_PAGE_ORDER
    | LessonPagesActions.UPDATE_SEQUENCE_LENGTH;
  payload?: any;
};
type LessonPagesReducer = (s: LessonPagesState, a: LessonPagesAction) => LessonPagesState;

type LessonPagesDispatch = (action: LessonPagesAction) => void;
const initialState: LessonPagesState = {
  lessonPages: [],
  currentLessonPage: null,
  currentLessonPageIndex: 0,
  objects: [],
};

const LessonPagesState = createContext<LessonPagesState>(initialState);
const LessonPagesDispatch = createContext<any>({});

const lessonPagesReducer = (state: LessonPagesState, action: LessonPagesAction): LessonPagesState => {
  switch (action.type) {
    case LessonPagesActions.UPDATE_CURRENT_PAGE: {
      const updates = action.payload;

      const lessonPages = state.lessonPages.map((page, index) => {
        if (index === state.currentLessonPageIndex) {
          return {
            ...page,
            ...updates,
          };
        }
        return page;
      });

      return {
        ...state,
        lessonPages,
        currentLessonPage: action.payload,
      };
    }
    case LessonPagesActions.UPDATE_CURRENT_PAGE_MANIFEST: {
      const updates = action.payload;

      const lessonPages = state.lessonPages.map((page, index) => {
        if (index === state.currentLessonPageIndex) {
          return {
            ...page,
            pageManifestIsDirty: true,
            pageManifest: {
              ...page.pageManifest,
              ...updates,
            },
          };
        }
        return page;
      });

      return {
        ...state,
        lessonPages,
        currentLessonPage: action.payload,
      };
    }
    case LessonPagesActions.UPDATE_PAGE_ORDER: {
      const { pageToGoTo, pageIndex } = action.payload;
      const updatePageOrder = produce((lessonPages) => {
        const pageToMove = lessonPages[pageIndex];
        lessonPages.splice(pageIndex, 1);
        lessonPages.splice(pageToGoTo, 0, pageToMove);
        lessonPages.forEach((page: any, index: number) => {
          if (page.order !== index) {
            page.order = index;
            page.lessonPageIsDirty = true;
          }
        });
      });

      const lessonPages = updatePageOrder(state.lessonPages);
      return {
        ...state,
        lessonPages,
        currentLessonPageIndex: pageToGoTo,
        currentLessonPage: lessonPages[pageToGoTo],
      };
    }
    case LessonPagesActions.UPDATE_LESSON_PAGES: {
      return {
        ...state,
        lessonPages: [...action.payload],
      };
    }
    case LessonPagesActions.UPDATE_SEQUENCE_LENGTH: {
      const sequenceLength = action.payload;
      const updateSequenceLength = produce((lessonPages) => {
        lessonPages[state.currentLessonPageIndex].pageManifestIsDirty = true;
        const pm = lessonPages[state.currentLessonPageIndex].pageManifest;
        if (!pm.timeline) {
          pm.timeline = {
            animatedObjects: [],
            sequenceLength,
          };
        }
        pm.timeline.sequenceLength = sequenceLength;
      });
      const lessonPages = updateSequenceLength(state.lessonPages);
      return {
        ...state,
        lessonPages,
        currentLessonPage: lessonPages[state.currentLessonPageIndex],
      };
    }
    case LessonPagesActions.SET_CURRENT_PAGE_INDEX: {
      const newIndex = action.payload;
      const currentLessonPage = state.lessonPages[newIndex];
      return {
        ...state,
        currentLessonPageIndex: action.payload,
        currentLessonPage,
      };
    }

    case LessonPagesActions.ADD_NEW_PAGE: {
      const newPage = action.payload.page;
      const newPageIndex = action.payload.pageIndex;
      const addNewPage = produce((lessonPages) => {
        lessonPages.splice(newPageIndex, 0, newPage);
        lessonPages.forEach((page: any, index: number) => {
          if (page.order !== index) {
            page.order = index;
            page.lessonPageIsDirty = true;
          }
        });
      });
      const lessonPages = addNewPage(state.lessonPages);
      return {
        ...state,
        lessonPages,
        currentLessonPageIndex: newPageIndex,
        currentLessonPage: lessonPages[newPageIndex],
      };
    }
    case LessonPagesActions.DELETE_PAGE: {
      const deletePage = produce((lessonPages) => {
        lessonPages.splice(state.currentLessonPageIndex, 1);
        lessonPages.forEach((page: any, index: number) => {
          if (page.order !== index) {
            page.order = index;
            page.lessonPageIsDirty = true;
          }
        });
      });
      const lessonPages = deletePage(state.lessonPages);
      return {
        ...state,
        lessonPages,
        currentLessonPage: lessonPages[state.currentLessonPageIndex],
        currentLessonPageIndex: state.currentLessonPageIndex - 1,
      };
    }
    case LessonPagesActions.ADD_ANIMATED_OBJECT: {
      const objectId = action.payload;
      const type = action.type;
      const addAnimatedObject = produce((lessonPages) => {
        lessonPages[state.currentLessonPageIndex].pageManifestIsDirty = true;
        const pm = lessonPages[state.currentLessonPageIndex].pageManifest;
        // if there is not a timeline object in the page manifes add the default one
        if (!pm.timeline) {
          pm.timeline = {
            animatedObjects: [],
            sequenceLength: 30,
          };
        }
        pm.timeline.animatedObjects.push({
          id: objectId,
          start: 0,
          end: null,
          type,
        });
      });
      const lessonPages = addAnimatedObject(state.lessonPages);
      return {
        ...state,
        lessonPages,
        currentLessonPage: lessonPages[state.currentLessonPageIndex],
      };
    }
    case LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST: {
      const { animatedObjects, images, textBoxes, annotations, videos, scorm, tables, hotspots } = action.payload; // in the flattened format, need to convert to pageManifest
      const updatePageManifest = produce((lessonPages) => {
        const pm = lessonPages[state.currentLessonPageIndex].pageManifest;
        const currentPage = lessonPages[state.currentLessonPageIndex];
        if (hotspots) {
          pm.hotspots = hotspots;
          currentPage.pageManifestIsDirty = true;
        }
        if (images) {
          pm.pageImage = images;
          currentPage.pageManifestIsDirty = true;
        }
        if (textBoxes) {
          pm.textBlock = textBoxes;
          currentPage.pageManifestIsDirty = true;
        }
        if (annotations) {
          pm.annotations = annotations;
          currentPage.pageManifestIsDirty = true;
        }
        // if (panoramicList) {
        //   pm.panoramicList = panoramicList;
        //   currentPage.pageManifestIsDirty = true;
        // }
        if (animatedObjects) {
          //lazy initialize timeline
          if (!pm.timeline) {
            pm.timeline = {
              animatedObjects: [],
              sequenceLength: 30,
            };
          }
          pm.timeline.animatedObjects = animatedObjects;
          currentPage.pageManifestIsDirty = true;
        }
        if (tables) {
          pm.tables = tables;
          currentPage.pageManifestIsDirty = true;
        }
        if (videos) {
          if (videos[0]) {
            pm.video = videos[0];
            currentPage.pageManifestIsDirty = true;
          } else {
            if (pm.video) {
              delete pm.video;
              currentPage.pageManifestIsDirty = true;
            }
          }
        } else {
          delete pm.video;
        }
        if (scorm) {
          if (!pm.pageScormObject) {
            pm.pageScormObject = [];
          }
          pm.pageScormObject[0] = scorm;
          currentPage.pageManifestIsDirty = true;
        } else {
          if (pm.pageScormObject) {
            delete pm.pageScormObject;
            currentPage.pageManifestIsDirty = true;
          }
        }
      });
      const lessonPages = updatePageManifest(state.lessonPages);
      return {
        ...state,
        lessonPages,
      };
    }
    default:
      return state;
  }
};

export function LessonPagesProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer<LessonPagesReducer>(lessonPagesReducer, initialState); // TODO use reducer

  return (
    <LessonPagesDispatch.Provider value={dispatch}>
      <LessonPagesState.Provider value={state}>{children}</LessonPagesState.Provider>
    </LessonPagesDispatch.Provider>
  );
}

export function useLessonPagesDispatch() {
  const ctx = useContext(LessonPagesDispatch);
  if (ctx === undefined) {
    throw new Error("Wrap component in LessonPages Provider");
  }
  return ctx as LessonPagesDispatch;
}
export function useLessonPagesState() {
  const ctx = useContext(LessonPagesState);
  if (ctx === undefined) {
    throw new Error("Wrap component in LessonPages Provider");
  }
  return ctx;
}
