import "./fmsDesigner.css";
import _, { forEach } from "lodash";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
  PageContext,
  IFMSObject,
  IPageContext,
  LessonPageActionsContext,
  ILessonPageActionsContext,
  IAssetManagementContext,
  AssetContext,
} from "../../routes/builderContexts";
import addDescriptionIcon from "../../assets/icons/add-description.png";
import successCriteriaIcon from "../../assets/icons/success-criteria.png";
// import ImageButton from "../../assets/icons/ld-upload-image-icon.png";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import { IFmsPageManifest } from "./Interfaces/IFmsPageManifest";
import genericRepositoryService from "../../services/genericRepositoryService";
import { ICdu } from "./Interfaces/ICdu";
// import IApiResponse from '../../models/IApiResponse';
import CduDisplay from "./components/cduDisplay";
import CduCircleButtons from "./components/cduCircularButtons";
import CduRectangularButtons from "./components/cduRectangularButtons";
import CduButtonGroupings from "./components/cduButtonGroupings";
import CDUCellHighlights from "./components/CDUCellHighlights";
import FMSPolyGroupings from "./components/FMSPolyGroup";
import CduImageOverlays from "./components/cduImageOverlays";
import CduLights from "./components/cduLights";
import Description from "./components/PageObjective";
import CduSingleLineEditor from "./components/cduSingleLineEditor";
import EmptyEditingScreen from "./components/emptyEditingScreen";
import SuccessCriteriaPage from "./components/successCriteriaPage/successCriteriaPage";
import KeyDataEditor from "./components/keyDataEditor/keyDataEditor";
import KeyDataManager from "./components/keyDataEditor/KeyDataManager";
import { IFmsContext, FmsContext } from "./Interfaces/fmsContext";
import CduPageOverlays from "./components/cduPageOverlays";
import CDUPageButtons from "./components/CDUmodal/CDUPageButtons";
import CDUCellCursor from "./components/CDUCellCursor";
import CDUDropDown from "./components/CDUDropDown";
import CDUHoldingDisplay from "./components/CDUHoldingDisplay";
import blankFunctionKeys from "./assets/blankFunctionKeyes.json";
import WYSIWYG from "../../components/WYSIWYG/WYSIWYG";
import CDUmodal from "./components/CDUmodal/CDUmodal";
import backgroundLogo from "../../assets/ld-cpat-logo-background.png";
//import ModalEditor from "./components/ModalEditor";
import { IDandPath, crawlAudioArray as crawlAudioArray } from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";
import lodash from "lodash";
import { CDUScreenObjects } from "./components/CDUScreenObjects";

export const initialCDU: ICdu = {
  foreignObjects: [],
  image: { href: "", width: 0, height: 0 },
  circleObjects: [],
  rectangularObjects: [],
  lightObjects: [],
  groupings: {
    alpha: {},
    numeric: {},
    function: {},
    lineSelectLeft: {},
    lineSelectRight: {},
  },
  groupingObjects: [],
  polyGroupings: [],
  pageNames: {},
  cduAssets: [],
  modalIndex: {},
  cduScreen: {
    xCoordinate: 0,
    yCoordinate: 0,
    width: "0px",
    height: "0px",
    href: "",
  },
  paging: false,
};

const FmsDesigner: React.FC = () => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageManifest: IFmsPageManifest = pageContext.pageManifest as IFmsPageManifest;
  const fmsContext: IFmsContext = useContext<IFmsContext>(FmsContext);
  // const [pageFailedToLoad, setPageFailedToLoad]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const [currentCduPageName, setCurrentCduPageName]: [string, React.Dispatch<React.SetStateAction<string>>] =
    useState<string>("");
  // const [cduInformation, setCduInformation]: [ICdu, React.Dispatch<React.SetStateAction<ICdu>>] = useState<ICdu>(initialCDU);

  const [cduPageToEdit, setCduPageToEdit] = useState<string>(pageManifest.bootPage);
  const [criteriaIndex, setCriteriaIndex] = useState<number>(-1); //used for determining what success criteria the designer is working on
  const [editingCduScreen, setEditingCduScreen] = useState<boolean>(false);
  const [editorComponent, setEditorComponent]: [
    React.ReactElement,
    React.Dispatch<React.SetStateAction<React.ReactElement>>,
  ] = useState<React.ReactElement>(<EmptyEditingScreen />);
  const [gridBorder, setGridBorder] = useState<string>("1px dashed #ffffff"); //grid for cdu display
  const [highlightedGroups, setHighlights] = useState<string[]>([]);
  const [highlightFrom, setHighlightFrom] = useState<string>("");
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [keyEventOpensEditor, setEvent] = useState<boolean>(false);
  const [keyList, setKeyList] = useState<string[]>([]);
  const [selectedCduCell, setSelectedCduCell] = useState<string>("");
  const [selectedKey, setKey] = useState<string>();
  const [triggerReRender, toggleReRender] = useState<boolean>(false); //used to refresh the RTE in the CduSingleLineEditor component
  const [currentModal, setCurrentModal] = useState<any>({});
  const [showCduModal, setShowCduModal] = useState<boolean>(false);
  const [holdingDisplayEditorShown, setHoldingDisplayEditorShown] = useState<boolean>(false);
  const [imageToDisplayIndex, setImageToDisplayIndex] = useState(0);

  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const [dropDownValues, setDropDownValues] = useState<any>({});
  const [dropDownParent, setDropDownParent] = useState<any>({});

  const [cduCursorObject, setCduCursorObject] = useState(
    pageManifest.cdu.includes("a350")
      ? {
          cduKeyName: "fms-select",
          xCoordinate: 465,
          yCoordinate: 8,
          width: 115,
          height: 24,
        }
      : {
          cduKeyName: "fms1",
          id: "fms1",
          xCoordinate: 468,
          yCoordinate: 4,
          width: 42,
          height: 44,
        },
  );
  const [cursorSelected, setCursorSelected] = useState<boolean>(false);
  const [currentCDUPageData, setCurrentCDUPageData] = useState<any>();
  const currentCDU = lessonPageActionsContext.fmsObject.cduObject;
  const [fontSizeBase, setFontSizeBase] = useState("16px");
  const [pageButtons, setPageButtons] = useState<any>([]);

  const currentVersion = 20210312; // dropped the "B" to simplfy warning check
  const visibleChange = true; //  Does the changes constitute a visible change to the presented data
  // if visibleChange = true then when you update the previousVersion copy value to lastVisibleChange
  const previousVersion = 20210223; // dropped the "B" to simplfy warning check
  const lastVisibleChange = 20210223; // This should be the last version that had visibleChange = true

  const versionChangeWarning = () => {
    if (visibleChange === true || previousVersion < lastVisibleChange || previousVersion === lastVisibleChange) {
      doWarning();
    }
  };

  const doWarning = () => {
    // We need a warning message designed by Sabelyz and approved by Kent.  The following ALERT is a stand in.
  };

  const addVersion = (currentVersion: number) => {
    pageManifest.version = currentVersion;

    pageContext.updatePageManifest(pageManifest);
  };
  const versionCheck = (currentVersion: number) => {
    if (currentVersion !== pageManifest.version) {
      versionChangeWarning();
      addVersion(currentVersion);
    }
  };

  useEffect(() => {
    if (pageContext.page.pageManifestIsDirty === false || pageContext.page.pageManifestIsDirty === undefined) {
      return;
    } else {
      !pageManifest.version || pageManifest.version !== undefined || pageManifest.version !== null
        ? versionCheck(currentVersion)
        : addVersion(previousVersion); //different from orig
    }
  }, [pageContext.page.pageManifestIsDirty]);

  // #### End of version system
  // #### End of version system
  // #### End of version system

  useEffect(() => {
    let tempIds: number[] = [];

    const idArr = () => {
      tempIds.push(pageManifest.assetVersionId);

      _.forEach(pageManifest.Audio, (audio) => {
        if (typeof audio.Version === "number") {
          tempIds.push(audio.Version);
        }
      });

      if (pageManifest.pageImage) {
        _.forEach(pageManifest.pageImage, (image) => {
          tempIds.push(image.assetVersionId);
        });
      }
    };

    idArr();

    if (!_.isEqual(pageContext.pageAssetIds, tempIds) && tempIds.length > 0) {
      pageContext.setPageAssetIds(tempIds);
    }

    return () => {
      tempIds = [];
    };
  }, [pageManifest.assetVersionId, pageManifest.Audio?.length, pageManifest.pageImage, pageContext.pageAssetIds]);

  useEffect(() => {
    setCurrentCduPageName(cduPageToEdit);
  }, [cduPageToEdit]);

  useEffect(() => {
    const getCduPageData = async () => {
      //for intial load of A220 A350 and other CDU
      //reads the page templates dictionary from the CDU collection to make an api call for that specific page.
      //Once we have the page template we need to stores irs rectangular objects and foreign objects. The image path of the page graphic neeeds to be displayed
      //the page manifest.pages object needs to be updated with the pageName as the object's name and the CDU cell IDs from the foreign objects as variables EX - pages["INIT"] = {CDUCELLONE: ""}
      //the page manifest has to be updated before it leaves the function, we have to be careful about this because calling the update manifest multiple times in this useEffect
      //can cause an infinite loop of re-renders
      const pageTemplates = lessonPageActionsContext.fmsObject?.cduObject?.pageTemplates;
      if (pageTemplates) {
        const selectedPageTemplate = pageTemplates[pageManifest.bootPage];
        const response: any = await genericRepositoryService.blobFetch(
          pageManifest.cduPath + selectedPageTemplate.pagePath,
        ); // gets data for init page when creating a new lesson page
        if (response.isSuccess) {
          const foreignObjects = response.data.foreignObjects.reduce(
            (obj: any, item: any) => ((obj[item.id] = ""), obj),
            {},
          );
          const pageButtons = response.data.rectangularObjects;
          // let foreignObjects = {}

          lessonPageActionsContext.fmsObject.cduObject.foreignObjects = response.data.foreignObjects;
          //somewhere in here things need to change

          if (
            pageManifest.pages[selectedPageTemplate.id] &&
            Object.keys(pageManifest.pages[selectedPageTemplate.id]) !== Object.keys(foreignObjects)
          ) {
            pageManifest.pages[selectedPageTemplate.id] = foreignObjects;
          }
          if (!pageManifest.pages[selectedPageTemplate.id]) {
            pageManifest.pages[selectedPageTemplate.id] = foreignObjects;
          }

          //
          function addOverlays(item: any) {
            //
            if (item.actions?.length > 0) {
              if (item.actions[0].function === "changeImage") {
                //item.actions[0].function not equal to expected value based on pageTemplate.json

                const overLays = pageManifest.pageLayouts[selectedPageTemplate.id]?.overLays;
                if (overLays === undefined) {
                  pageManifest.pageLayouts[selectedPageTemplate.id] = {
                    ...pageManifest.pageLayouts[selectedPageTemplate.id],
                    overLays: [],
                  };
                }
                // ;
                pageManifest.pageLayouts[selectedPageTemplate.id].overLays.push(
                  // overlaysArray.push(
                  {
                    xCoordinate: item.xCoordinate,
                    yCoordinate: item.yCoordinate,
                    width: item.width,
                    height: item.height,
                    image: item.actions[0].parameter.changeImage.assets[0],
                    parentCell: item.cduKeyName,
                  },
                );
              }
            }
          }

          let newOverlays = false;

          const cell = "flt-nbr";
          if (pageContext.pageManifest.pages[pageManifest.bootPage][cell] !== "") {
            //
          }
          if (pageManifest.pageLayouts[selectedPageTemplate.id]?.overLays !== undefined) {
            pageButtons.forEach(addOverlays);
          } else {
            // pageManifest.pageLayouts[selectedPageTemplate.id].overLays = []
            pageButtons.forEach(addOverlays);
            newOverlays = true;
            // pageContext.updatePageManifest(pageManifest);
          }

          // pageContext.updatePageManifest(pageManifest); // uncomment to break things :D (but seriously dont)
          //the changes to the manifest will be undone once it leaves this function due to it being asynchronous, directly updating the manifest will cause re-ordering to crash
          //adding a check similar to the bottom of this useEffect solves this problem but needs to be present in both places since this will need to update A220 pages

          if (!_.isEqual(pageManifest, pageContext.pageManifest)) {
            pageContext.updatePageManifest(pageManifest);
          }
          setCurrentCDUPageData(response.data);
        }
      }
      setIsLoading(false);
    };

    const loadModal = async () => {
      //if there is a boot modal it needs to be loaded and displayed.
      //no manifest update needed since the data should already be populated

      if (pageManifest.pageLayouts && pageManifest.pageLayouts.bootModal && pageManifest.pageLayouts.bootModal !== "") {
        const modalResponse = await genericRepositoryService.blobFetch(
          pageManifest.cduPath + currentCDU.modalIndex[pageManifest.pageLayouts.bootModal].path,
        );
        if (modalResponse.data) {
          setCurrentModal(modalResponse.data);

          setShowCduModal(true);
        }
      }
    };
    function newPageManifestPopulation(fmsData: IFMSObject) {
      const lightList = pageManifest?.lights ? Object.keys(pageManifest?.lights) : [];

      if (fmsData.assetVersionId !== -1) {
        const oldAssetVersionId = pageManifest.assetVersionId;

        const actionKeys = Object.keys(pageManifest.actions);
        if (!actionKeys.includes("knob")) {
          pageManifest.actions["knob"] = [];
        }
        if (!actionKeys.includes("+") || pageManifest.actions["+"].length === 0) {
          pageManifest.actions["+"] = [
            {
              function: "appendToScratchpad",
              conditions: [{ type: "", valueToCheck: "", placeToCheck: "", operator: "" }],
            },
          ];
        }
        if (!actionKeys.includes("SpaceKey")) {
          pageManifest.actions.SpaceKey = [
            {
              function: "appendToScratchpad",
              conditions: [{ type: "", valueToCheck: "", placeToCheck: "", operator: "" }],
            },
          ];
        }
        if (!actionKeys.includes("enterKey")) {
          pageManifest.actions["enterKey"] = [];
        }
        //
        if (
          pageManifest.cdu.includes("787") ||
          pageManifest.cdu.includes("a220") ||
          pageManifest.cdu.includes("a350")
        ) {
          // if (!pageManifest.pageLayouts && pageManifest.cdu.includes("787") || (pageManifest.cdu.includes("a220") || pageManifest.cdu.includes("a350"))){
          //A220 needs a MOD/ACT overlay

          if (
            pageManifest.cdu.includes("a220") &&
            !_.find(pageManifest.pageLayouts[pageManifest.bootPage].overLays, {
              parentCell: "actMod",
            })
          ) {
            pageManifest["pageLayouts"] = {
              INIT: {
                layoutStyle: "standard.json",
                overLays: [
                  {
                    xCoordinate: 510,
                    yCoordinate: 4,
                    width: 78,
                    height: 34,
                    image: "assets/SVG/act.svg",
                    parentCell: "actMod",
                  },
                ],
              },
              overlays: [],
              cursorKey: "",
              cursorType: "pilot_cursor",
            };
          } else {
            pageManifest["pageLayouts"] = {
              INIT: { layoutStyle: "standard.json" },
              overLays: [],
              cursorKey: "",
              cursorType: "pilot_cursor",
            };
          }
        }
        if (pageManifest.cdu.includes("a220") && !pageManifest.actions.directAccess) {
          pageManifest.actions["directAccess"] = [
            {
              function: "cduKeyPress",
              parameter: {
                appendToScratchpad: {},
                changeCDUCell: {
                  destination: ["LSK1_Data_R1"],
                  destinationValues: [""],
                  sourceCell: "Scratchpad",
                  newSourceValue: "",
                },
                cduKeyPress: {
                  keyName: "directAccess",
                },
              },
              conditions: [
                {
                  type: "textAttribute",
                  valueToCheck: "",
                  placeToCheck: "",
                  operator: "",
                },
              ],
            },
          ];
        }
        if (
          !pageManifest.cdu.includes("a220") &&
          pageManifest.pages &&
          pageManifest.pages[pageManifest.bootPage] &&
          !_.has(pageManifest.pages[pageManifest.bootPage], "Scratchpad_Symbol")
        ) {
          pageManifest.pages[pageManifest.bootPage]["Scratchpad_Symbol"] = "";
        }
        const validTitles = ["Enabling Objective:", "Terminal Objective:", "Information:"];
        if (!validTitles.includes(pageManifest.instructions.lessonTitle)) {
          pageManifest.instructions.lessonTitle = "Enabling Objective:";
        }
        //need to put source values into destination array
        const keyStrings = Object.keys(pageManifest.actions);
        keyStrings.forEach((key) => {
          if (
            pageManifest.actions[key] &&
            pageManifest.actions[key].length &&
            key.length > 0 &&
            typeof pageManifest.actions[key] !== "string"
          ) {
            //need to check for invalid code so designer does not crash

            pageManifest.actions[key].forEach((action: any, index: number) => {
              if (
                pageManifest.actions[key][index] &&
                pageManifest.actions[key][index].parameter &&
                pageManifest.actions[key][index].parameter.changeCDUCell &&
                pageManifest.actions[key][index].parameter.changeCDUCell.destination &&
                pageManifest.actions[key][index].parameter.changeCDUCell.sourceCell !== ""
              ) {
                try {
                  pageManifest.actions[key][index].parameter.changeCDUCell.destination.push(
                    pageManifest.actions[key][index].parameter.changeCDUCell.sourceCell,
                  );

                  pageManifest.actions[key][index].parameter.changeCDUCell.destinationValues.push(
                    pageManifest.actions[key][index].parameter.changeCDUCell.newSourceValue,
                  );
                } catch {}
                try {
                  pageManifest.actions[key][index].parameter.changeCDUCell.sourceCell = "";
                  pageManifest.actions[key][index].parameter.changeCDUCell.newSourceValue = "";
                } catch {}
              }
            });
          }
        });
      }

      if (!pageManifest.actions["cursorControlPad"]) {
        pageManifest.actions["cursorControlPad"] = [];
        pageManifest.actions["cursorSelectLeft"] = [];
        pageManifest.actions["cursorSelectRight"] = [];
      }
      const pageKeys = Object.keys(pageManifest.pages);
      pageKeys.map((page) => {
        const cellList = Object.keys(pageManifest.pages[page]);
        if (currentCDU && currentCDU.foreignObjects) {
          currentCDU.foreignObjects.map((object) => {
            if (!cellList.includes(object.cduKeyName)) {
              pageManifest.pages[page][object.cduKeyName] = "";
            }
          });
        }
      });

      if (currentCDU && currentCDU.lightObjects) {
        currentCDU.lightObjects.map((light) => {
          if (pageManifest.lights) {
            if (!lightList.includes(light.id)) {
              pageManifest.lights[light.id] = {
                id: light.id,
                criteriaIndex: null,
                enabledInitially: false,
                toggleIndex: [],
                cduKey: [],
              };
            }
            if (!pageManifest.lights[light.id].toggleIndex) {
              pageManifest.lights[light.id]["toggleIndex"] = [];
            }
          } else {
            pageManifest["lights"] = {};
            currentCDU.lightObjects.map((light) => {
              pageManifest.lights[light.id] = {
                id: light.id,
                criteriaIndex: null,
                toggleIndex: [],
                enabledInitially: false,
                cduKey: [],
              };
            });
          }
        });
      }

      lightList.map((light: any) => {
        const variableList = Object.keys(pageManifest.lights[light]);
        if (!variableList.includes("cduKey")) {
          pageManifest.lights[light]["cduKey"] = [];
        } else if (typeof pageManifest.lights[light]["cduKey"] === "string") {
          if (pageManifest.lights[light]["cduKey"] !== "") {
            const convertedArr = [pageManifest.lights[light]["cduKey"]];
            pageManifest.lights[light]["cduKey"] = convertedArr;
          } else {
            pageManifest.lights[light]["cduKey"] = [];
          }
        }
      });
      if (fmsData.cduObject.screenObjects) {
        pageManifest.screenObjects = fmsData.cduObject.screenObjects;
      }
    }

    //
    const isEdited = validatePM();
    loadModal();
    newPageManifestPopulation(lessonPageActionsContext.fmsObject); //page manifest validation
    setCurrentCduPageName(cduPageToEdit);
    setKeyList(generateCduKeyList(currentCDU));
    getCduPageData();
    if (!_.isEqual(pageManifest, pageContext.pageManifest) || isEdited) {
      pageContext.updatePageManifest(pageManifest);
    }
    if (pageManifest.cdu.includes("330")) {
      setFontSizeBase("12px");
    } else if (pageManifest.cdu.includes("320")) {
      setFontSizeBase("12px");
    }
    if (lessonPageActionsContext.fmsObject.cduObject.pageTemplates) {
      const pageName = pageManifest.bootPage;
      const selectedPageTemplate = lessonPageActionsContext.fmsObject.cduObject.pageTemplates[pageName];
      pageManifest.pagePath = selectedPageTemplate.pagePath;
      //  pageContext.updatePageManifest(pageManifest);
    }
    // getPageData(pageManifest.bootPage)
  }, []);

  useEffect(() => {
    const openDropDown = (rectObjects: any) => {
      if (
        pageManifest.pageLayouts.bootDropDown &&
        pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells &&
        pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[pageManifest.pageLayouts.bootDropDown]
      ) {
        const dropVals =
          pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[pageManifest.pageLayouts.bootDropDown];
        //let parent = _.find(rectObjects, {cduKeyName: pageManifest.pageLayouts.bootDropDown}) _.find was failing for modal values

        const parent = rectObjects.filter((rect: any) => {
          return rect.cduKeyName === pageManifest.pageLayouts.bootDropDown;
        });

        setDropDownValues(dropVals);
        setDropDownParent(parent[0]);
        setDropDownOpen(true);
      }
    };

    const dropDownCheck = () => {
      if (!isLoading && currentCDUPageData && !dropDownOpen && !pageManifest.pageLayouts.bootModal) {
        openDropDown(currentCDUPageData.rectangularObjects);
      } else if (!isLoading && currentCDUPageData && !_.isEmpty(currentModal)) {
        openDropDown(currentModal.rectangularObjects);
      }
    };
    dropDownCheck();
  }, [isLoading, cduPageToEdit]);

  useEffect(() => {
    if (selectedKey !== undefined) {
      setEditorComponent(
        <SuccessCriteriaPage
          selectedKey={selectedKey}
          keyEventOpensEditor={keyEventOpensEditor}
          toggleKeyEditor={toggleKeyEditor}
        />,
      );
    }
  }, [selectedKey]);

  useEffect(() => {
    if (keyEventOpensEditor) {
      setKey("");
    }
  }, [editorComponent]);

  useEffect(() => {
    if (pageContext.isAnnotationsShown) {
      lessonPageActionsContext.setLessonPageActions([
        {
          triggerAction: () => {
            setEditorComponent(<Description />);
            setEvent(false);
          },
          actionName: "Page Objective",
          iconPath: addDescriptionIcon,
        },
        {
          triggerAction: () => {
            setEditorComponent(
              <SuccessCriteriaPage
                selectedKey={selectedKey ? selectedKey : ""}
                keyEventOpensEditor={keyEventOpensEditor}
                toggleKeyEditor={toggleKeyEditor}
              />,
            );
          },
          actionName: "To Do Tasks",
          iconPath: successCriteriaIcon,
        },
      ]);
    } else {
      lessonPageActionsContext.setLessonPageActions([]);
    }
  }, [pageContext.isAnnotationsShown]);

  useEffect(() => {
    pageManifest.holdingDisplay?.length > 0 ? setHoldingDisplayEditorShown(true) : setHoldingDisplayEditorShown(false);
  }, []);

  useEffect(() => {
    const getOverlays = () => {
      const pageTemplates = lessonPageActionsContext.fmsObject?.cduObject?.pageTemplates;
      if (pageTemplates) {
        const selectedPageTemplate = pageTemplates[pageManifest.bootPage];
        genericRepositoryService
          .blobFetch(pageManifest.cduPath + selectedPageTemplate.pagePath)
          .then((response: any) => {
            if (response.isSuccess) {
              const foreignObjects = response.data.foreignObjects.reduce(
                (obj: any, item: any) => ((obj[item.id] = ""), obj),
                {},
              );
              const pageButtons = response.data.rectangularObjects;

              setPageButtons(pageButtons);
            }
          }); // gets data for init page when creating a new lesson page
      }
    };

    getOverlays(); //where to call so that page does not blink
  }, []);

  useEffect(() => {
    function addOverlays(item: any) {
      //
      const pageTemplates = lessonPageActionsContext.fmsObject?.cduObject?.pageTemplates;
      if (pageTemplates) {
        const selectedPageTemplate = pageTemplates[pageManifest.bootPage];
        if (item.actions?.length > 0) {
          if (item.actions[0].function === "changeImage") {
            //item.actions[0].function not equal to expected value based on pageTemplate.json

            const overLays = pageManifest.pageLayouts[selectedPageTemplate.id]?.overLays;
            const overlay = {
              xCoordinate: item.xCoordinate,
              yCoordinate: item.yCoordinate,
              width: item.width,
              height: item.height,
              image: item.actions[0].parameter.changeImage.assets[0],
              parentCell: item.cduKeyName,
            };
            let overlayExists = false;
            if (overLays === undefined) {
              pageManifest.pageLayouts[selectedPageTemplate.id] = {
                ...pageManifest.pageLayouts[selectedPageTemplate.id],
                overLays: [],
              };
            }

            function findExisting(item: any) {
              if (item.parentCell === overlay.parentCell) {
                overlayExists = true;
              }
            }

            overLays?.forEach(findExisting);

            if (overlayExists === false) {
              pageManifest.pageLayouts[selectedPageTemplate.id].overLays.push(overlay);
            }
          }
        }
      }
      pageContext.updatePageManifest(pageManifest);
    }
    pageButtons.forEach(addOverlays);
  }, [pageButtons]);

  const validatePM = () => {
    let edited = false;
    if (pageManifest.cdu !== lessonPageActionsContext.fmsObject.cdu) {
      pageManifest.cdu = lessonPageActionsContext.fmsObject.cdu;
      if (!edited) edited = true;
    }

    if (pageManifest.cduPath !== lessonPageActionsContext.fmsObject.cduPath) {
      pageManifest.cduPath = lessonPageActionsContext.fmsObject.cduPath;
      if (!edited) edited = true;
    }

    if (pageManifest.assetVersionId !== lessonPageActionsContext.fmsObject.assetVersionId) {
      pageManifest.assetVersionId = lessonPageActionsContext.fmsObject.assetVersionId;
      if (!edited) edited = true;
    }
    return edited;
  };
  const setDropDownObject = (id: string) => {
    const dropObject = {
      cduKeyName: dropDownParent.cduKeyName,
      id: id,
      xCoordinate: dropDownParent.xCoordinate,
      yCoordinate: dropDownParent.yCoordinate,
      height: 22,
      width: dropDownParent.width,
    };
    return dropObject;
  };
  const setColumnObject = (id: string) => {
    //y={props.columnData.yCoordinate + (props.columnData.rowHeight * index)}

    const columnSplit = id.split("_data");
    let columnObject = {};
    if (currentModal.columns[columnSplit[0]]) {
      columnObject = {
        cduKeyName: id,
        id: id,
        xCoordinate: currentModal.columns[columnSplit[0]].xCoordinate,
        yCoordinate:
          currentModal.columns[columnSplit[0]].yCoordinate +
          currentModal.columns[columnSplit[0]].rowHeight * parseInt(id.slice(-1)),
        height: currentModal.columns[columnSplit[0]].rowHeight,
        width: currentModal.columns[columnSplit[0]].width,
      };
    }

    return columnObject;
  };
  const assignCduLineInformation = (id: string): void => {
    /****************************************************************************************************************
     **  When the user clicks a CDU cell the ID and coordinate object from the CDU collection needs to be selected **
     **  and added into the RTE. CDU cells can be located in the foreignObjects array within the main CDU json.    **
     **  The A220 is more complex and the values will exist in the individual page templates, and there can also   **
     **  be modal values which can act as normal cell values, or as column values which need to be calculated      **
     **  differently.                                                                                              **
     ****************************************************************************************************************/
    // if(id.includes("_Data_") || id.includes("_Label_") || id.includes("Title")){ //needs to check and see that the user has actually clicked on a proper element

    let lineObjectData: any = {};
    // if(showCduModal && !id.includes("Drop_option") && !pageManifest.cdu.includes("350")){
    if (showCduModal && !id.includes("Drop_option")) {
      if (currentModal.foreignObjects.find((lineItem: any) => lineItem.id === id)) {
        lineObjectData = currentModal.foreignObjects.find((lineItem: any) => lineItem.id === id) || setColumnObject(id);
        if (
          lineObjectData !== undefined &&
          !pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName].cellValues[id] &&
          currentCDU.foreignObjects.find((lineItem) => lineItem.id === id)
        ) {
          pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName].cellValues[id] = "";

          pageContext.updatePageManifest(pageManifest);
        }
      } else if (currentModal.columns && id.includes("column") && pageManifest.cdu.includes("220")) {
        lineObjectData = setColumnObject(id);
      } else if (currentCDU.foreignObjects.find((lineItem) => lineItem.id === id)) {
        lineObjectData = currentCDU.foreignObjects.find((lineItem) => lineItem.id === id);
      }
    } else if (dropDownOpen && id.includes("Drop_option")) {
      lineObjectData = setDropDownObject(id);
    } else {
      lineObjectData = currentCDU.foreignObjects.find((lineItem) => lineItem.id === id); //checks if the user has clicked on a valid section of the CDU and that there is an existing obj
      // }else{
    }

    if (lineObjectData) {
      let manifestData = ""; //checks to see that the page data exists, will crash when user clicks on blank space without
      if (dropDownOpen && id.includes("Drop_option")) {
        manifestData = pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][dropDownParent.cduKeyName][id];

        if (selectedCduCell !== lineObjectData.id) {
          toggleReRender(true);
          setSelectedCduCell(lineObjectData.id);
        }
        setEditorComponent(
          <CduSingleLineEditor
            cduPage={currentCduPageName}
            cduData={lineObjectData}
            cduHtmlData={manifestData}
            lineLength={manifestData?.replace(/<[^>]*>?/gm, "").length}
            updateManifestData={updateDropCell}
            cellId={lineObjectData.id}
          />,
        );
      } else if (!showCduModal) {
        manifestData = pageManifest.pages[currentCduPageName][id];
        if (selectedCduCell !== lineObjectData.id) {
          toggleReRender(true);
          setSelectedCduCell(lineObjectData.id);
        }
        setEditorComponent(
          <CduSingleLineEditor
            cduPage={currentCduPageName}
            cduData={lineObjectData}
            cduHtmlData={manifestData}
            lineLength={manifestData?.replace(/<[^>]*>?/gm, "").length}
            updateManifestData={updateCduCell}
            cellId={lineObjectData.id}
          />,
        );
      } else if (showCduModal && !id.includes("Drop_option")) {
        if (id.includes("column_data")) {
          const columnSplit = id.split("_data");
          manifestData =
            pageManifest.pageLayouts[currentCduPageName].modals[currentModal.modalName][columnSplit[0]][id];
        } else {
          manifestData = pageManifest.pageLayouts[currentCduPageName].modals[currentModal.modalName].cellValues[id];
        }

        if (selectedCduCell !== lineObjectData.id) {
          toggleReRender(true);
          setSelectedCduCell(lineObjectData.id);
        }

        setEditorComponent(
          <CduSingleLineEditor
            cduPage={currentCduPageName}
            cduData={lineObjectData}
            cduHtmlData={manifestData}
            lineLength={manifestData?.replace(/<[^>]*>?/gm, "").length}
            updateManifestData={updateModalCell}
            cellId={lineObjectData.id}
          />,
        );
      }
    }
    // }
  };
  const updateCduCell = (newText: string, id: string) => {
    pageManifest.pages[currentCduPageName][id] = newText;
    // pageManifest.pages[fmsContext.cduPageToEdit][props.cduData.id] = newText;

    pageContext.updatePageManifest(pageManifest);
  };
  const updateDropCell = (newText: string, id: string) => {
    pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][dropDownParent.cduKeyName][id] = newText;

    pageContext.updatePageManifest(pageManifest);
  };
  const updateModalCell = (newText: string, id: string) => {
    if (id.includes("column")) {
      const nameSplit = id.split("_data");

      pageManifest.pageLayouts[currentCduPageName].modals[currentModal.modalName][nameSplit[0]][id] = newText;
    } else {
      pageManifest.pageLayouts[currentCduPageName].modals[currentModal.modalName].cellValues[id] = newText;
    }

    pageContext.updatePageManifest(pageManifest);
  };

  const addModalRow = (columnName: string) => {
    const columnList = Object.keys(
      pageManifest.pageLayouts[currentCduPageName].modals[currentModal.modalName][columnName],
    );

    pageManifest.pageLayouts[currentCduPageName].modals[currentModal.modalName][columnName][
      `${columnName}_data_r_${columnList.length}`
    ] = "";

    pageContext.updatePageManifest(pageManifest);
  };
  const addDropDownRow = (dropName: string) => {
    const dropList = Object.keys(pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][dropName]);
    pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][dropName][
      `${dropName}_option_${dropList.length}`
    ] = "";

    pageContext.updatePageManifest(pageManifest);
  };

  const toggleKeyEditor = (booleanValue: boolean) => {
    setEvent(booleanValue);
  };

  const assignFmsKeyPad = (id: string): void => {
    //
    const multiActionKeys = ["cursorControlPad", "enterKey", "cursorSelectRight", "cursorSelectLeft", "execKey"];

    const keyInfo = currentCDU?.rectangularObjects?.find((buttonItem) => buttonItem.id === id) ||
      currentCDU?.circleObjects?.find((element: any) => element.cduKeyName === id) ||
      currentCDU?.overLays?.find((element: any) => element.id === id && id === "knob") ||
      currentCDU?.foreignObjects?.find((element: any) => element.cduKeyName === id) ||
      currentCDUPageData?.rectangularObjects?.find((element: any) => element.cduKeyName === id) ||
      currentModal?.rectangularObjects?.find((element: any) => element.cduKeyName === id) ||
      currentModal?.foreignObjects?.find((element: any) => element.cduKeyName === id) || { id: id, cduKeyName: id };
    const keyList = currentCDU.foreignObjects.map((foreignObject) => {
      return foreignObject.id;
    });
    if (currentCDU.lightObjects) {
      currentCDU.lightObjects.map((light) => {
        keyList.push(light.id);
      });
    }

    Object.keys(pageManifest.pages).map((page) => {
      keyList.push(page);
    });
    let keyName = keyInfo.id || keyInfo.cduKeyName; //need the || because circular buttons don't currently have an id just the cduname
    if (keyName === "decimal") {
      keyName = ".";
    }
    if (keyName === "plus/minus") {
      keyName = "+";
    }
    // if(keyInfo){
    if (
      (keyInfo && !pageManifest.cdu.includes("787")) ||
      (keyInfo && pageManifest.cdu.includes("787") && !keyInfo.cduKeyName.includes("_"))
    ) {
      if (
        (keyInfo && !pageManifest.cdu.includes("787")) ||
        (keyInfo && pageManifest.cdu.includes("787") && !keyInfo.cduKeyName.includes("Title"))
      ) {
        if (
          (keyInfo && !pageManifest.cdu.includes("787")) ||
          (keyInfo && pageManifest.cdu.includes("787") && !keyInfo.cduKeyName.includes("Scratchpad"))
        ) {
          if (keyEventOpensEditor) {
            setKey(keyName);
          } else {
            if (multiActionKeys.includes(keyName)) {
              if (!_.has(pageManifest.actions, keyName)) {
                // prevents designer crash on click of ovfy button

                pageManifest.actions[keyName] = _.cloneDeep(JSON.parse(JSON.stringify([...blankFunctionKeys])));

                pageContext.updatePageManifest(pageManifest);
              } else if (pageManifest.actions[keyName] && pageManifest.actions[keyName].length === 0) {
                pageManifest.actions[keyName] = _.cloneDeep(JSON.parse(JSON.stringify([...blankFunctionKeys])));

                pageContext.updatePageManifest(pageManifest);
              }
              if (!document.getElementById("AddToDoTask")) {
                setEditorComponent(
                  <KeyDataManager keyName={keyName} destinationList={keyList} fromTask={false} taskIndex={-1} />,
                );
              }
            } else {
              if (!document.getElementById("AddToDoTask")) {
                setEditorComponent(
                  <KeyDataEditor
                    keyName={keyName}
                    index={0}
                    destinationList={keyList}
                    fromTask={false}
                    taskIndex={-1}
                  />,
                );
              }
            }
          }
          if (
            pageManifest.cdu.includes("145") &&
            pageManifest.actions.LSKR1[0].conditions[0].placeToCheck === "Scratchpad"
          ) {
            const noScratchpadBlankFunctionKeys = lodash.cloneDeep(blankFunctionKeys);
            noScratchpadBlankFunctionKeys[0].parameter.changeCDUCell.destination[0] = "Title";
            noScratchpadBlankFunctionKeys[0].conditions[0].placeToCheck = "Title";
            pageManifest.actions["LSKR1"] = _.cloneDeep(JSON.parse(JSON.stringify([...noScratchpadBlankFunctionKeys])));

            pageContext.updatePageManifest(pageManifest);
          }
        }
      }
    }
  };
  const checkHighlightingKeyWords = (): string[] => {
    //need cduKey name also
    const cduKeyWordHighlights: string[] = [];
    if (pageManifest.successCriteria.length > 0 && pageManifest.successCriteria[criteriaIndex]) {
      pageManifest.successCriteria[criteriaIndex].highlights.forEach((highlight: string) => {
        cduKeyWordHighlights.push(highlight);
      });
    }
    return cduKeyWordHighlights;
  };
  const generateCduKeyList = (cduData: ICdu) => {
    const keyList: string[] = [];

    if (cduData) {
      cduData?.rectangularObjects?.forEach((rectObj) => {
        keyList.push(rectObj.id);
      });
      cduData?.circleObjects?.forEach((circObj: any) => {
        keyList.push(circObj.cduKeyName);
      });
    }
    keyList.push("knob");
    return keyList;
  };
  const selectCduLight = (lightId: string) => {
    if (!pageManifest.lights[lightId]) {
      pageManifest.lights[lightId] = { id: lightId, criteriaIndex: null, enabledInitially: false };
      pageContext.updatePageManifest(pageManifest);
      return;
    }
    const variableList = Object.keys(pageManifest.lights[lightId]);
    if (variableList.includes("enabledInitially")) {
      pageManifest.lights[lightId].enabledInitially = !pageManifest.lights[lightId].enabledInitially;
    } else {
      pageManifest.lights[lightId]["enabledInitially"] = true;
    }

    pageContext.updatePageManifest(pageManifest);
  };

  const createModalObject = (modalData: any) => {
    const modalObject: any = {};
    if (modalData.foreignObjects) {
      modalObject["cellValues"] = {};
      modalData.foreignObjects.forEach((cell: any, index: number) => {
        modalObject["cellValues"][cell.cduKeyName] = "";
      });
    }
    if (modalData.columns) {
      Object.keys(modalData.columns).forEach((column: any) => {
        modalObject[column] = {
          [`${column}_data_r_0`]: "",
        };
      });
    }
    if (modalData.rectangularObjects) {
      const overlaysArray: any = [];
      modalData.rectangularObjects.forEach((object: any, index: number) => {
        if (object.actions[0]?.function === "changeImage") {
          const overlayObject = {
            id: object.id,
            image: object.actions[0].parameter.changeImage.assets[0],
            height: object.height,
            width: object.width,
            xCoordinate: object.xCoordinate,
            yCoordiante: object.yCoordinate,
          };
          overlaysArray.push(overlayObject);
        }
      });
      modalObject.overLays = overlaysArray;
    }
    return modalObject;
  };

  const addModalDataToManifest = (modalData: any) => {
    let diffFlag = false;
    if (pageManifest.pageLayouts[pageManifest.bootPage].modals) {
      if (!pageManifest.pageLayouts[pageManifest.bootPage].modals[modalData.modalName]) {
        pageManifest.pageLayouts[pageManifest.bootPage].modals[modalData.modalName] = createModalObject(modalData);
        diffFlag = true;
      }
    } else if (pageManifest.pageLayouts[pageManifest.bootPage].modals) {
      if (pageManifest.pageLayouts[pageManifest.bootPage][modalData.modalName]?.overLays === undefined) {
        pageManifest.pageLayouts[pageManifest.bootPage].modals[modalData.modalName] = createModalObject(modalData);
        diffFlag = true;
      }
    } else {
      pageManifest.pageLayouts[pageManifest.bootPage]["modals"] = {
        [modalData.modalName]: createModalObject(modalData),
      };
      diffFlag = true;
    }
    if (diffFlag === true) {
      pageContext.updatePageManifest(pageManifest);
    }
  };
  const fetchCduModal = async (modalName: string) => {
    /********************************************************************************************************
     **   Modals are tracked in a dictionary in the main CDU json. The path for the modal is found by      **
     ** indexing the modalIndex using the keyName of the modal and referencing the .path variable. Once    **
     ** the function has a valid path the json file for the modal is fetched and its relevant data is      **
     ** added to the pageManifest under the pageLayouts object as its own object value based on the modal's**
     ** keyName in the current page's object EX: pageManifest.pageLayouts.INIT.ARRIVALS                    **
     ********************************************************************************************************/
    if (currentCDU.modalIndex[modalName] && currentCDU.modalIndex[modalName].path) {
      const modalResponse = await genericRepositoryService.blobFetch(
        pageManifest.cduPath + currentCDU.modalIndex[modalName].path,
      );

      if (modalResponse.isSuccess) {
        addModalDataToManifest(modalResponse.data);
        setCurrentModal(modalResponse.data);

        setShowCduModal(true);
        setDropDownOpen(false);
        setDropDownValues({});
        setDropDownParent({});
      }
    }
  };
  const fetchModalForManifestPopulation = async (modalName: string) => {
    //just updates manifest values for agit p corner case with the A220 paging where there could possible be data neccisary for a modal,
    //but the page data does not have the proper values
    if (currentCDU.modalIndex[modalName] && currentCDU.modalIndex[modalName].path) {
      const modalResponse = await genericRepositoryService.blobFetch(
        pageManifest.cduPath + currentCDU.modalIndex[modalName].path,
      );
      if (modalResponse.isSuccess) {
        addModalDataToManifest(modalResponse.data);
      }
    }
  };

  //changing a220 page needs to fetch the pagetemplate by piecing the page path and the cdu path if there are no foreign objects for that page they need to be populated
  //modal data is page specific so the modal needs to be closed before the page changes
  const getPageData = async (pageId: string) => {
    //
    if (lessonPageActionsContext.fmsObject.cduObject.pageTemplates) {
      const pageName = pageId;
      const selectedPageTemplate = lessonPageActionsContext.fmsObject.cduObject.pageTemplates[pageName];

      const response: any = await genericRepositoryService.blobFetch(
        pageManifest.cduPath + selectedPageTemplate.pagePath,
      );

      setDropDownOpen(false);
      setDropDownValues({});
      setDropDownParent({});

      if (response.isSuccess) {
        const foreignObjectsObj = response.data.foreignObjects.reduce(
          (obj: any, item: any) => ((obj[item.id] = ""), obj),
          {},
        );
        const pageButtons = response.data.rectangularObjects;
        const overlaysArray: any[] = [];

        // let pageButtonsObj = pageButtons.array.forEach((element:any) => {
        //    return({element.cduKeyName ...element})
        // });

        // let pageButtonsObj = pageButtons.forEach(function (arrayItem){

        // let foreignObjectsObj = {}
        lessonPageActionsContext.fmsObject.cduObject.foreignObjects = response.data.foreignObjects;
        pageManifest.bootPage = selectedPageTemplate.id;
        pageManifest.pagePath = selectedPageTemplate.pagePath;

        if (!pageManifest.pageLayouts[pageId]) {
          //

          pageManifest.pages[selectedPageTemplate.id] = foreignObjectsObj;

          // pageManifest.pages[selectedPageTemplate.id] = {
          //   ...foreignObjectsObj,
          //   ...pageButtons};
          const actModButton = _.find(response.data.rectangularObjects, {
            cduKeyName: "actMod",
          });
          pageManifest.pageLayouts[selectedPageTemplate.id] = {
            overLays: [
              {
                xCoordinate: actModButton.xCoordinate,
                yCoordinate: actModButton.yCoordinate,
                width: actModButton.width,
                height: actModButton.height,
                image: "assets/SVG/atc.svg",
                parentCell: "actMod",
              },
            ],
          };

          function addOverlays(item: any) {
            if (item.actions?.length > 0) {
              if (item.actions[0].function === "changeImage") {
                //item.actions[0].function not equal to expected value based on pageTemplate.json

                pageManifest.pageLayouts[selectedPageTemplate.id].overLays.push(
                  // overlaysArray.push(
                  {
                    xCoordinate: item.xCoordinate,
                    yCoordinate: item.yCoordinate,
                    width: item.width,
                    height: item.height,
                    image: item.actions[0].parameter.changeImage.assets[0],
                    parentCell: item.cduKeyName,
                  },
                );
              }
            }
          }

          pageButtons.forEach(addOverlays);
          // pageManifest.pageLayouts[selectedPageTemplate.id] = {overLays : overlaysArray}
        }
        if (pageManifest.pageLayouts.cursorKey !== "" && pageManifest.cdu.includes("a350")) {
          //pageManifest.pageLayouts.cursorKey = "fms1";
          findCursorObject("fms-select");
        } else if (pageManifest.pageLayouts.cursorKey !== "") {
          findCursorObject("fms1");
        }

        if (showCduModal && currentModal) {
          addModalDataToManifest(currentModal);
        } else if (pageManifest.pageLayouts.bootModal) {
          fetchModalForManifestPopulation(pageManifest.pageLayouts.bootModal);
        } else {
          pageContext.updatePageManifest(pageManifest);
        }

        setCurrentCDUPageData(response.data);
        setCduPageToEdit(selectedPageTemplate.id); //need to check if the page exists if it doesn
      }
    }
  };

  const findModalCusror = (highlightedElement: string) => {
    let keyMatch: any = {};
    let change = false;
    if (Object.keys(currentModal).length > 0 && currentModal.foreignObjects && showCduModal) {
      //iterate through CDU columns, cells,buttons to find slectedKey
      if (
        pageManifest.pageLayouts[pageManifest.bootPage].modals &&
        pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName]
      ) {
        Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName]).map((col) => {
          //iterate through the columns until we find the row that is selected
          Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName][col]).map(
            (row: any, index: number) => {
              if (highlightedElement.includes("column") && currentModal.columns) {
                const columnData = currentModal.columns[col];

                const rowData = {
                  cduKeyName: highlightedElement,
                  id: highlightedElement,
                  xCoordinate: columnData.xCoordinate,
                  yCoordinate: columnData.yCoordinate + columnData.rowHeight * index,
                  width: columnData.width,
                  height: columnData.rowHeight,
                };
                keyMatch = rowData;
                change = true;
              } else {
                const cellData = _.find(currentModal.foreignObjects, {
                  cduKeyName: highlightedElement,
                });
                if (cellData && !change) {
                  const rowData = {
                    cduKeyName: cellData.cduKeyName,
                    id: cellData.cduKeyName,
                    xCoordinate: cellData.xCoordinate,
                    yCoordinate: cellData.yCoordinate,
                    width: cellData.width,
                    height: cellData.height,
                  };
                  keyMatch = rowData;
                  change = true;
                }
              }
            },
          );
        });
      }
    }
    return keyMatch;
  };
  const findCursorObject = (highlightedElement: string) => {
    let keyMatch: any = _.cloneDeep(cduCursorObject);
    let change = false;
    //let defaultKey = {cduKeyName: "fms1", id: "fms1", xCoordinate:468, yCoordinate: 4, width: 42, height: 44}
    //needs to find where the cursor is based on if its inside a modal or not also looks for buttons. look in cdu page, page buttons, or modal buttons/cells/columns
    //columns will be tricky to find since they are dynamic
    //look to see if highlighted cell is within modal object
    if (Object.keys(currentModal).length > 0 && currentModal.foreignObjects && showCduModal) {
      //iterate through CDU columns, cells,buttons to find slectedKey
      if (
        pageManifest.pageLayouts[pageManifest.bootPage].modals &&
        pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName]
      ) {
        if (currentModal.modalName === "NEWWAYPOINT") {
        }
        Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName]).map((col) => {
          //iterate through the columns until we find the row that is selected
          Object.keys(pageManifest.pageLayouts[pageManifest.bootPage].modals[currentModal.modalName][col]).map(
            (row: any, index: number) => {
              if (highlightedElement === row) {
                if (highlightedElement.includes("column") && currentModal.columns) {
                  const columnData = currentModal.columns[col];
                  const rowData = {
                    cduKeyName: highlightedElement,
                    id: highlightedElement,
                    xCoordinate: columnData.xCoordinate,
                    yCoordinate: columnData.yCoordinate + columnData.rowHeight * index,
                    width: columnData.width,
                    height: columnData.rowHeight,
                  };
                  keyMatch =
                    columnData.highlight !== undefined ? { ...rowData, highlight: columnData.highlight } : rowData;
                  change = true;
                } else {
                  const cellData = _.find(currentModal.foreignObjects, {
                    cduKeyName: highlightedElement,
                  });
                  if (cellData && !change) {
                    const rowData = {
                      cduKeyName: cellData.cduKeyName,
                      id: cellData.cduKeyName,
                      xCoordinate: cellData.xCoordinate,
                      yCoordinate: cellData.yCoordinate,
                      width: cellData.width,
                      height: cellData.height,
                      highlight: cellData.highlight,
                    };
                    keyMatch =
                      cellData.highlight !== undefined ? { ...rowData, highlight: cellData.highlight } : rowData;
                    change = true;
                  }
                }
              }
            },
          );
        });
      }
      if (!change && currentModal.foreignObjects) {
        currentModal.foreignObjects.map((cell: any) => {
          if (cell.cduKeyName === highlightedElement) {
            keyMatch = cell;
            change = true;
          }
        });
      }
      if (!change && currentModal.rectangularObjects) {
        currentModal.rectangularObjects.map((rectObj: any) => {
          if (rectObj.cduKeyName === highlightedElement) {
            keyMatch = rectObj;
            change = true;
          }
        });
      }
      if (!change && highlightedElement.includes("Drop_option")) {
        //fms.pageLayouts[fms.bootPage].dropDownCells[keyData.actions[0].parameter.openDropDown.dropDown];
        const menuName: any = highlightedElement.split("_");

        //needs to validate that the cell exists but needs to accept empty strings
        if (
          pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[menuName[0]][highlightedElement] !==
            undefined &&
          pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[menuName[0]][highlightedElement] !== null
        ) {
          keyMatch = {
            cduKeyName: highlightedElement,
            id: highlightedElement,
            xCoordinate: dropDownParent.xCoordinate,
            yCoordinate: dropDownParent.yCoordinate + dropDownParent.height + menuName[menuName.length - 1] * 22,
            width: dropDownParent.width,
            height: 22,
          };

          change = true;
        }
      }
      if (!change) {
        keyMatch =
          currentCDU.paging && highlightedElement !== ""
            ? _.find(currentCDUPageData.rectangularObjects, {
                cduKeyName: highlightedElement,
              }) ||
              _.find(currentCDUPageData.foreignObjects, {
                cduKeyName: highlightedElement,
              }) ||
              _.find(currentCDU.rectangularObjects, {
                cduKeyName: highlightedElement,
              })
            : {
                cduKeyName: "fms1",
                id: "fms1",
                xCoordinate: 468,
                yCoordinate: 4,
                width: 42,
                height: 44,
              };
      }
    } else {
      if (!change && highlightedElement.includes("Drop_option")) {
        //fms.pageLayouts[fms.bootPage].dropDownCells[keyData.actions[0].parameter.openDropDown.dropDown];
        const menuName: any = highlightedElement.split("_");
        if (pageManifest.pageLayouts[pageManifest.bootPage].dropDownCells[menuName[0]][highlightedElement]) {
          keyMatch = {
            cduKeyName: highlightedElement,
            id: highlightedElement,
            xCoordinate: dropDownParent.xCoordinate,
            yCoordinate: dropDownParent.yCoordinate + dropDownParent.height + menuName[menuName.length - 1] * 22,
            width: dropDownParent.width,
            height: 22,
          };

          change = true;
        }
      }
      if (!change) {
        keyMatch =
          currentCDU.paging && highlightedElement !== ""
            ? _.find(currentCDUPageData.rectangularObjects, {
                cduKeyName: highlightedElement,
              }) ||
              _.find(currentCDUPageData.foreignObjects, {
                cduKeyName: highlightedElement,
              }) ||
              _.find(currentCDU.rectangularObjects, {
                cduKeyName: highlightedElement,
              })
            : {
                cduKeyName: "fms1",
                id: "fms1",
                xCoordinate: 468,
                yCoordinate: 4,
                width: 42,
                height: 44,
              };
      }
    }
    pageManifest.pageLayouts.cursorKey = highlightedElement;
    if (keyMatch) {
      setCduCursorObject(keyMatch);
    }
    pageContext.updatePageManifest(pageManifest);
  };

  const createFmsContextProvider = (): IFmsContext => {
    return {
      cduPageToEdit,
      criteriaIndex,
      currentCDU,
      currentCDUPage: currentCduPageName,
      editingCduScreen,
      gridBorder,
      highlightedGroups,
      highlightFrom,
      keyList,
      selectedKey,
      triggerReRender,
      currentModal,
      addModalDataToManifest,
      fetchCduModal,
      setCurrentModal,
      checkHighlightingKeyWords,
      setCduPageToEdit,
      setCriteriaIndex,
      setEditingCduScreen,
      setGridBorder,
      setHighlightFrom,
      setHighlights,
      toggleReRender,
      showCduModal,
      setShowCduModal,
      getPageData,
      cursorSelected,
      setCursorSelected,
      currentCDUPageData,
      setCurrentCDUPageData,
      findCursorObject,
      dropDownOpen,
      setDropDownOpen,
      dropDownValues,
      setDropDownValues,
      dropDownParent,
      setDropDownParent,
      holdingDisplayEditorShown,
      setHoldingDisplayEditorShown,
      imageToDisplayIndex,
      setImageToDisplayIndex,
    };
    //<PagesManagementContext.Provider value={createPagesContextProvider()}>
  };

  if (isLoading) {
    return <></>;
  } else if (pageContext.isAnnotationsShown) {
    return (
      <>
        {/* <RetryApiModal reProcessFunction={fetchCdu} showModal={pageFailedToLoad} />  DO NOT UNCOMMENT THIS*/}
        <FmsContext.Provider value={createFmsContextProvider()}>
          {/* <div className={cduInformation.image.width > cduInformation.image.height ? "rowNoPadding fms-row one-hundred-percent-height" : "row fms-row one-hundred-percent-height"}> */}
          <div className="one-hundred-percent-height">
            <img src={backgroundLogo} alt="CPAT Logo" id="background-logo" />
            {/* <div className={currentCDU.image.width > currentCDU.image.height ? "col-6 one-hundred-percent-height no-padding-right scrolling-fms" : "col-6 one-hundred-percent-height no-padding-right"}> */}
            {/* <div className={currentCDU.image.width > currentCDU.image.height ? "col-6 one-hundred-percent-height no-padding-right scrolling-fms" : "col-6 one-hundred-percent-height no-padding-right"}> */}
            <div className="fms-draggable-aspect-ratio">
              <div className="fms-draggable-aspect-ratio-mins">
                <div className="fmspage-designer-left-column">
                  <svg
                    className={
                      currentCDU.image.width > currentCDU.image.height
                        ? "image-fluid fms-svgWide"
                        : "image-fluid fms-svg"
                    }
                    viewBox={"0 0 " + currentCDU.image.width + " " + currentCDU.image.height}
                  >
                    <defs />
                    <g>
                      {currentCDU.image.href.length > 0 ? (
                        <image
                          height="100%"
                          width="100%"
                          href={blobUrlFormatHelper(`${pageManifest.cduPath}/${currentCDU.image.href}`)}
                        />
                      ) : (
                        <></>
                      )}
                      <CDUScreenObjects />
                      {currentCDU.pageTemplates ? (
                        <CduPageOverlays
                          cduPath={pageManifest.cduPath}
                          selectedPageData={currentCDUPageData}
                          // selectedPageData = {getCduPageData}
                          cduScreenArea={currentCDU.cduScreen}
                        />
                      ) : (
                        <></>
                      )}
                      {/**MODAL NEEDS TO HANDLE OVERLAY IMAGES AT A DIFFERENT LEVEL THAN NOTMAL CDU MODELS */}
                      {showCduModal && currentModal ? (
                        <>
                          {currentCDUPageData && currentCDUPageData.rectangularObjects ? (
                            <CDUPageButtons
                              rectangularObjects={currentCDUPageData.rectangularObjects}
                              fromModal={false}
                              assignFmsKeypad={assignFmsKeyPad}
                              highlightGroups={highlightedGroups}
                            />
                          ) : (
                            <></>
                          )}
                          <CduDisplay
                            cduPage={pageManifest.pages[pageManifest.bootPage]}
                            cduLinesInformation={currentCDU.foreignObjects}
                            highlightGroups={highlightedGroups}
                            accessCduLineInformation={assignCduLineInformation}
                            fontSizeBase={fontSizeBase}
                            assignFmsKeypad={assignFmsKeyPad} // new was only on cdu rect and circle buttons
                          />

                          {/* <CDUmodal
                      paging={currentCDU.paging}
                      cduPath={pageManifest.cduPath}
                      modalData={currentModal}
                      modalShown={showCduModal}
                      manifestModalData={pageManifest.pageLayouts[pageManifest.bootPage] && pageManifest.pageLayouts[pageManifest.bootPage].modals ? pageManifest.pageLayouts[pageManifest.bootPage].modals : {}}
                      accessCduLineInformation={assignCduLineInformation}
                      addModalRow={addModalRow}
                      assignFmsKeypad={assignFmsKeyPad}
                      highlightGroups={highlightedGroups}
                    />  */}

                          {/* {pageManifest.cdu.includes('e145')?
                    <HoldingDisplay
                    
                    />
                    :null} */}

                          {currentCDU.overLays || pageManifest.pageLayouts[pageManifest.bootPage].overLays ? (
                            <CduImageOverlays
                              overLays={currentCDU.overLays}
                              cduPath={pageManifest.cduPath}
                              assignFmsKeypad={assignFmsKeyPad}
                              pageOverlays={
                                pageManifest.pageLayouts
                                  ? pageManifest.pageLayouts[pageManifest.bootPage].overLays
                                  : null
                              }
                              pageImage={currentCDU.cduScreen ? currentCDU.cduScreen : null}
                              pageTemplates={currentCDU.pageTemplates}
                              accessCduLineInformation={assignCduLineInformation}
                              paging={pageManifest.paging}
                            />
                          ) : (
                            <></>
                          )}

                          <CDUmodal
                            paging={currentCDU.paging}
                            cduPath={pageManifest.cduPath}
                            modalData={currentModal}
                            modalShown={showCduModal}
                            manifestModalData={
                              pageManifest.pageLayouts[pageManifest.bootPage] &&
                              pageManifest.pageLayouts[pageManifest.bootPage].modals
                                ? pageManifest.pageLayouts[pageManifest.bootPage].modals
                                : {}
                            }
                            accessCduLineInformation={assignCduLineInformation}
                            addModalRow={addModalRow}
                            assignFmsKeypad={assignFmsKeyPad}
                            highlightGroups={highlightedGroups}
                          />
                        </>
                      ) : (
                        <>
                          {currentCDU.overLays || pageManifest.pageLayouts[pageManifest.bootPage].overLays ? (
                            <CduImageOverlays
                              overLays={currentCDU.overLays}
                              cduPath={pageManifest.cduPath}
                              assignFmsKeypad={assignFmsKeyPad}
                              pageOverlays={
                                pageManifest.pageLayouts
                                  ? pageManifest.pageLayouts[pageManifest.bootPage].overLays
                                  : null
                              }
                              pageImage={currentCDU.cduScreen ? currentCDU.cduScreen : null}
                              pageTemplates={currentCDU.pageTemplates}
                              accessCduLineInformation={assignCduLineInformation}
                              paging={pageManifest.paging}
                            />
                          ) : (
                            <></>
                          )}
                          {currentCDUPageData && currentCDUPageData.rectangularObjects ? (
                            <CDUPageButtons
                              rectangularObjects={currentCDUPageData.rectangularObjects}
                              fromModal={false}
                              assignFmsKeypad={assignFmsKeyPad}
                              highlightGroups={highlightedGroups}
                            />
                          ) : (
                            <></>
                          )}

                          <CduDisplay
                            cduPage={pageManifest.pages[pageManifest.bootPage]}
                            cduLinesInformation={currentCDU.foreignObjects}
                            highlightGroups={highlightedGroups}
                            accessCduLineInformation={assignCduLineInformation}
                            fontSizeBase={fontSizeBase}
                            assignFmsKeypad={assignFmsKeyPad} //review new was only on cdu rect and circle buttons
                          />
                        </>
                      )}

                      {dropDownOpen && dropDownParent ? (
                        <CDUDropDown
                          parentMenu={dropDownParent}
                          options={
                            pageManifest.pageLayouts[pageManifest.bootPage]["dropDownCells"][dropDownParent.cduKeyName]
                          }
                          accessCduLineInformation={assignCduLineInformation}
                          addDropDownRow={addDropDownRow}
                          assignFmsKeypad={assignFmsKeyPad}
                          highlightedGroups={highlightedGroups}
                        />
                      ) : (
                        <></>
                      )}

                      <CDUCellCursor
                        cduPath={pageManifest.cduPath}
                        cursor={_.find(currentCDU.cduAssets, {
                          id: pageManifest.pageLayouts.cursorType,
                        })}
                        modalData={currentModal}
                        currentCduPage={currentCDUPageData}
                        cursorCellObject={cduCursorObject}
                        parentMenu={dropDownOpen && dropDownParent ? dropDownParent : null}
                      />

                      <CDUCellHighlights
                        cduModel={pageManifest.cdu}
                        highlightedCell={pageManifest.pageLayouts.cursorKey}
                        cduAssets={currentCDU.cduAssets ? currentCDU.cduAssets : []}
                        cduPath={pageManifest.cduPath}
                        cellLines={currentCDU.foreignObjects}
                        rectObjects={currentCDU.rectangularObjects}
                      />
                      <CduRectangularButtons
                        rectangularButtons={currentCDU.rectangularObjects}
                        assignFmsKeypad={assignFmsKeyPad}
                        highlights={highlightedGroups}
                        cduAssets={currentCDU.cduAssets}
                      />
                      <CduCircleButtons
                        cduCircularButtons={currentCDU.circleObjects}
                        assignFmsKeypad={assignFmsKeyPad}
                        highlights={highlightedGroups}
                      />

                      {currentCDU.lightObjects ? (
                        <CduLights
                          cduLights={currentCDU.lightObjects}
                          cduName={currentCDU.image.href}
                          selectCduLight={selectCduLight}
                        />
                      ) : (
                        <></>
                      )}
                      <CduButtonGroupings
                        cduButtonGroupings={currentCDU.groupingObjects}
                        highlightGroups={highlightedGroups}
                      />
                      <FMSPolyGroupings groups={currentCDU.polyGroupings} highlightGroups={highlightedGroups} />

                      <CDUHoldingDisplay />

                      {/*runs every time???*/}
                    </g>
                  </svg>
                </div>
                {/* <div className="col-6 no-padding-left"> */}
                {/* <div className={currentCDU.image.width > currentCDU.image.height ? "col-6 no-padding-left fiftyone" : "col-6 no-padding-left"}> */}
                <div className="fmspage-designer-right-column">
                  <div className="fms-right-hand-editor-background">{editorComponent}</div>
                </div>
              </div>
            </div>
          </div>
        </FmsContext.Provider>
      </>
    );
  } else {
    return (
      <WYSIWYG
        cduPath={`${pageManifest.cduPath}/${currentCDU?.image?.href}`}
        enablingObjective={pageManifest.instructions.lessonInstructions.replace(/<[^>]*>/g, "")}
        enablingObjectiveTitle={pageManifest.instructions.lessonTitle.replace(/<[^>]*>/g, "")}
        image={currentCDU?.image}
        mode={lessonPageActionsContext.selectedEditorLessonModes}
        pageManifest={pageManifest}
        pageType={pageContext.pageType}
      />
    );
  }
};

export default FmsDesigner;

interface PageImage {
  ParentVersion: number;
  assetVersionId: number;
  height: number;
  imagePath: string;
}

export function fmsPageManifestAssetCollector(pageManifest: any) {
  const assets: IDandPath[] = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    if (key === "assetVersionId") {
      assets.push(new IDandPath(value as number, pageManifest["cduPath"]));
    } else if (key === "pageImage") {
      if (value) {
        (value as PageImage[]).forEach((obj) => {
          assets.push(new IDandPath(obj.assetVersionId, obj.imagePath));
        });
      }
    } else if (key === "Audio") {
      assets.push(...crawlAudioArray(value as any));
    } else if (key === "holdingDisplay") {
      (value as PageImage[]).forEach((obj) => {
        assets.push(new IDandPath(obj.assetVersionId, obj.imagePath));
      });
    }
  }
  return assets;
}
