import React from "react";
import PageEditorActionLister from "../../components/PageActionLister/PageEditorActionLister";
import PropertyBoxPanel from "../ObjectPropertiesPanel";
import "./RightPanel.css";

interface RightPanelProps {
  lessonPages: any[];
  currentlyDisplayedPageIndex: number;
  lessonVersionId: number;
}

const selectedPanelButtonStyle = {
  background: "var(--buttons-color)",
  color: "white",
};
const nonSelectedPanelButtonStyle = {
  cursor: "pointer",
};

function RightPanel({ currentlyDisplayedPageIndex, lessonPages, lessonVersionId }: RightPanelProps) {
  const [selectedPanel, setSelectedPanel] = React.useState("properties"); // ["page", "properties"
  return (
    <div className="right-panel">
      <div className="selection-container">
        <div
          style={selectedPanel === "page" ? selectedPanelButtonStyle : nonSelectedPanelButtonStyle}
          onClick={() => {
            setSelectedPanel("page");
          }}
        >
          Page
        </div>
        <div
          style={selectedPanel === "properties" ? selectedPanelButtonStyle : nonSelectedPanelButtonStyle}
          onClick={() => {
            setSelectedPanel("properties");
          }}
        >
          Properties
        </div>
      </div>
      <div className="selected-container" style={{overflow:"hidden"}}>
        {selectedPanel === "page" && (
          <PageEditorActionLister
            lessonPages={lessonPages}
            currentIndex={currentlyDisplayedPageIndex}
            lessonVersionId={lessonVersionId}
          />
        )}
        {selectedPanel === "properties" && <PropertyBoxPanel />}
      </div>
    </div>
  );
}

export default RightPanel;
