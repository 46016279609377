import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import createFormDataInMbChunks from "../components/fileDivider";
import "../components/LessonDataModal/LessonDataModal.css";
import "../components/LessonSettings/LessonSettings.styles.css";
import { ILessonDesignerStore } from "../store/reducers/rootReducer";
import Portal from "../components/Portal/Portal";
import genericRepositoryService from "../services/genericRepositoryService";
import { IAircraft } from "../models/IGetAircraftLessonData";
import { ICreateNewLessonWithMetadataRequest } from "../models/ICreateNewLessonWithMetadata/ICreateNewLessonWithMetadata";
// import immutabilityHelper from 'immutability-helper';
import { IAtaNumberDto } from "../models/IGetATAData";
import IApiResponse from "../models/IApiResponse";
import { IGetNewLessonDependenciesResponse, LmsNameAndKey } from "../models/GetNewLessonDependenciesResponse";
import { lessonStyle } from "../models/IGetLessonStyle";
import { LessonType } from "../models/IGetLessonTypes/IGetLessonTypes";
import createFormattedAircrafts, {
  Manufacturer,
  AircraftFamilyMember,
  AircraftVariant,
} from "../contexts/utils/createFormattedAircrafts";
import { ReactComponent as Check } from "../assets/icons/Settings/check-box.svg";
import { ReactComponent as CheckChecked } from "../assets/icons/Settings/check-box-checked.svg";
import { ReactComponent as RemoveIcon } from "../assets/icons/Common/remove-tag-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/AssetManager/search-bar-icon.svg";
import { ReactComponent as OpenArrow } from "../assets/icons/Settings/drop-down-icon.svg";
import { ReactComponent as PlusIcon } from "../assets/icons/Settings/add-new-tag.svg";
import NoPermissionsLockoutModal from "../components/Modals/NoPermissionsLockoutModal/noPermissionsLockoutModal";
import { massageFileName } from "../components/AssetManager/AssetManagerUtils/UploaderFunctions";
import _ from "lodash";
import useSettingsToggle, { Settings, settingType } from "../hooks/useSettingsToggle";
const powerPointPath = "/newlessonfromfile";
const scoPath = "/newlessonfromclasscofile";
interface IFleetValues {
  [key: string]: string[];
}
interface IManufacturerOptions {
  fleets: IFleetValues;
}
interface IManufacturersList {
  [key: string]: IManufacturerOptions;
}

const renderSettings: Settings = {
  pptimport: settingType.customerenabled,
  storylineimport: settingType.cpatonly,
  toolbook: settingType.cpatonly,
};

const NewLessonModal: React.FC = (props: any) => {
  //#region [rgba(20, 100, 50,0.2)]
  const [saving, setSaving]: [boolean, any] = useState(false);
  const [userDoesNotHavePermissions, setUserDoesNotHavePermissions]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [lessonCopyrightHolders, setLessonCopyrightHolders]: [
    LmsNameAndKey[],
    React.Dispatch<React.SetStateAction<LmsNameAndKey[]>>,
  ] = useState<LmsNameAndKey[]>([]);
  // const [showCopyRightHolders, setShowCopyRightHolders]: [boolean, (React.Dispatch<React.SetStateAction<boolean>>)]  = useState<boolean>(false);
  const [lessonTailoredFor, setLessonTailoredFor]: [
    LmsNameAndKey[],
    React.Dispatch<React.SetStateAction<LmsNameAndKey[]>>,
  ] = useState<LmsNameAndKey[]>([]);
  const [lessonIsWhiteTail, setLessonIsWhiteTail]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [importedDataLessonStyles, setImportedDataLessonStyles]: [any[], any] = useState([]);
  const [importedDataLessonTypes, setImportedDataLessonTypes]: [any[], any] = useState([]);
  const [lmsName, setLmsName]: [any, any] = useState([]);
  const [MinimumPassingScore, setMinimumPassingScore] = useState(70);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [aircraftData, setAircraftData] = useState<any>({
    manufacturer: "None", //manufacturerName
    aircraftFamily: "None", //aircraftFamilyName
    aircraft: "None", //aircraftName
    configuration: null, //configurationName
  });
  const [lessonText, setLessonText] = useState({
    publisher: "",
    author: "",
    lessonName: "",
    lessonDescription: "",
    notes: "",
  });
  const [userSelectedAtaData, setUserSelectedAtaData]: [any[], any] = useState([]);
  const [currentLessonStyle, setCurrentLessonStyle] = useState(2);
  const [currentLessonType, setCurrentLessonType] = useState(2);
  const [currentTags, setCurrentTags]: [any, any] = useState([
    {
      tagKey: "",
      tagValue: "",
    },
  ]);
  const [lessonData, setLessonData] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState("Advanced Settings");

  const [formattedAircrafts, setFormattedAircrafts] = useState<any>({});
  const l = useLocation();
  const navigate = useNavigate();
  const [ataToggle, setAtaToggle] = React.useState(false);
  const [tagsToggle, setTagsToggle] = React.useState(false);
  const [ipToggle, setIpToggle] = React.useState(false);
  const [tailoredForToggle, setTailoredForToggle] = React.useState(false);
  const [ipFilter, setIpFilter] = React.useState("");
  const [ipOptionsVisible, setIpOptionsVisible] = React.useState(false);
  const [tailoredForFilter, setTailoredForFilter] = React.useState("");
  const [isCpatCopyHolder, setIsCpatCopyHolder] = React.useState(true);
  const [emptyTitle, setEmptytitle] = React.useState(true);
  const [authorName, setAuthorName] = React.useState("");
  const [narrationFile, setNarrationFile] = React.useState<File | null>(null);
  const [narrationText, setNarrationText] = React.useState("");
  const [fileError, setFileError] = useState(false);
  const [featuresToRender, setFeaturesToRender, loading] = useSettingsToggle(renderSettings);
  const { pptimport, storylineimport, toolbook } = featuresToRender;

  useEffect(() => {
    pullData();
  }, []);

  useEffect(() => {
    setFeaturesToRender(props.isCpatUser); // returns set of features that are allowed for the logged in user
  }, [loading, props.isCpatUser]);

  function noneCheck(value: string) {
    if (value === "None") {
      return "";
    }
    return value;
  }

  const handleNarrationFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const fileData = e.target.files[0];

    if (!fileData || fileData.name.substring(fileData.name.lastIndexOf(".") + 1) !== "txt") {
      return;
    }

    const reader = new FileReader();
    reader.onloadend = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        setNarrationText(e.target.result.toString());
      }
    };
    reader.readAsText(fileData);
  };

  useEffect(() => {
    if (lessonText.lessonName.trim().length === 0) {
      setEmptytitle(true);
    } else {
      setEmptytitle(false);
    }
  }, [lessonText.lessonName]);

  // useEffect(() => {

  //     let x = document.getElementsByClassName("modal-container");

  //     if (currentTags.showing !== true) {
  //       x[0].scrollTop = 0;
  //     } else {
  //       x[0].scrollTop = x[0].scrollHeight;
  //     }

  // }, [userSelectedAtaData.showing, currentTags.showing]);
  //#endregion
  //#region [rgba(134, 100, 50,0.2)]

  function checkTitleLength(props: any) {
    if (props <= 200) {
      setLessonText({
        ...lessonText,
        lessonName: props,
      });
    }
  }
  async function pullData() {
    function handleAircraftStuff(aircrafts: IAircraft[]) {}

    function handleLessonStyleStuff(lessonStyles: lessonStyle[]) {
      setImportedDataLessonStyles(lessonStyles);
    }

    function handleLessonTypeStuff(lessonTypes: LessonType[]) {
      setImportedDataLessonTypes(lessonTypes);
    }

    const newLessonInformation: IApiResponse<IGetNewLessonDependenciesResponse> =
      await genericRepositoryService.getNewLessonInformation();

    if (newLessonInformation.isForbidden) {
      setUserDoesNotHavePermissions(true);
      return;
    }

    if (!newLessonInformation.isSuccess) {
      throw newLessonInformation.errorMessage;
    }
    setFormattedAircrafts(createFormattedAircrafts(newLessonInformation.data.aircrafts));
    setLessonData(newLessonInformation.data);
    handleAircraftStuff(newLessonInformation.data.aircrafts);
    handleLessonStyleStuff(newLessonInformation.data.lessonStyles);
    handleLessonTypeStuff(newLessonInformation.data.lessonTypes);
    setLmsName(newLessonInformation.data.lmsName);
    setAuthorName(newLessonInformation.data.authorName);
  }
  //#endregion
  //#region [ rgba(10,80,82, 0.5)]

  async function handleSaveSettings() {
    const x: ICreateNewLessonWithMetadataRequest = {
      LessonMeta: {
        LessonVersionId: 0,
        LessonName: lessonText.lessonName,
        Description: lessonText.lessonDescription,
        MinimumPassingScore,
        ManufacturerId: Manufacturer.getIdByName(lessonData.aircrafts, noneCheck(aircraftData.manufacturer)),
        AircraftFamilyId: AircraftFamilyMember.getIdByName(
          lessonData.aircrafts,
          noneCheck(aircraftData.aircraftFamily),
        ),
        AircraftId: AircraftVariant.getIdByName(lessonData.aircrafts, noneCheck(aircraftData.aircraft)),
        AtaNumberIds: userSelectedAtaData.map((ata) => ata.ataNumberId) as number[],
        AtaNumberDtos: userSelectedAtaData,
        LessonStyle: {
          LessonStyleId: currentLessonStyle,
        },
        lessonTags: currentTags,
        LessonType: {
          LessonTypeId: currentLessonType,
        },
        additionalCopyrightHolders: lessonCopyrightHolders,
        CustomTailoredForLmss: lessonTailoredFor,
        isLessonWhiteTail: lessonIsWhiteTail,
      },
      cpatNarratorFileContents: narrationText,
    };

    if (!emptyTitle || l.pathname.toLowerCase() === scoPath) {
      setSaving(true);
      let response;
      if (!fileError && (pptimport || storylineimport || toolbook) && selectedFile !== null && l.pathname !== scoPath) {
        const requestFormData = new FormData();
        const stringified = [JSON.stringify(x)];
        const requestBlob = new Blob(stringified, { type: "application/json" });
        requestFormData.append("request", requestBlob);
        // make a new file with a sanitized name
        const fileWithMassagedName: File = new File([selectedFile], massageFileName(selectedFile));
        createFormDataInMbChunks(requestFormData, fileWithMassagedName);
        response = await genericRepositoryService.createNewLessonFromFileWithMetadata(requestFormData);
      } else if (
        l.pathname === "/" ||
        l.pathname.toLowerCase() === "/home" ||
        l.pathname.toLowerCase() === "/newlesson" ||
        selectedFile === null
      ) {
        response = await genericRepositoryService.createNewLessonWithMetadata(x);
      } else {
        if (selectedFile !== null) {
          const requestFormData = new FormData();
          const stringified = [JSON.stringify(x)];
          const requestBlob = new Blob(stringified, {
            type: "application/json",
          });
          requestFormData.append("request", requestBlob);
          // make a new file with a sanitized name
          const fileWithMassagedName: File = new File([selectedFile], massageFileName(selectedFile));
          createFormDataInMbChunks(requestFormData, fileWithMassagedName);
          if (l.pathname.toLowerCase() === scoPath) {
            // used to be else if
            response = await genericRepositoryService.createNewLessonFromCLASSCOFileWithMetadata(requestFormData);
          }
        }
      }
      if (response) {
        if (!response.isSuccess) {
          alert(response.errorMessage + " Please try again");
          navigate("newLesson");
          setSaving(false);
        } else {
          navigate(`/edit/Lesson/${response.data.createdLesson.lessonId}`);
        }
      } else {
        //something went bad
        setSaving(false);
      }
    }
  }
  //#endregion

  //#region [rgba(134, 20, 50,0.2)]
  const handleAddAta = (ata: IAtaNumberDto) => () => {
    const currentAtas: IAtaNumberDto[] = [...userSelectedAtaData];
    let deleteIndex = -1;
    currentAtas.forEach((a, index) => {
      if (a.ataNumberId === ata.ataNumberId) {
        deleteIndex = index;
      }
    });
    if (deleteIndex === -1) {
      currentAtas.push(ata);
    } else {
      currentAtas.splice(deleteIndex, 1);
    }

    setUserSelectedAtaData(currentAtas);
  };

  const selectedAta = (ata: IAtaNumberDto) => {
    const currentAtas: IAtaNumberDto[] = [...userSelectedAtaData];
    const selected = currentAtas.find((a) => {
      return ata.ataNumberId === a.ataNumberId;
    });
    return !!selected;
  };

  const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>, mode: string, index: number) => {
    const currentTagsCopy = [...currentTags];

    switch (mode) {
      case "name":
        currentTagsCopy[index].tagKey = e.target.value;
        setCurrentTags(currentTagsCopy);

        break;
      case "value":
        currentTagsCopy[index].tagValue = e.target.value;
        setCurrentTags(currentTagsCopy);

        break;
      default:
        return null;
    }
  };

  const handleIpFiltering = () => {
    return lessonData.lmss
      .filter((lms: any) => {
        return lms.name.includes(ipFilter) && lms.isDefaultCopyrightOwner === false;
      })
      .map((lms: any) => {
        return (
          <div key={"qazwsx" + lms.name}>
            <p
              title={lms.name}
              onClick={(e) => {
                setIpFilter(lms.name);
                setIpOptionsVisible(false);
              }}
            >
              {lms.name}
            </p>
          </div>
        );
      });
  };

  const handleAddIp = () => {
    const lms = lessonData.lmss.find((lms: any) => {
      return lms.name === ipFilter;
    });
    // currentIpOwners.forEach((lms, index) => {

    // })

    if (lms) {
      const currentIpOwners = [...lessonCopyrightHolders];
      const isAlreadyInList = currentIpOwners.find((llms) => {
        return llms.lmsKey === lms.lmsKey;
      });

      if (isAlreadyInList) {
        //do nothing
      } else {
        setIpOptionsVisible(false);
        setIpFilter("");
        setLessonCopyrightHolders([...currentIpOwners, lms]);
      }
    }
  };

  const canAddIp = () => {
    const lms = lessonData.lmss.find((lms: any) => {
      return lms.name === ipFilter;
    });
    if (lms) {
      const currentIpOwners = [...lessonData.lmss];
      const isAlreadyInList = currentIpOwners.find((llms) => {
        return llms.lmsKey === lms.lmsKey;
      });
      return !!isAlreadyInList;
    } else {
      return false;
    }
  };

  const handleRemoveIp = (lms: any) => () => {
    const currentIpOwners = [...lessonCopyrightHolders];
    let deleteIndex = -1;
    currentIpOwners.forEach((llms, index) => {
      if (llms.lmsKey === lms.lmsKey) {
        deleteIndex = index;
      }
    });
    currentIpOwners.splice(deleteIndex, 1);
    setLessonCopyrightHolders([...currentIpOwners]);
  };

  const handleTailoredForClick = (lms: any) => () => {
    const currentTailoredFor = [...lessonTailoredFor];
    let deleteIndex = -1;
    currentTailoredFor.forEach((llms, index) => {
      if (lms.lmsKey === llms.lmsKey) {
        deleteIndex = index;
      }
    });
    if (deleteIndex !== -1) {
      //delete
      currentTailoredFor.splice(deleteIndex, 1);
    } else {
      currentTailoredFor.push({
        name: lms.name,
        lmsKey: lms.lmsKey,
        isDefaultCopyrightOwner: lms.isDefaultCopyrightOwner,
      });
    }
    setLessonTailoredFor([...currentTailoredFor]);
  };

  const selectedTailoredForLms = (lmsKey: number) => {
    /**
     * List of currently chosen, in lesson tailored for Lms Keys used for checking if the lms in UI is in that list
     */
    const currentTailoredFor: number[] = lessonTailoredFor.map((llms) => {
      return llms.lmsKey;
    });

    return currentTailoredFor.includes(lmsKey);
  };

  const handleCpatIpClick = () => {
    if (props.isCpatUser) {
      //  settingsDispatch({type: "UPDATE_LESSON_META_DATA_LOCAL_COPY", payload: {isCpatCopyHolder: !settingsState.lessonMetaDataLocalCopy.isCpatCopyHolder}})
      setIsCpatCopyHolder(!isCpatCopyHolder);
    }
  };

  ////#endregion
  return (
    <React.Fragment>
      {saving && (
        <Portal>
          <div className="save-blocking-screen" style={{ zIndex: 150 }}>
            <div>
              <div className="loader-save">Creating..</div>
            </div>
          </div>
        </Portal>
      )}
      {!saving && (
        <React.Fragment>
          <NoPermissionsLockoutModal userDoesNotHavePermissions={userDoesNotHavePermissions} />
          <div className="lessonSettings-backdrop">
            <div className="lessonSettings-container" onMouseDown={(e) => e.stopPropagation()}>
              <div className="lessonSettings-title">
                {l.pathname.toLowerCase() !== scoPath ? <p>Lesson Settings</p> : <p>Upload Lesson From SCO</p>}
                <div>
                  <div className="settings-lms-container">
                    <div className="settings-lms">
                      <p>
                        <strong>LMS:</strong>
                        {lmsName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lessonSettings-general">
                <div className="lessonSettings-general-left-up">
                  {l.pathname.toLowerCase() !== scoPath && (
                    <>
                      <div className="lessonSettings-lessonTitle">
                        <div className={emptyTitle ? "title-message on" : "title-message off"}>
                          *Cannot have an empty Lesson Title
                        </div>
                        <p>Lesson Title</p>
                        <input
                          type="text"
                          value={lessonText.lessonName}
                          style={
                            emptyTitle
                              ? {
                                  background: "rgba(223,21,21,0.15)",
                                }
                              : {}
                          }
                          onChange={(e) => {
                            if (e.target.value.length <= 200) {
                              setLessonText({
                                ...lessonText,
                                lessonName: e.target.value,
                              });
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="lessonSettings-general-right-up">
                  {l.pathname.toLowerCase() !== scoPath && (
                    <>
                      <div className="lessonSettings-lessonDescription">
                        <p>Lesson Description</p>
                        <input
                          value={lessonText.lessonDescription}
                          onChange={(e) => {
                            setLessonText({
                              ...lessonText,
                              lessonDescription: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="lessonSettings-general-left-down">
                  <div>
                    <div className="lessonSettings-lessonAuthor">
                      <p>Author</p>
                      <input type="text" disabled value={authorName} />
                    </div>
                    <div className="lessonSettings-lessonStyle">
                      <p>Lesson Style</p>
                      <select disabled={true} value={"Computer Based Training"} onChange={(e) => {}}>
                        {importedDataLessonStyles.map((lessonStyle) => {
                          return <option key={"lslsk" + lessonStyle.name}>{lessonStyle.name}</option>;
                        })}
                      </select>
                    </div>
                    {l.pathname.toLowerCase() !== scoPath && (
                      <div className="lessonSettings-lessonType">
                        <p>Lesson Type</p>
                        <select
                          value={
                            importedDataLessonTypes[currentLessonType - 1]?.nickname ??
                            importedDataLessonTypes[currentLessonType - 1]?.name ??
                            ""
                          }
                          onChange={(e) => {
                            const name = e.target.value;
                            let lessonTypeId = -1;
                            importedDataLessonTypes.forEach((lt, index) => {
                              if (lt.name === name || lt.nickname === name) {
                                lessonTypeId = lt.lessonTypeId;
                              }
                            });
                            setCurrentLessonType(lessonTypeId);
                          }}
                        >
                          {importedDataLessonTypes.map((lessonType) => {
                            return (
                              <option key={"lsltk" + lessonType.name}>
                                {lessonType.nickname ? lessonType.nickname : lessonType.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="lessonSettings-general-right-down">
                  {l.pathname.toLowerCase() !== scoPath && (
                    <>
                      <div className="lessonSettings-aircraftSpecificity">
                        <div>
                          <p>Manufacturer</p>
                          <select
                            value={aircraftData.manufacturer}
                            onChange={(e) => {
                              e.persist();
                              const aircraftFamilyNames = Object.keys(formattedAircrafts[e.target.value].fleets);
                              const aircraftName = Object.keys(
                                formattedAircrafts[e.target.value].fleets[aircraftFamilyNames[0]].variants,
                              )[0];
                              setAircraftData((aircraft: any) => {
                                if (e.target.value === "None") {
                                  return {
                                    manufacturer: "None",
                                    aircraftFamily: "None",
                                    aircraft: "None",
                                  };
                                } else {
                                  return {
                                    manufacturer: e.target.value,
                                    aircraftFamily: aircraftFamilyNames[0],
                                    aircraft: aircraftName,
                                    configuration: "None",
                                  };
                                }
                              });
                              //  const manufacturerId = Manufacturer
                              //   .getIdByName(lessonData.aircrafts, noneCheck(e.target.value));
                              //  const aircraftFamilyId = AircraftFamilyMember
                              //   .getIdByName(lessonData.aircrafts, noneCheck(aircraftFamilyNames[0]));
                              //  const aircraftId = AircraftVariant
                              //   .getIdByName(lessonData.aircrafts, noneCheck(aircraftName))
                            }}
                          >
                            {Object.keys(formattedAircrafts).map((manufacturer, index) => {
                              return <option key={"lsfa" + index}>{manufacturer}</option>;
                            })}
                          </select>
                        </div>
                        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                        <div>
                          <p>Fleet</p>

                          <select
                            value={aircraftData.aircraftFamily}
                            onChange={(e) => {
                              e.persist();

                              const aircraftName = noneCheck(e.target.value)
                                ? Object.keys(
                                    formattedAircrafts[aircraftData.manufacturer].fleets[e.target.value].variants,
                                  )[0]
                                : "None";
                              setAircraftData((aircraft: any) => {
                                if (e.target.value === "None") {
                                  return {
                                    manufacturer: aircraft.manufacturer,
                                    aircraftFamily: "None",
                                    aircraft: "None",
                                    configuration: "None",
                                  };
                                } else {
                                  return {
                                    manufacturer: aircraft.manufacturer,
                                    aircraftFamily: e.target.value,
                                    aircraft: aircraftName,
                                    configuration: "None",
                                  };
                                }
                              });

                              // const aircraftFamilyId = AircraftFamilyMember.getIdByName(
                              //  lessonData.aircrafts,
                              //  noneCheck(e.target.value)
                              // )
                              // const aircraftId = AircraftVariant.getIdByName(
                              //  lessonData.aircrafts,
                              //  noneCheck(aircraftName)
                              // )
                            }}
                          >
                            {!aircraftData.manufacturer || aircraftData.manufacturer === "None" ? (
                              <option>None</option>
                            ) : (
                              Object.keys(formattedAircrafts[aircraftData.manufacturer].fleets).map((fleet, index) => {
                                return <option key={"lsfaf" + index}>{fleet}</option>;
                              })
                            )}
                          </select>
                        </div>

                        {/* /////////////////////////////////////////////////////////////////////////////////////////////// */}

                        <div>
                          <p>Variant</p>

                          <select
                            value={aircraftData.aircraft}
                            onChange={(e) => {
                              e.persist();
                              setAircraftData((aircraft: any) => {
                                if (e.target.value === "None") {
                                  return {
                                    manufacturer: aircraft.manufacturer,
                                    aircraftFamily: aircraft.aircraftFamily,
                                    aircraft: "None",
                                    configuration: "None",
                                  };
                                } else {
                                  return {
                                    manufacturer: aircraft.manufacturer,
                                    aircraftFamily: aircraft.aircraftFamily,
                                    aircraft: e.target.value,
                                    configuration: "None",
                                  };
                                }
                              });
                              // const aircraftId = AircraftVariant.getIdByName(lessonData.aircrafts, noneCheck(e.target.value))
                            }}
                          >
                            {aircraftData.aircraftFamily === "None" ? (
                              <option>None</option>
                            ) : (
                              Object.keys(
                                formattedAircrafts[aircraftData.manufacturer].fleets[aircraftData.aircraftFamily]
                                  .variants,
                              ).map((variant, index) => {
                                return <option key={"lsfava" + index}>{variant}</option>;
                              })
                            )}
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="lessonSettings-tabs-container">
                <div className="lessonSettings-tab-chooser">
                  {l.pathname.toLowerCase() !== scoPath && (
                    <div
                      className={
                        "lessonSettings-advanced-tab" +
                        (selectedTab === "Advanced Settings" ? " selected-settings-tab" : "")
                      }
                      onClick={() => {
                        setSelectedTab("Advanced Settings");
                      }}
                    >
                      <p>Advanced Settings</p>
                    </div>
                  )}
                  {l.pathname.toLowerCase() !== scoPath && (
                    <div
                      className={
                        "lessonSettings-extra-tab" + (selectedTab === "Extra Options" ? " selected-settings-tab" : "")
                      }
                      onClick={() => {
                        setSelectedTab("Extra Options");
                      }}
                    >
                      <p>Extra Options</p>
                    </div>
                  )}
                  {!loading && pptimport && (
                    <div
                      onClick={() => setSelectedTab("File-Input-PPT")}
                      className={`lessonSettings-extra-tab ${
                        selectedTab === "File-Input-PPT" ? " selected-settings-tab" : ""
                      }`}
                    >
                      <p>Lesson from PPT </p>
                    </div>
                  )}
                  {!loading && storylineimport && (
                    <div
                      onClick={() => setSelectedTab("File-Input-Storyline")}
                      className={`lessonSettings-extra-tab ${
                        selectedTab === "File-Input-Storyline" ? " selected-settings-tab" : ""
                      }`}
                    >
                      <p>Lesson from Storyline</p>
                    </div>
                  )}
                  {!loading && toolbook && (
                    <div
                      onClick={() => setSelectedTab("File-Input-TB")}
                      className={`lessonSettings-extra-tab ${
                        selectedTab === "File-Input-TB" ? " selected-settings-tab" : ""
                      }`}
                    >
                      <p>Lesson from TBK zip</p>
                    </div>
                  )}
                </div>
                <div className="lessonSettings-tab-content">
                  {selectedTab === "Advanced Settings" && (
                    <div className="advancedSettings">
                      <div className="settings-passing-score">
                        <p>Passing Score: </p>
                        <input
                          type="number"
                          min="50"
                          max="100"
                          value={MinimumPassingScore}
                          onChange={(e) => {
                            let score = +e.target.value;
                            if (score < 50) {
                              score = 50;
                            } else if (score > 100) {
                              score = 100;
                            }

                            setMinimumPassingScore(score);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {/* {selectedTab === 'References' && <ReferencesSettings setAssetManagerController={props.setAssetManagerController} />} */}
                  {selectedTab === "Extra Options" && (
                    <div className="extraOptions-settings">
                      <div className="settings-ata-container">
                        <div className="settings-ata-toggle">
                          <div className="settings-ata-title">ATA Number</div>
                          <div
                            className="settings-ata-open"
                            onClick={() => {
                              setAtaToggle(!ataToggle);
                            }}
                          >
                            {ataToggle ? <p>Close</p> : <p>Open</p>}
                            <OpenArrow style={ataToggle ? { transform: "rotate(180deg)" } : {}} />
                          </div>
                        </div>
                        {ataToggle && (
                          <div className="settings-ata-content">
                            <div className="settings-ata-grid">
                              {lessonData.ataNumbers.map((ata: any) => {
                                return (
                                  <div key={"lsac" + ata.ataNumberId} className="ata-cell" onClick={handleAddAta(ata)}>
                                    <div className="ata-check-box">
                                      {selectedAta(ata) ? <CheckChecked /> : <Check />}
                                    </div>
                                    <div className="ata-text">
                                      <p title={ata.description}>{`${ata.ataIdentifier} - ${ata.description}`}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="settings-tags-container">
                        <div className="settings-tags-toggle">
                          <div className="settings-tags-title">Tags</div>
                          <div
                            className="settings-tags-open"
                            onClick={() => {
                              setTagsToggle(!tagsToggle);
                            }}
                          >
                            {tagsToggle ? <p>Close</p> : <p>Open</p>}
                            <OpenArrow style={tagsToggle ? { transform: "rotate(180deg)" } : {}} />
                          </div>
                        </div>
                        {tagsToggle && (
                          <div className="settings-tags-content">
                            {currentTags.map((lt: any, index: number) => {
                              return (
                                <div key={"lstm" + index} className="tags-map">
                                  <div className="tags-name-container">
                                    <p>Name: </p>
                                    <input
                                      autoComplete="off"
                                      onChange={(e) => {
                                        handleTagsChange(e, "name", index);
                                      }}
                                      value={currentTags[index].tagKey}
                                      type="text"
                                      placeholder="Enter Tag Name"
                                    />
                                  </div>
                                  <div className="tags-value-container">
                                    <p>Value: </p>
                                    <input
                                      onChange={(e) => {
                                        handleTagsChange(e, "value", index);
                                      }}
                                      value={currentTags[index].tagValue}
                                      type="text"
                                      placeholder="Enter Tag Value"
                                    />
                                  </div>
                                  <div
                                    className="tag-remove"
                                    onClick={() => {
                                      const currentTagsCopy = [...currentTags];
                                      currentTagsCopy.splice(index, 1);

                                      // settingsDispatch({type: "UPDATE_LESSON_META_DATA_LOCAL_COPY", payload: {lessonTags: currentTags}})
                                      setCurrentTags(currentTagsCopy);
                                    }}
                                  >
                                    <RemoveIcon />
                                  </div>
                                </div>
                              );
                            })}

                            <div
                              className="tags-add"
                              onClick={() => {
                                // settingsDispatch({type: "UPDATE_LESSON_META_DATA_LOCAL_COPY", payload: {lessonTags: [...settingsState.lessonMetaDataLocalCopy.lessonTags, {tagKey: "", tagValue: ""}]}})
                                setCurrentTags([...currentTags, { tagKey: "", tagValue: "" }]);
                              }}
                            >
                              <PlusIcon />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="settings-ip-owner-container">
                        <div className="settings-ip-owner-toggle">
                          <div className="settings-ip-owner-title">Intellectual Property Owner</div>
                          <div
                            className="settings-ip-owner-open"
                            onClick={() => {
                              setIpToggle(!ipToggle);
                            }}
                          >
                            {ipToggle ? <p>Close</p> : <p>Open</p>}
                            <OpenArrow style={ipToggle ? { transform: "rotate(180deg)" } : {}} />
                          </div>
                        </div>
                        {ipToggle && (
                          <div className="settings-ip-owner-content">
                            <div>
                              {isCpatCopyHolder ? (
                                <CheckChecked
                                  onClick={handleCpatIpClick}
                                  className={props.isCpatUser ? "isCPaTEnabled" : ""}
                                />
                              ) : (
                                <Check onClick={handleCpatIpClick} />
                              )}
                              <p>CPaT</p>
                            </div>
                            <div className="ip-cpat-checkbox-container"></div>
                            <div className="ip-adding-container">
                              <div className="ip-input-select-container">
                                <input
                                  type="text"
                                  placeholder="Enter Organization Here"
                                  onFocus={() => {
                                    setIpOptionsVisible(true);
                                  }}
                                  value={ipFilter}
                                  onChange={(e) => {
                                    setIpFilter(e.target.value);
                                  }}
                                />
                                <div className="ip-select-options" style={ipOptionsVisible ? {} : { display: "none" }}>
                                  {handleIpFiltering().length === 0 ? (
                                    <div>
                                      <p>No Results Found</p>
                                    </div>
                                  ) : (
                                    handleIpFiltering()
                                  )}
                                </div>
                              </div>
                              <button onClick={handleAddIp} disabled={!canAddIp()}>
                                Add
                              </button>
                            </div>

                            <div
                              onClick={() => {
                                setIpOptionsVisible(false);
                              }}
                            >
                              {lessonCopyrightHolders
                                .filter((lms) => !lms.isDefaultCopyrightOwner)
                                .map((lms, index) => {
                                  return (
                                    <div key={"lsip" + lms.lmsKey} className="ip-owner-box">
                                      <p>{`${index + 1}. ${lms.name}`}</p>
                                      <RemoveIcon onClick={handleRemoveIp(lms)} />
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>

                      {props.isCpatUser && (
                        <div className="settings-tailoredFor-container">
                          <div className="settings-tailoredFor-toggle">
                            <div className="settings-tailoredFor-title">Tailored For</div>
                            <div
                              className="settings-tailoredFor-open"
                              onClick={() => {
                                setTailoredForToggle(!tailoredForToggle);
                              }}
                            >
                              {tailoredForToggle ? <p>Close</p> : <p>Open</p>}
                              <OpenArrow style={tailoredForToggle ? { transform: "rotate(180deg)" } : {}} />
                            </div>
                          </div>
                          {tailoredForToggle && (
                            <div className="settings-tailoredFor-content">
                              <div className="tailoredFor-header">
                                <div className="settings-whiteTail">
                                  {lessonIsWhiteTail ? (
                                    <CheckChecked
                                      onClick={() => {
                                        // settingsDispatch({type: "UPDATE_LESSON_META_DATA_LOCAL_COPY", payload: {isLessonWhiteTail: false}})
                                        setLessonIsWhiteTail(false);
                                      }}
                                    />
                                  ) : (
                                    <Check
                                      onClick={() => {
                                        // settingsDispatch({type: "UPDATE_LESSON_META_DATA_LOCAL_COPY", payload: {isLessonWhiteTail: true}})
                                        setLessonIsWhiteTail(true);
                                      }}
                                    />
                                  )}
                                  <p>White Tail</p>
                                </div>
                                <div className="tailoredFor-search">
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      setTailoredForFilter(e.target.value);
                                    }}
                                  />
                                  <div>
                                    <SearchIcon />
                                  </div>
                                </div>
                              </div>
                              <div className="tailoredFor-options">
                                {/**Map */}
                                {lessonData.lmss
                                  .filter((lms: any) => lms.name.includes(tailoredForFilter))
                                  .map((lms: any, index: number) => {
                                    return (
                                      <div
                                        key={"lstfk" + index}
                                        className="tailoredFor-lms-cell"
                                        onClick={handleTailoredForClick(lms)}
                                      >
                                        {selectedTailoredForLms(lms.lmsKey) ? <CheckChecked /> : <Check />}
                                        <p title={lms.name}>{lms.name}</p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {selectedTab === "File-Input-PPT" && pptimport && (
                    <div className="lessonSettings-file-input">
                      <div>
                        <p>Select PPT file</p>
                        <input
                          type="file"
                          accept=".pptx"
                          onChange={(e: any) => {
                            if (!e.target.files[0]) return;
                            const fileName = e.target.files[0].name;
                            if (fileName.substring(fileName.lastIndexOf(".") + 1) !== "pptx") {
                              setFileError(true);
                              return;
                            }
                            setSelectedFile(e.target.files[0]);
                            setFileError(false);
                          }}
                        />
                        {fileError && (
                          <div style={{ color: "red" }} className="title-message on">
                            Error loading file. Please select a valid file format
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {selectedTab === "File-Input-Storyline" && storylineimport && (
                    <div className="lessonSettings-file-input">
                      <div>
                        <p>Select Storyline file</p>
                        <input
                          type="file"
                          accept=".story"
                          onChange={(e: any) => {
                            if (!e.target.files[0]) return;
                            const fileName = e.target.files[0].name;
                            if (fileName.substring(fileName.lastIndexOf(".") + 1) !== "story") {
                              setFileError(true);
                              return;
                            }
                            setSelectedFile(e.target.files[0]);
                            setFileError(false);
                          }}
                        />
                        {fileError && (
                          <div style={{ color: "red" }} className="title-message on">
                            Error loading file. Please select a valid file format
                          </div>
                        )}
                      </div>

                      {selectedFile &&
                        selectedFile?.name.substring(selectedFile.name.lastIndexOf(".") + 1) === "story" && (
                          <div>
                            <p>Select narration file (optional)</p>
                            <input type="file" accept=".txt" onChange={(e) => handleNarrationFile(e)} />
                          </div>
                        )}
                    </div>
                  )}
                  {selectedTab === "File-Input-TB" && featuresToRender["pptimport"] && (
                    <div className="lessonSettings-file-input">
                      <div>
                        <p>Select ZIP file</p>
                        <input
                          type="file"
                          accept=".zip"
                          onChange={(e: any) => {
                            if (!e.target.files[0]) return;
                            const fileName = e.target.files[0].name;
                            if (fileName.substring(fileName.lastIndexOf(".") + 1) !== "zip") {
                              setFileError(true);
                              return;
                            }
                            setSelectedFile(e.target.files[0]);
                            setFileError(false);
                          }}
                        />
                        {fileError && (
                          <div style={{ color: "red" }} className="title-message on">
                            Error loading file. Please select a valid file format
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="lessonSettings-buttons">
                <button onClick={handleSaveSettings}>Save</button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// const mapDispatchTopProps = (dispatch:any) => {
//   return {
//     assignLMSKey: ''//created action
//   }
// }
const mapStateToProps = (state: ILessonDesignerStore) => {
  return {
    isCpatUser: state.authorizedState.isCpatUser,
  };
};
export default connect(mapStateToProps, null)(NewLessonModal);
