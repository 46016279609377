import React, { useRef } from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";

export function SVGTriangle({
  x,
  y,
  objectId,
  onMouseDown,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
  isDisplayed,
}: SVGAnnotationProps) {
  const triangleRef: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const createPoints = () => {
    const point1 = [4, height - 4];
    const point2 = [(width || 0) / 2, 4];
    const point3 = [width - 4 || 0, height - 4 || 0];
    // point format is "number,number number,number number,number"
    const points = `${point1.join(",")} ${point2.join(",")} ${point3.join(",")}`;
    return points;
  };

  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;

  return (
    <svg
      viewBox="0 0 auto auto"
      ref={triangleRef}
      data-objectid={objectId}
      id={`squareLetter-${objectId}`}
      className="annotation"
      name="triangle"
      onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      preserveAspectRatio="xMinYMin meet"
      style={{
        transform: transformString,
        width: `${width}px`,
        height: `${height}px`,
        zIndex: zIndex,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
      }}
    >
      <HighlightAnnotation objectId={objectId} />
      <polygon
        id="triangle-points"
        points={createPoints()}
        stroke={`${borderColor || "white"}`}
        fill={`${backgroundColor || "transparent"}`}
        strokeWidth={`${strokeWidth || "4"}`}
      />
    </svg>
  );
}
