import React, { useMemo } from "react";
import { ReactComponent as FrameSVG } from "./FilledKeyframe.svg";
import {
  clippedSpaceFromUnitSpace,
  clippedSpaceToUnitSpace,
  snapToGrid,
  useTimeline,
} from "../../../../../contexts/TimelineProvider/TimelineProvider";
import useRefAndState from "../../../../../hooks/useRefAndState";
import useDrag from "../../../../../hooks/useDrag";
import { Frame as FrameType } from "../../../../../types";
export const FRAME_WIDTH = 20;
export function Frame({
  frame,
  start,
  end,
  onDragEnd,
}: {
  frame: FrameType;
  start: number;
  end: number | null;
  onDragEnd: (frame: Partial<FrameType>) => void;
}) {
  const [setFrameRef, frameRef] = useRefAndState<HTMLDivElement | null>(null);
  const [timeline] = useTimeline();
  const {
    clippedSpace,
    scaledSpace: { leftPadding },
    widthInPx,
    subUnitsPerUnit,
  } = timeline;
  const clippedStart = useMemo(() => {
    return clippedSpaceFromUnitSpace(start, leftPadding, clippedSpace, widthInPx);
  }, [start, clippedSpace, clippedSpace, widthInPx]);
  const clippedEnd = useMemo(() => {
    return clippedSpaceFromUnitSpace(end ?? timeline.sequenceLength, leftPadding, clippedSpace, widthInPx);
  }, [end, clippedSpace, clippedSpace, widthInPx]);
  const position = clippedSpaceFromUnitSpace(frame.timestamp, leftPadding, clippedSpace, widthInPx);
  // const startOffset = frame.timestamp - start;
  // const startOffsetPx = clippedSpaceFromUnitSpace(startOffset, leftPadding, clippedSpace, widthInPx);

  useDrag(frameRef, {
    debugName: `Frame ${frame.id} drag`,
    onDragStart() {
      const initialPosition = position;
      let lastPosition = position;
      return {
        onDrag(dx) {
          // onDrag(dx);
          if (!frameRef) return;
          const newPosition = initialPosition + dx;
          if (newPosition < clippedStart || newPosition > clippedEnd) return;
          lastPosition = newPosition;
          frameRef.style.left = `${newPosition}px`;
        },
        onDragEnd(dragHappened) {
          if (!dragHappened) return;
          let finalUnitPosition = clippedSpaceToUnitSpace(lastPosition, leftPadding, clippedSpace, widthInPx);
          finalUnitPosition = snapToGrid(finalUnitPosition, subUnitsPerUnit);
          onDragEnd({
            timestamp: finalUnitPosition,
          });
        },
      };
    },
  });

  return (
    <div
      className="frame"
      style={{
        position: "absolute",
        left: position,
        height: "70%",
        cursor: "pointer",
        pointerEvents: "all",
      }}
      ref={setFrameRef}
    >
      <FrameSVG
        style={{
          transform: `translateX(-${FRAME_WIDTH / 2}px)`,
          height: "16px",
          width: FRAME_WIDTH,
          stroke: "black",
        }}
      />
    </div>
  );
}
