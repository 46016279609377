import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import QuizActionLister from "../../pageTypes/Quiz_Player/QuizActionLister";
import aeIcon from "../../assets/icons/ld-advance-editor-icon.png";
import { connect } from "react-redux";
import "./PageEditorActionLister.css";
import { ILessonDesignerStore } from "../../store/reducers/rootReducer";
import {
  ILessonPageActionsContext,
  LessonPageActionsContext,
  ILessonPageAction,
  IPageContext,
  PageContext,
  IEditorModeContext,
  EditorModeContext,
} from "../../routes/builderContexts";
import {
  QUIZ_PAGE,
  BASIC_PAGE,
  FMS_PAGE,
  ThreeD_PAGE,
  TITLE_PAGE,
  EXIT_PAGE,
  WALK_AROUND,
  CABIN,
} from "../constants/pageTypes";
import PagePreview from "./components/PagePreview";

import ILessonPageDto from "../../models/ILessonPageDto";
import AnnotationModeButton from "./components/AnnotationModeButton";
import { useLessonData } from "../../contexts/LessonDataProvider";
import { ReactComponent as CheckChecked } from "../../assets/icons/Settings/check-box-checked.svg";
import { ReactComponent as Check } from "../../assets/icons/Settings/check-box.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/Common/question-feedback-icon.svg";
import { ReactComponent as LineHeightIcon } from "../../assets/icons/Settings/line-height-white.svg";
import { textBoxVersionBridge } from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/functions/formatConversion";
import { LINE_HEIGHT_FOR_TEXT_BOXES } from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/BaseFreeForm";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { CURRENT_TEXTBOX_VERSION } from "../../utils/Versioning/basePagesVersioning";
import { travelTree } from "../../lib/interactivity";
import useSettingsToggle, { Settings, settingType } from "../../hooks/useSettingsToggle";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import BackgroundColorChooser from "./components/BackgroundColorChooser";
import { LessonPagesActions, useLessonPagesDispatch } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import { useObjectsState } from "../../contexts/ObjectsProvider";
import { AbstractTooltip as Tooltip } from "../ToolTip/ToolTip";

// import {ButtonsContext} from '../Header' ../../Annotation/AnnotationTextMenu/constants

type PropType = {
  currentIndex: number;
  lessonPages: ILessonPageDto[];
  isCpatUser?: boolean;
  lessonVersionId: number;
};

const renderSettings: Settings = {
  updatelivery: settingType.disabled,
};
const PageEditorActionLister: React.FC<PropType> = (props: PropType) => {
  // const buttonsContext = useContext(ButtonsContext)
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const [lessonModeOn, toggleLessonMode] = useState(false);
  const [lessonModeNames] = useState<string[]>(["Demo", "Practice", "Perform"]);
  const permissions = useLessonData({ permissions: true });
  const [featuresToRender] = useSettingsToggle(renderSettings);
  const handleAutoAdvance = () => () => {
    lessonPageActionsContext.handleRightSide("shouldAutoPageForward");
  };
  const [, setMiscUI] = useMiscUI();
  const lessonPagesDispatch = useLessonPagesDispatch();
  const handleIsScorable = () => () => {
    lessonPageActionsContext.handleRightSide("isScorable");
  };
  const { updatelivery } = featuresToRender;
  const objectsState = useObjectsState();
  /*    useEffect(() => {
     setFeaturesToRender((null != props.isCpatUser) ? props.isCpatUser : false); 
   },[loading, props.isCpatUser])
 */
  const handleLessonModes = (i: number) => (e: any) => {
    const currentLessonModes = _.cloneDeep(pageContext.page.lessonModeIds);
    //check the array does it have the number I pushed already in there and are we on our last number
    const lessonMode = i + 1;

    if (currentLessonModes.includes(lessonMode)) {
      currentLessonModes.splice(currentLessonModes.indexOf(lessonMode), 1);
    } else {
      currentLessonModes.push(lessonMode);
    }

    lessonPageActionsContext.handleRightSide("", [...currentLessonModes]);
  };

  useEffect(() => {
    if ("canEdit" in permissions) {
      if (permissions.canEdit(pageContext.page.pagePlayerType)) {
      } else {
        lessonPageActionsContext.setLessonPageActions([]);
      }
    }
  }, [pageContext.page.pagePlayerType]);

  function handleTextBoxFix() {
    const pageManifestCopy = _.cloneDeep(pageContext.pageManifest);
    const fixesTextBloc = pageManifestCopy.textBlock.map((bloc: any) => {
      //modifies the text to be latest version compliant
      bloc.text = textBoxVersionBridge(bloc.text);
      //sets the lineHeight property
      bloc.lineHeight = LINE_HEIGHT_FOR_TEXT_BOXES;
      //updates to latest version
      bloc.version = CURRENT_TEXTBOX_VERSION; // line height edit versioning
      return bloc;
    });
    pageManifestCopy.textBlock = fixesTextBloc;
    pageContext.updatePageManifest(pageManifestCopy);
    setMiscUI({ type: "TOGGLE_QUILL_REFRESH" });
  }

  /**
   * this function will check if the page is able to auto advance, it runs several times and if
   * the dependecies are in a certain way then it will toggle the availability of the auto advance
   *
   * jokingly should be named, probably should split the checks more neatly
   * checkIfThePageIsAbleToAutoAdvanceAndAlsoUpdateTheAutoAdvanceIfAnyOfTheseDependenciesChange
   *
   */
  const checkIfBasePageHasAudio = () => {
    // as an update this needs to check if there is interactivity on the page. if this is the
    // case then the auto advance should be able to be used
    const pmInteractivityLb = pageContext?.pageManifest?.interactivity?.levelBuffer;

    if (
      pageContext.pageManifest.Audio &&
      pageContext.pageManifest.Audio.length > 0 &&
      pageContext.pageManifest.Audio[0].File
    ) {
      return true;
    } else if (_.has(pageContext.pageManifest, "video")) {
      return true;
    } else if (pmInteractivityLb && pmInteractivityLb.length > 0) {
      let req = false;
      travelTree(pageContext.pageManifest.interactivity, (level) => {
        //@ts-ignore this is breaking in ^3.9.10
        level.actionBuffer.forEach((action) => {
          if (action.required) {
            req = action.required;
          }
        });
      });
      if (!req && pageContext.page.shouldAutoPageForward === true) {
        handleAutoAdvance()();
      }
      return req;
    } else {
      if (pageContext.page.shouldAutoPageForward === true) {
        handleAutoAdvance()();
      }
      return false;
    }
  };

  const checkLessonMode = (i: number): boolean => {
    if (Object.entries(pageContext.page).length !== 0) {
      const checked = pageContext.page.lessonModeIds;
      if (checked.length === 0) return false;
      if (checked.includes(i + 1)) return true;
      return false;
    } else {
      return false;
    }
  };

  const checkFMSSuccessCriteria = () => {
    //console.clear()
    if (
      (!pageContext.pageManifest.successCriteria || pageContext.pageManifest.successCriteria?.length <= 0) &&
      pageContext.pageManifest.Audio?.length <= 0
    ) {
      return true;
    } else {
      return false;
    }
    //////////////////////////////////////////////////Removed as a short term fix for lesson mode independent auto advance
    // if (pageContext.pageManifest.successCriteria){
    //   if(pageContext.pageManifest.successCriteria.length <= 0  && !checkFMSAudio()) {
    //     if (pageContext.page.shouldAutoPageForward) {
    //       lessonPageActionsContext.handleRightSide("shouldAutoPageForward");
    //     }
    //     return true;
    //   }else {
    //     return false;
    //   }
    // }else {
    //   return false;
    // }
  };

  const openAdvancedEditor = () => {
    const scorm = objectsState.objectList.find((object) => object.type === "SCORM");
    lessonPagesDispatch({
      type: LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST,
      payload: {
        objects: objectsState.objectList,
        animatedObjects: objectsState.animatedObjects,
        images: objectsState.images,
        textBoxes: objectsState.textBoxes,
        annotations: objectsState.annotations,
        tables: objectsState.tables,
        videos: objectsState.videos,
        scorm: scorm,
        hotspots: objectsState.hotspots,
      },
    });
    pageContext.showAdvancedEditor(true);
  };
  const showPagePreview = (
    <div className="pageEditorActionListerRow">
      <div className="pageListerActionButton">
        <PagePreview pagenumber={pageContext.currentlyDisplayedPageIndex} lessonVersionId={props.lessonVersionId} />
      </div>
    </div>
  );

  const showAdvancedEditor = (
    <div className="pageEditorActionListerRow">
      <div className="cpat-user-button">
        <button
          className="pageListerActionButton"
          data-tooltip-id="Show Advanced Editor"
          onClick={() => openAdvancedEditor()}
          name="Show Advanced Editor"
        >
          <img className="pageListerActionImg" src={aeIcon} alt="" />
          Show Advanced Editor
        </button>
        <Tooltip id={"Show Advanced Editor"} />
      </div>
    </div>
  );

  const showLiveryEditor = (
    <div className="pageEditorActionListerRow">
      <div className={renderSettings["updatelivery"] == settingType.cpatonly ? "cpat-user-button" : ""}>
        <button
          className="pageListerActionButton"
          style={{ padding: 0, textAlign: "center", fontSize: "1em" }}
          data-tooltip-id="Show Livery Editor"
          onClick={() => pageContext.showLiveryEditor(true)}
          name="Show Livery Editor"
        >
          Aircraft Livery
        </button>
        <Tooltip id={"Show Livery Editor"} />
      </div>
    </div>
  );

  const importedButtons = (
    <React.Fragment>
      {/* <PageEditorModeSelector/> */}
      {/* {warningBool() ? <AudioWarning /> : <></>} */}
      {showPagePreview}
      {props.isCpatUser && showAdvancedEditor}
      {(props.isCpatUser || updatelivery) && pageContext.pageType === WALK_AROUND && showLiveryEditor}
      {lessonPageActionsContext.lessonPageActions.map((value: ILessonPageAction, index: number) => {
        return (
          <React.Fragment key={"lpamr" + index}>
            <div className="pageEditorActionListerRow" key={index}>
              <button
                className="pageListerActionButton"
                data-tooltip-id={value.actionName}
                onClick={value.triggerAction}
                name={value.actionName}
              >
                <InfoIcon className="pageListerActionImg" />
                {value.actionName}
              </button>
              <Tooltip id={value.actionName} />
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );

  if (pageContext.pageType === TITLE_PAGE || pageContext.pageType === EXIT_PAGE) {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}
        <div className="pageEditorActionListerRow disabled-action">
          <div className="pageListerActionButton auto-advance-p" style={{ background: "var(--disabled-color)" }}>
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                disabled={true}
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            style={{ background: "var(--disabled-color)" }}
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes?.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    <input type="checkbox" disabled={true} checked={true} />
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageContext.page.name === "Score Page") {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}
        <BackgroundColorChooser />
        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    <input
                      type="checkbox"
                      onChange={handleLessonModes(i)}
                      checked={checkLessonMode(i)}
                      disabled={lessonModeNames[i] === "Demo" || lessonModeNames[i] === "Practice"}
                    />
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  } else if (pageContext.page.name === "Warning Page") {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className={`pageEditorActionListerRow${checkIfBasePageHasAudio() ? "" : " disabled-action"}`}>
          <div
            className="pageListerActionButton auto-advance-p"
            data-tooltip-id="Auto Advance"
            style={{
              background: !checkIfBasePageHasAudio() ? "var(--disabled-color)" : "rgb(40,61,85)",
            }}
          >
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
                disabled={!checkIfBasePageHasAudio()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>
        <BackgroundColorChooser />
        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    <input
                      type="checkbox"
                      onChange={handleLessonModes(i)}
                      checked={checkLessonMode(i)}
                      disabled={lessonModeNames[i] === "Demo" || lessonModeNames[i] === "Practice"}
                    />
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  } else if (pageContext.page.name === "Objective Page") {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className={`pageEditorActionListerRow${checkIfBasePageHasAudio() ? "" : " disabled-action"}`}>
          <div
            className="pageListerActionButton auto-advance-p"
            data-tooltip-id="Auto Advance"
            style={{
              background: !checkIfBasePageHasAudio() ? "var(--disabled-color)" : "rgb(40,61,85)",
            }}
          >
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
                disabled={!checkIfBasePageHasAudio()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>
        <BackgroundColorChooser />
        {props.isCpatUser && (
          <div className="pageEditorActionListerRow">
            <div className="cpat-user-button">
              <div
                className="pageListerActionButton"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
                onClick={handleTextBoxFix}
              >
                <LineHeightIcon />
                Update Line Height
              </div>
            </div>
          </div>
        )}
        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`} onClick={handleLessonModes(i)}>
                    {lessonModeNames[i]}
                    <input type="checkbox" onChange={handleLessonModes(i)} checked={checkLessonMode(i)} />
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  } else if (pageContext.page.name === "Conclusion Page") {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className={`pageEditorActionListerRow${checkIfBasePageHasAudio() ? "" : " disabled-action"}`}>
          <div
            className="pageListerActionButton auto-advance-p"
            data-tooltip-id="Auto Advance"
            style={{
              background: !checkIfBasePageHasAudio() ? "var(--disabled-color)" : "rgb(40,61,85)",
            }}
          >
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
                disabled={!checkIfBasePageHasAudio()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>
        <BackgroundColorChooser />
        {props.isCpatUser && (
          <div className="pageEditorActionListerRow">
            <div className="cpat-user-button">
              <div
                className="pageListerActionButton"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
                onClick={handleTextBoxFix}
              >
                <LineHeightIcon />
                Update Line Height
              </div>
            </div>
          </div>
        )}
        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`} onClick={handleLessonModes(i)}>
                    {lessonModeNames[i]}
                    <input type="checkbox" onChange={handleLessonModes(i)} checked={checkLessonMode(i)} />
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  } else if (pageContext.page.name === "Introduction Page") {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className={`pageEditorActionListerRow${checkIfBasePageHasAudio() ? "" : " disabled-action"}`}>
          <div
            className="pageListerActionButton auto-advance-p"
            data-tooltip-id="Auto Advance"
            style={{
              background: !checkIfBasePageHasAudio() ? "var(--disabled-color)" : "rgb(40,61,85)",
            }}
          >
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
                disabled={!checkIfBasePageHasAudio()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>
        <BackgroundColorChooser />
        {props.isCpatUser && (
          <div className="pageEditorActionListerRow">
            <div className="cpat-user-button">
              <div
                className="pageListerActionButton"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
                onClick={handleTextBoxFix}
              >
                <LineHeightIcon />
                Update Line Height
              </div>
            </div>
          </div>
        )}
        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`} onClick={handleLessonModes(i)}>
                    {lessonModeNames[i]}
                    <input type="checkbox" onChange={handleLessonModes(i)} checked={checkLessonMode(i)} />
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  }

  // QUIZ  PAGE
  else if (pageContext.pageType === QUIZ_PAGE) {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}
        <QuizActionLister lessonPages={props.lessonPages} currentIndex={props.currentIndex} />
        {/* <AnnotationModeButton /> */}

        <div className="pageEditorActionListerRow scorable-button">
          <div className="pageListerActionButton" data-tooltip-id="Is Scorable">
            <p>Scorable</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch-scorable"
                checked={pageContext.page.isScorable}
                onChange={handleIsScorable()}
              />
              <label htmlFor="toggle-switch-scorable" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Is Scorable"} />
        </div>
        <BackgroundColorChooser />
        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    {/* <input type="checkbox" checked={checkLessonMode(i)} onChange={handleLessonModes(i)} /> */}
                    {checkLessonMode(i) ? (
                      <CheckChecked onClick={handleLessonModes(i)} className="page-modes-check" />
                    ) : (
                      <Check onClick={handleLessonModes(i)} className="page-modes-check" />
                    )}
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  }

  // BASE PAGE
  else if (pageContext.pageType === BASIC_PAGE) {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className={`pageEditorActionListerRow${checkIfBasePageHasAudio() ? "" : " disabled-action"}`}>
          <div
            className={`pageListerActionButton auto-advance-p`}
            data-tooltip-id="Auto Advance"
            style={checkIfBasePageHasAudio() ? {} : { background: "var(--disabled-color)" }}
          >
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
                disabled={!checkIfBasePageHasAudio()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>
        <BackgroundColorChooser />
        {props.isCpatUser && (
          <div className="pageEditorActionListerRow">
            <div className="cpat-user-button">
              <div
                className="pageListerActionButton"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
                onClick={handleTextBoxFix}
              >
                <LineHeightIcon />
                Update Line Height
              </div>
            </div>
          </div>
        )}

        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    {checkLessonMode(i) ? (
                      <CheckChecked onClick={handleLessonModes(i)} className="page-modes-check" />
                    ) : (
                      <Check onClick={handleLessonModes(i)} className="page-modes-check" />
                    )}
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  }

  // FMS PAGE
  else if (pageContext.pageType === FMS_PAGE) {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}
        <AnnotationModeButton />

        {pageContext.fmsDataWorksheetURL ? ( // show worksheet launch button if we have a worksheet
          <div className="pageEditorActionListerRow">
            <div
              className="pageListerActionButton edit-mode-btn"
              data-tooltip-id="FMCDataWorksheet"
              style={{}}
              onClick={() => {
                window.open(blobUrlFormatHelper(pageContext.fmsDataWorksheetURL!), "_blank", "noopener,noreferrer");
              }}
            >
              <span>FMC Data Worksheet</span>
            </div>
            <Tooltip id={"FMCDataWorksheet"} />
          </div>
        ) : null}

        <div className="pageEditorActionListerRow scorable-button">
          <div className="pageListerActionButton" data-tooltip-id="Is Scorable">
            <p>Scorable</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch-scorable"
                checked={pageContext.page.isScorable}
                onChange={handleIsScorable()}
              />
              <label htmlFor="toggle-switch-scorable" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Is Scorable"} />
        </div>
        <div className={`pageEditorActionListerRow${checkFMSSuccessCriteria() ? "" : " disabled-action"}`}>
          <div
            className="pageListerActionButton auto-advance-p"
            data-tooltip-id="Auto Advance"
            style={!checkFMSSuccessCriteria() ? {} : { background: "var(--disabled-color)" }}
          >
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
                disabled={checkFMSSuccessCriteria()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>
        <BackgroundColorChooser />
        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    {checkLessonMode(i) ? (
                      <CheckChecked onClick={handleLessonModes(i)} className="page-modes-check" />
                    ) : (
                      <Check onClick={handleLessonModes(i)} className="page-modes-check" />
                    )}

                    {/* <input type="checkbox" checked={checkLessonMode(i)} onChange={handleLessonModes(i)} /> */}
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  }

  // THREE D PAGE
  else if (pageContext.pageType === ThreeD_PAGE) {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className="pageEditorActionListerRow scorable-button">
          <div className="pageListerActionButton" data-tooltip-id="Is Scorable">
            <p>Scorable</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch-scorable"
                checked={pageContext.page.isScorable}
                onChange={handleIsScorable()}
              />
              <label htmlFor="toggle-switch-scorable" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Is Scorable"} />
        </div>
        <div className="pageEditorActionListerRow">
          <div className="pageListerActionButton auto-advance-p" data-tooltip-id="Auto Advance">
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>

        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    {checkLessonMode(i) ? (
                      <CheckChecked onClick={handleLessonModes(i)} className="page-modes-check" />
                    ) : (
                      <Check onClick={handleLessonModes(i)} className="page-modes-check" />
                    )}

                    {/* <input type="checkbox" checked={checkLessonMode(i)} onChange={handleLessonModes(i)} /> */}
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  }

  // WALK AROUND
  else if (pageContext.pageType === WALK_AROUND) {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className="pageEditorActionListerRow scorable-button">
          <div className="pageListerActionButton" data-tooltip-id="Is Scorable">
            <p>Scorable</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch-scorable"
                checked={pageContext.page.isScorable}
                onChange={handleIsScorable()}
              />
              <label htmlFor="toggle-switch-scorable" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Is Scorable"} />
        </div>
        <div className="pageEditorActionListerRow">
          <div className="pageListerActionButton auto-advance-p" data-tooltip-id="Auto Advance">
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>

        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    <input type="checkbox" checked={checkLessonMode(i)} onChange={handleLessonModes(i)} />
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  }

  // CABIN
  else if (pageContext.pageType === CABIN) {
    return (
      <div className="pageEditorActionListerInnerContainer">
        {importedButtons}

        <div className="pageEditorActionListerRow scorable-button">
          <div className="pageListerActionButton" data-tooltip-id="Is Scorable">
            <p>Scorable</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch-scorable"
                checked={pageContext.page.isScorable}
                onChange={handleIsScorable()}
              />
              <label htmlFor="toggle-switch-scorable" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="pageEditorActionListerRow">
          <div className="pageListerActionButton auto-advance-p" data-tooltip-id="Auto Advance">
            <p>Page Auto Advance</p>
            <div className="toggle toggle-switch">
              <input
                type="checkbox"
                className="toggle-checkbox"
                id="toggle-switch"
                checked={pageContext.page.shouldAutoPageForward}
                onChange={handleAutoAdvance()}
              />
              <label htmlFor="toggle-switch" className="toggle-btn">
                <span className="toggle-feature" data-label-on="on" data-label-off="off"></span>
              </label>
            </div>
          </div>
          <Tooltip id={"Auto Advance"} />
        </div>

        <div className="pageEditorActionListerRow flex-grow">
          <div
            className="pageListerActionButton plab-lessonModes"
            data-tooltip-id="Lesson Modes"
            onClick={() => toggleLessonMode(!lessonModeOn)}
          >
            Page Modes
            <div className="plab-mode-container">
              {lessonPageActionsContext.lessonModes.map((el: any, i: number) => {
                return (
                  <label key={`lbbl${el.lessonMode}`}>
                    {lessonModeNames[i]}
                    <input type="checkbox" checked={checkLessonMode(i)} onChange={handleLessonModes(i)} />
                  </label>
                );
              })}
            </div>
          </div>
          <Tooltip id={"Lesson Modes"} />
        </div>
      </div>
    );
  } else {
    return <div className="pageEditorActionListerInnerContainer">{importedButtons}</div>;
  }
};

const mapStateToProps = (state: ILessonDesignerStore) => {
  return {
    isCpatUser: state.authorizedState.isCpatUser,
  };
};
export default connect(mapStateToProps, null)(PageEditorActionLister);
