import "./assets/annotation.css";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IAnnotation } from "./models/IAnnotation";
import AnnotationCircledNumber from "./types/AnnotationCircledNumber";
import AnnotationLineArrow from "./types/AnnotationLineArrow";
import AnnotationLabel from "./types/AnnotationLabel";
import AnnotationSquareNumber from "./types/AnnotationSquareNumber";
import AnnotationLine from "./types/AnnotationLine";
import AnnotationDashedLine from "./types/AnnotationDashedLine";
import AnnotationPointArrow from "./types/AnnotationPointArrow";
import AnnotationSquare from "./types/AnnotationSquare";
import AnnotationRoundedSquare from "./types/AnnotationRoundedSquare";
import AnnotationTriangle from "./types/AnnotationTriangle";
import AnnotationCircle from "./types/AnnotationCircle";
import AnnotationCircledLetter from "./types/AnnotationCircledLetter";
import AnnotationSquareLetter from "./types/AnnotationSquareLetter";
import { IKeyPressHandler } from "../ObjectPropertyBox/models/IKeyPressHandler";
import { ElementTypes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { ISymbolV2 } from "../Symbol/models/ISymbol";
import { useTablesDataDispatch, useTablesDataState } from "../Tables/TablesDataProvider";

export type AnnotationType = {
  annotations?: IAnnotation[];
  editableIndex: number;
  isMenuShown: boolean;
  kp: IKeyPressHandler;
  pageManifest?: any;
  symbols?: ISymbolV2[];
  target: HTMLElement | SVGSVGElement;
  handleFormatChange(e: any, funct: any, value: any, keyValue: any): void;
  // handlePasteText: (e: React.ClipboardEvent<HTMLDivElement>) => void,
  markSelected: (target: HTMLDivElement | SVGSVGElement, index: number, type: ElementTypes) => void;
  setTarget: (val: any) => void;
  setElementType(value: ElementTypes): void;
  setEditableIndex(number: number): void;
  setNodeToUpdate(obj: any): void;
  updateLabel: (text: string, scrollWidth: number, scrollHeight: number) => void;
  setIsMenuShown: React.Dispatch<React.SetStateAction<boolean>>;
  elementType?: ElementTypes;
  objectIsInTimeline?: boolean;
};

export const ALL_ANNOTATION_TYPES = [
  "arrow",
  "fillArrow",
  "lineArrow",
  "solidLine",
  "dashedLine",
  "circle",
  "roundedSquare",
  "square",
  "triangle",
  "circledNumber",
  "circledLetter",
  "squareNumber",
  "squareLetter",
  "label",
];
export type AnnotationPropType = {
  annotation: IAnnotation;
  count: number;

  // editableIndex: number,
  index: number;
  isDragging?: boolean;
  kp: IKeyPressHandler;
  ratio: { height: number; width: number };
  objectIsInTimeline?: boolean;
  markSelected: (target: HTMLDivElement | SVGSVGElement, index: number, type: ElementTypes) => void;
  setElementType(value: ElementTypes): void;
  setNodeToUpdate(obj: any): void;
  setTarget: (val: any) => void;
};

const Annotation = (props: AnnotationType) => {
  const {
    annotations,
    editableIndex,
    isMenuShown,
    kp,
    target,
    handleFormatChange,
    markSelected,
    setEditableIndex,
    setElementType,
    setNodeToUpdate,
    setTarget,
    updateLabel,
    setIsMenuShown,
    elementType,
  } = props;
  // const labelRefs: any = useRef([]);
  const [isActive, setIsActive]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false);
  const [ratio]: [{ height: number; width: number }, Dispatch<SetStateAction<{ height: number; width: number }>>] =
    useState<{ height: number; width: number }>({
      height: parseFloat((2 / 3).toFixed(4)),
      width: parseFloat((2 / 3).toFixed(4)),
    });
  const [textField, setTextField]: [HTMLElement | null, React.Dispatch<React.SetStateAction<HTMLElement | null>>] =
    useState<HTMLElement | null>(null);
  let circledLetterCount = 0;
  let circledNumberCount = 1;
  let squareLetterCount = 0;
  let squareNumberCount = 0;
  // let arrowCount: number = 1;
  // let labelCount: number = 1;
  const TablesDispatch = useTablesDataDispatch();
  const TablesState = useTablesDataState();

  useEffect(() => {
    if (annotations && annotations.length > 0) {
      document.addEventListener("mousedown", handleAnnotationClick);
      return () => document.removeEventListener("mousedown", handleAnnotationClick);
    }
  });

  useEffect(() => {
    if (isMenuShown) {
      setIsActive(true);
    }
  }, [isMenuShown]);

  function handleAnnotationClick(e: any) {
    // if(e.path[2].id.toString() === 'New-Save-Idea'){
    //    TablesDispatch({ action: 'setSelectedTable', payload: [undefined] });
    // }
    if (elementType !== "pageImage") {
      removeLabelSelection(e);
      removeTarget(e);
    }
  }

  function removeTarget(e: any) {
    if (!target || !e.target || !annotations) return;
    const toolbar: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      ".wysiwyg-toolbar",
    ) as NodeListOf<HTMLDivElement>;
    const opb: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      ".property-box-container",
    ) as NodeListOf<HTMLDivElement>; //object property box
    const moveable: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      ".moveable-control-box",
    ) as NodeListOf<HTMLDivElement>;
    const deleteModal: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      ".confirm-delete-header",
    ) as NodeListOf<HTMLDivElement>;

    const targetClassName = e.target.getAttribute("class") ?? "";
    const parentClassName = e.target.parentElement?.getAttribute("class") ?? "";

    // account for either the target or parent being an annotation
    // there may be other possibilities but I have not encountered them yet
    const notAnAnnotation =
      targetClassName !== "annotation" &&
      !targetClassName.includes("target") &&
      parentClassName !== "annotation" &&
      !parentClassName.includes("target");
    // check if target is property box or toolbar
    const didnotClickOnToolBarorPB =
      !target.contains(e.target) &&
      !toolbar[0]?.contains(e.target) &&
      opb.length > 0 &&
      !opb[0]?.contains(e.target) &&
      ((deleteModal.length > 0 && deleteModal[0].contains(e.target)) || deleteModal.length === 0) &&
      !moveable[0]?.contains(e.target);

    if (notAnAnnotation && didnotClickOnToolBarorPB) {
      setEditableIndex(-1);
      setNodeToUpdate(null);
      setTarget(null);
    }
  }

  function removeLabelSelection(e: any) {
    if (annotations && !textField?.contains(e.target) && textField?.parentElement) {
      textField.parentElement.style.outline = annotations[editableIndex]?.borderColor
        ? (annotations[editableIndex].borderColor as string)
        : "none";
      setTextField(null);
    }
  }

  const displayAnnotations = () => {
    if (annotations && annotations.length >= 0) {
      return annotations.map((annotation: IAnnotation, index: number) => {
        // fix for some data being null in the page manifest for annotations
        // this relies on other components calling the updatePageManifest function to get the updated data
        // to the manifest.
        if (!annotation.top) {
          annotation.top = 1;
        }
        if (!annotation.left) {
          annotation.left = 1;
        }
        const objectIsInTimeline = !!props.pageManifest?.timeline?.animatedObjects?.find((ao: any) => {
          return ao.id === annotation?.objectId;
        });
        const annotationProp: AnnotationPropType = {
          annotation: annotation,
          index: index,
          count: circledNumberCount,
          kp: kp,
          // "handleKeyPress": handleKeyPress,
          markSelected: markSelected,
          ratio: ratio,
          setElementType: setElementType,
          setNodeToUpdate: setNodeToUpdate,
          setTarget: setTarget,
          objectIsInTimeline,
        };
        switch (annotation.type) {
          case "fillArrow":
          case "pointArrow":
          case "arrow":
            // ++arrowCount;
            return <AnnotationLineArrow key={index} {...annotationProp} />;
          case "circledNumber":
            ++circledNumberCount;
            return <AnnotationCircledNumber key={index} {...annotationProp} />;
          case "circledLetter":
          case "circleLetter":
            ++circledLetterCount;
            return <AnnotationCircledLetter key={index} {...annotationProp} count={circledLetterCount} />;
          case "line":
            return <AnnotationLine key={index} {...annotationProp} />;
          case "squareNumber":
            ++squareNumberCount;
            return <AnnotationSquareNumber key={index} {...annotationProp} count={squareNumberCount} />;
          case "squareLetter":
            ++squareLetterCount;
            return <AnnotationSquareLetter key={index} {...annotationProp} count={squareLetterCount} />;
          case "label":
            // ++labelCount;
            return (
              <AnnotationLabel
                key={index}
                {...annotationProp}
                editableIndex={editableIndex}
                isActive={isActive && index === editableIndex}
                isMenuShown={isMenuShown && index === editableIndex}
                handleFormatChange={handleFormatChange}
                // refFunc={(el: any, index: number) => {getLabelRef(el, index)}}
                setEditableIndex={props.setEditableIndex}
                setElementType={setElementType}
                setIsActive={setIsActive}
                setIsMenuShown={setIsMenuShown}
                updateLabel={updateLabel}
              />
            );
          case "solidLine":
            return <AnnotationLine key={index} {...annotationProp} />;
          case "dashedLine":
            return <AnnotationDashedLine key={index} {...annotationProp} />;
          case "lineArrow":
            return <AnnotationPointArrow key={index} {...annotationProp} />;
          case "square":
            return <AnnotationSquare key={index} {...annotationProp} />;
          case "roundedSquare":
            return <AnnotationRoundedSquare key={index} {...annotationProp} />;
          case "triangle":
            return <AnnotationTriangle key={index} {...annotationProp} />;
          case "circle":
            return <AnnotationCircle key={index} {...annotationProp} />;
          default:
            return null;
        }
      });
    }
  };

  return <React.Fragment>{displayAnnotations()}</React.Fragment>;
};

export default Annotation;
