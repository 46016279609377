import React from "react";
import { colors } from "../../Annotation/AnnotationTextMenu/constants";
import { FormatButtonType } from "../WYSIWYGToolbar";
import ColorPicker from "./ColorPicker";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
const FontColorButton2 = (props: FormatButtonType) => {
  const { handleFormatChange } = props;
  const [isShown, setIsShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    React.useState<boolean>(false);

  return (
    <React.Fragment>
      <div className="toolbar-btn-container" data-tooltip-id="FontColor">
        <Tooltip id="FontColor" />
        <button className="wysiwyg-toolbar-btn" id="font-color" onClick={() => setIsShown(!isShown)}>
          <span className="icon-font-color-icon"></span>
        </button>
        {isShown ? (
          <ColorPicker
            isShown={isShown}
            keyValue={"fontColor"}
            handleFormatChange={handleFormatChange}
            setIsShown={setIsShown}
            colors={colors}
          />
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
};

export default FontColorButton2;
