import "../BasicPage_Player/BasePageDesigner.css";
import "./Quiz_Editor.css";
import { cloneDeep, forEach, isEmpty, isEqual, some } from "lodash";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import QuizChoices from "./QuizChoices";
import QuizAddChoice from "./QuizAddChoice";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import {
  AssetContext,
  IAssetManagementContext,
  ILessonPageActionsContext,
  IPageContext,
  LessonPageActionsContext,
  PageContext,
} from "../../routes/builderContexts";
import IQuizInterface from "./IQuizInterfaces";
import addIcon from "../../assets/icons/ld-add-add-criteria-icon.png";
import QuizChoiceModal from "./QuizChoiceModal";
import infoIcon from "../../assets/icons/ld-info-icon.png";
import WYSIWYG from "../../components/WYSIWYG/WYSIWYG";
import { IDandPath } from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";
import { drawerIcon } from "../../components/PageLister/DrawerSVGs";
import { ReactComponent as Layout2 } from "../../assets/icons/LayoutChange/2-answers.svg";
import { ReactComponent as Layout3 } from "../../assets/icons/LayoutChange/3-answers.svg";
import { ReactComponent as Layout4 } from "../../assets/icons/LayoutChange/4-answers.svg";
import { ReactComponent as Layout5 } from "../../assets/icons/LayoutChange/5-answers.svg";
import { ReactComponent as Layout6 } from "../../assets/icons/LayoutChange/6-answers.svg";
import { ReactComponent as RadioUnanswered } from "../../assets/icons/Quiz/radio-button-text-answers-not-selected.svg";
import { ReactComponent as RadioAnswered } from "../../assets/icons/Quiz/radio-button-text-answers-selected.svg";
import { updateImage } from "../../utils/Images";

const PLACEHOLDER_IMAGE_SCALE = "scale(4)";
//HOC Icons
const layoutsSvgs = [
  (props: any) => <Layout2 {...props} />,
  (props: any) => <Layout3 {...props} />,
  (props: any) => <Layout4 {...props} />,
  (props: any) => <Layout5 {...props} />,
  (props: any) => <Layout6 {...props} />,
];

export interface QuizAsset {
  imagePath: string;
  assetVersionId: number;
}

export interface IQuizPageManifest {
  // [key: string]: QuizAsset[] | any
  image: QuizAsset[];
  question: {
    text: string;
    maxAttempts: number;
    audio: any[];
  };
  randomize: boolean;
  choices: any[];
}

const choiceTemplate = {
  text: "Enter answer here...",
  isCorrect: false,
  responses: {
    correct: "That is the correct answer",
    incorrect: "That is the incorrect answer",
  },
  audio: [
    {
      File: "",
      assetVersionId: 0,
      parentVersionId: 0,
      FileMD5: "",
      NarratorText: "",
    },
  ],
};

const QuizEditor: React.FC = () => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: IQuizInterface = cloneDeep(pageContext.pageManifest);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const updatedManifest = cloneDeep(pageManifest);
  const [choiceIndex, setChoiceIndex]: [number, React.Dispatch<React.SetStateAction<number>>] = useState<number>(-1);
  const [correctFeedback, setCorrectFeedback]: [string, Dispatch<SetStateAction<string>>] = useState("");
  const [incorrectFeedback, setIncorrectFeedback]: [string, Dispatch<SetStateAction<string>>] = useState("");
  const [isChoiceModalShown, setIsChoiceModalShown]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isMaxReached, setIsMaxReached]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [quizAnswersMode, setQuizAnswersMode] = useState<"text" | "graphic">("text");
  const [numberOfAnswers, setNumberOfAnswers] = useState<number>(
    updatedManifest.isGraphic ? updatedManifest.image.filter((img) => img.imagePath).length : 2,
  );

  const imageOne: string = blobUrlFormatHelper(updatedManifest.image[0].imagePath);

  useEffect(() => {
    lessonPageActionsContext.setLessonPageActions([
      {
        actionName: "Answer Feedback",
        triggerAction: () => onQuizFeedbackClick(),
        iconPath: `${infoIcon}`,
      },
    ]);
    addGenericFeedback();
    checkMaxChoices();

    if (!isEqual(updatedManifest, pageContext.pageManifest)) {
      pageContext.updatePageManifest(updatedManifest);
    }

    if (updatedManifest.image.length >= 2) {
      handleSwitchingToGraphicQuiz("graphic")(undefined!);
      handleSwitchingLayoutNumber(updatedManifest.image.length)(undefined!);
    }
  }, []);

  useEffect(() => {
    let tempIds: number[] = [];
    const validateIds = () => {
      forEach(updatedManifest.image, (image) => {
        tempIds.push(image.assetVersionId);
      });
    };
    validateIds();
    if (!isEqual(pageContext.pageAssetIds, tempIds) && tempIds.length > 0) {
      pageContext.setPageAssetIds(tempIds);
    }

    //updatePageAndImageData();
    return () => {
      tempIds = [];
    };
  }, [updatedManifest.image.length, pageContext.pageAssetIds]);

  useEffect(() => {
    if (updatedManifest?.annotations?.length > 0) {
      updatedManifest.randomize = false;
      pageContext.updatePageManifest(updatedManifest);
    }
  }, [!!updatedManifest?.annotations?.length, updatedManifest?.randomize]);

  useEffect(() => {
    updatePageAndImageData();
  }, [numberOfAnswers]);

  const onQuizFeedbackClick = (): void => {
    setIsChoiceModalShown(true);
    setChoiceIndex(-1);
  };

  const displayImage = (imageIndex: number): JSX.Element => {
    let imageSource = "";
    if (imageIndex === 0) {
      imageSource = imageOne;
    }

    if (!isEmpty(updatedManifest.image[imageIndex].imagePath)) {
      return (
        <img
          src={imageSource}
          alt=""
          className="Quizimg"
          id={"quiz-image-" + imageIndex}
          onLoad={() => updateImage(imageIndex, updatedManifest, pageContext)}
        />
      );
    } else {
      return <>Click here to select a picture...</>;
    }
  };

  const handleImageDivClick = (index: number) => {
    assetContext.setAssetIndex(index);
    assetContext.setAssetTypeId(4);
    // assetContext.showBlobUploader(true);
    assetContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
  };

  const addChoice = (): void => {
    updatedManifest.choices.push(choiceTemplate);
    updatedManifest.question.maxAttempts = updatedManifest.choices.length;
    checkMaxChoices();
    pageContext.updatePageManifest(updatedManifest);
  };

  const checkMaxChoices = () => {
    if (updatedManifest.choices.length >= 5) {
      setIsMaxReached(true);
    }
  };

  const displayChoiceModal = (index: number) => {
    setCorrectFeedback(updatedManifest.choices[index].responses.correct);
    setIncorrectFeedback(updatedManifest.choices[index].responses.incorrect);
    setChoiceIndex(index);
    setIsChoiceModalShown(true);
  };

  const markAsCorrect = (e: any) => {
    const value = parseInt(e.target.value);
    updatedManifest.choices.forEach((choice, index) => {
      updatedManifest.choices[index].isCorrect = value === index;
    });
    pageContext.updatePageManifest(updatedManifest);
  };

  const updateQuestion = (e: any): void => {
    updatedManifest.question.text = e.target.value;
    pageContext.updatePageManifest(updatedManifest);
  };

  const updateChoice = (e: any, index: number): void => {
    updatedManifest.choices[index].text = e.target.value;
    pageContext.updatePageManifest(updatedManifest);
  };

  const addGenericFeedback = () => {
    updatedManifest.choices.forEach((choice, key) => {
      if (updatedManifest.choices[key].responses.correct.trim() === "") {
        updatedManifest.choices[key].responses.correct = "That is the correct answer!";
      }

      if (updatedManifest.choices[key].responses.incorrect.trim() === "") {
        updatedManifest.choices[key].responses.incorrect = "That is the incorrect answer!";
      }
    });
  };

  const removeChoice = async (key: number): Promise<void> => {
    if (isMaxReached) {
      setIsMaxReached(false);
    }

    updatedManifest.choices.splice(key, 1);
    const hasTrue = some(updatedManifest.choices, ["isCorrect", true]);

    if (!hasTrue) {
      updatedManifest.choices[0].isCorrect = true;
    }

    updatedManifest.question.maxAttempts = updatedManifest.choices.length;
    pageContext.updatePageManifest(updatedManifest);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "correct") {
      setCorrectFeedback(e.target.value);
    } else {
      setIncorrectFeedback(e.target.value);
    }
  };

  const handleSave = () => {
    if (choiceIndex > -1) {
      updatedManifest.choices[choiceIndex].responses = {
        correct: correctFeedback,
        incorrect: incorrectFeedback,
      };
    } else {
      forEach(updatedManifest.choices, (choice, index) => {
        updatedManifest.choices[index].responses = {
          correct: correctFeedback,
          incorrect: incorrectFeedback,
        };
      });
    }
    pageContext.updatePageManifest(updatedManifest);
    setIsChoiceModalShown(false);
    setChoiceIndex(-1);
  };

  const getIsCorrect = () => {
    if (updatedManifest.choices && updatedManifest.choices[choiceIndex]) {
      return updatedManifest.choices[choiceIndex].isCorrect;
    } else {
      return false;
    }
  };

  function handleSwitchingToGraphicQuiz(type: "text" | "graphic") {
    return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //check if the page manifest has at least 2 images slots available;
      if (updatedManifest.image.length >= 2) {
        if (type === "text") {
          updatedManifest.image = updatedManifest.image.filter((x, i) => i === 0); // reset image to 1 image
          updatedManifest.choices = updatedManifest.choices.filter((x, i) => i <= 1); // reset choices to 2 choices
          pageContext.updatePageManifest(updatedManifest);
          setNumberOfAnswers(2);
        }
        while (updatedManifest.choices.length < updatedManifest.image.length) {
          const choiceCopy = cloneDeep(choiceTemplate);
          updatedManifest.choices.push(choiceCopy);
          if (updatedManifest.choices.every((choice) => !choice.isCorrect)) {
            updatedManifest.choices[0].isCorrect = true;
          }
        }
      } else {
        updatedManifest.image.push({
          assetVersionId: 0,
          imagePath: "",
          height: 0,
          left: 0,
          top: 0,
          width: 0,
        });

        if (updatedManifest.choices.length > updatedManifest.image.length) {
          updatedManifest.choices = updatedManifest.choices.filter((x, i) => i < updatedManifest.image.length); // match choices to images
        }
        while (updatedManifest.choices.length < updatedManifest.image.length) {
          const choiceCopy = cloneDeep(choiceTemplate);
          updatedManifest.choices.push(choiceCopy);
        }
        if (updatedManifest.choices.every((choice) => !choice.isCorrect)) {
          updatedManifest.choices[0].isCorrect = true;
        }
        pageContext.updatePageManifest(updatedManifest);
      }
      setQuizAnswersMode(type);

      updatePageAndImageData();
    };
  }

  function updatePageAndImageData() {
    for (let i = 0; i < updatedManifest.image.length; i++) {
      updateImage(i, updatedManifest, pageContext);
    }
  }

  function handleSwitchingLayoutNumber(layoutNumber: number) {
    return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      //check if the page manifest has at least 2 images slots available;
      if (updatedManifest.image.length >= layoutNumber) {
        // already have all of them

        updatedManifest.image = updatedManifest.image.filter((x, i) => i < layoutNumber);
        updatedManifest.choices = updatedManifest.choices.filter((x, i) => i < updatedManifest.image.length); // match choices to images
        if (updatedManifest.choices.every((choice) => !choice.isCorrect)) {
          updatedManifest.choices[0].isCorrect = true;
        }
        pageContext.updatePageManifest(updatedManifest);
        setNumberOfAnswers(layoutNumber);
      } else {
        while (updatedManifest.image.length !== layoutNumber) {
          updatedManifest.image.push({
            assetVersionId: 0,
            imagePath: "",
            height: 0,
            left: 0,
            top: 0,
            width: 0,
          });
        }
        while (updatedManifest.choices.length < updatedManifest.image.length) {
          const choiceCopy = cloneDeep(choiceTemplate);
          updatedManifest.choices.push(choiceCopy);
          if (updatedManifest.choices.every((choice) => !choice.isCorrect)) {
            updatedManifest.choices[0].isCorrect = true;
          }
        }
        pageContext.updatePageManifest(updatedManifest);
        setNumberOfAnswers(layoutNumber);
      }

      updatePageAndImageData();
    };
  }

  function WyswygQuizRightSide() {
    const [changingLayout, setChangingLayout] = useState<boolean>(false);

    return (
      <>
        {quizAnswersMode === "graphic" && (
          <div onClick={() => setChangingLayout(!changingLayout)} className="quiz-change-layout-container">
            <div className="quiz-layout-arrow" data-quiz-arrow-open={changingLayout}>
              {drawerIcon()}
            </div>
            <div onClick={(e) => e.stopPropagation()} className={changingLayout ? "quiz-layouts" : "quiz-layouts-hide"}>
              {layoutsSvgs.map((svgEl, index) => {
                return svgEl({
                  onClick: handleSwitchingLayoutNumber(index + 2),
                  key: index + "svgEL",
                });
              })}
            </div>
            <div className="quiz-layout-button">
              <span>Change Layout</span>
            </div>
          </div>
        )}
        <button onClick={handleSwitchingToGraphicQuiz("graphic")}>Graphic Answer</button>
        <button onClick={handleSwitchingToGraphicQuiz("text")}>Text Answer</button>
      </>
    );
  }

  return (
    <React.Fragment>
      <QuizChoiceModal
        index={choiceIndex}
        correctFeedback={correctFeedback}
        incorrectFeedback={incorrectFeedback}
        isCorrect={getIsCorrect()}
        manifest={updatedManifest}
        show={isChoiceModalShown}
        markAsCorrect={markAsCorrect}
        handleChange={handleChange}
        handleSave={handleSave}
        onHide={() => setIsChoiceModalShown(false)}
      />
      <WYSIWYG rightSideActions={WyswygQuizRightSide}>
        <div id="quizplayer">
          {quizAnswersMode === "text" && (
            <div className="Quizcenter">
              <div className="quiz-image" id="quiz-image-container">
                <div
                  className="img-aspect-container"
                  onClick={() => handleImageDivClick(0)}
                  id={"img-aspect-container-0"}
                >
                  <div className="img-aspect-container-inside">
                    <div className="img-aspect-centering">{displayImage(0)}</div>
                  </div>
                </div>
              </div>
              <div className="Quizbasic">
                <div className="question-area">
                  <textarea
                    className="question-text-box"
                    value={updatedManifest.question.text}
                    placeholder="Enter question here..."
                    rows={3}
                    onChange={(e: any) => updateQuestion(e)}
                  />
                </div>
                <div className="choice-area">
                  <QuizChoices
                    displayChoiceModal={displayChoiceModal}
                    newManifest={updatedManifest}
                    updateChoice={updateChoice}
                    removeChoice={removeChoice}
                    markAsCorrect={markAsCorrect}
                  />
                  <QuizAddChoice isMaxReached={isMaxReached} addIcon={addIcon} addChoice={addChoice} />
                </div>
                <div style={{ display: "flex" }}>
                  <button id="quizSubmitButton" className="Quizsubmit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          {quizAnswersMode === "graphic" && (
            <GraphicQuiz layoutPageAmount={numberOfAnswers} handleImageDivClick={handleImageDivClick} />
          )}
        </div>
      </WYSIWYG>
    </React.Fragment>
  );
};

function GraphicQuiz(props: { layoutPageAmount: number; handleImageDivClick: any }) {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const updatedManifest = cloneDeep(pageContext.pageManifest);

  const updateQuestion = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    updatedManifest.question.text = event.target.value;
    pageContext.updatePageManifest(updatedManifest);
  };

  const handleImageRadioClick = (index: number) => () => {
    updatedManifest.choices = updatedManifest.choices.map((choice: any, i: number) => {
      choice.isCorrect = i === index;
      return choice;
    });
    pageContext.updatePageManifest(updatedManifest);
  };

  function getLayoutCss() {
    switch (props.layoutPageAmount) {
      default:
      case 2:
        return { gridTemplateColumns: "50% 50%", gridTemplateRows: "100%" };
      case 3:
        return {
          gridTemplateColumns: "33.33% 33.33% 33.33%",
          gridTemplateRows: "100%",
        };
      case 4:
        return { gridTemplateColumns: "50% 50%", gridTemplateRows: "50% 50%" };
      case 5:
        return { gridTemplateColumns: "100%", gridTemplateRows: "50% 50%" };
      case 6:
        return {
          gridTemplateColumns: "33.33% 33.33% 33.33%",
          gridTemplateRows: "50% 50%",
        };
    }
  }

  return (
    <div className="quiz-graphic-layout">
      <div className="quiz-graphic-question">
        <div className="question-area-gq">
          <textarea
            className="question-text-box"
            value={pageContext.pageManifest.question.text}
            placeholder="Enter question here..."
            rows={3}
            onChange={updateQuestion}
          />
        </div>
        <div>Select Correct Answer:</div>
      </div>
      <div className="quiz-graphic-answers" id="quiz-image-container">
        <div className="quiz-graphic-answers-layouts" style={getLayoutCss()}>
          {updatedManifest.image.length === 5 ? (
            <QuizLayout5Answers imageArray={updatedManifest.image} handleImageDivClick={props.handleImageDivClick} />
          ) : (
            <>
              {updatedManifest.image.map((pageImage: any, index: number) => {
                return (
                  <div key={"qgz3" + index} className="quiz-image-cell">
                    <div
                      className="quiz-image"
                      onClick={() => {
                        props.handleImageDivClick(index);
                      }}
                    >
                      <div className="img-aspect-container" id={"img-aspect-container-" + index}>
                        <div className="img-aspect-container-inside">
                          <div className="img-aspect-centering">
                            {pageImage.imagePath ? (
                              <img
                                src={"/api/Asset/" + pageImage.imagePath}
                                alt=""
                                className="Quizimg"
                                id={"quiz-image-" + index}
                                onLoad={() => updateImage(index, updatedManifest, pageContext)}
                              />
                            ) : (
                              <span
                                className="icon-insert-image-icon"
                                style={{ transform: PLACEHOLDER_IMAGE_SCALE }}
                              ></span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {updatedManifest.choices[index].isCorrect ? (
                      <RadioAnswered />
                    ) : (
                      <RadioUnanswered onClick={handleImageRadioClick(index)} />
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      <div className="quiz-graphic-submit">
        <button>Submit</button>
      </div>
    </div>
  );
}

function QuizLayout5Answers(props: { imageArray: any[]; handleImageDivClick: any }) {
  const topRow = props.imageArray.filter((x, i) => i <= 2);
  const bottomRow = props.imageArray.filter((x, i) => i > 2);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const updatedManifest = cloneDeep(pageContext.pageManifest);
  const handleImageRadioClick = (index: number) => () => {
    updatedManifest.choices = updatedManifest.choices.map((choice: any, i: number) => {
      choice.isCorrect = i === index;
      return choice;
    });
    pageContext.updatePageManifest(updatedManifest);
  };
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "33.33% 33.33% 33.33%",
          gridTemplateRows: "100%",
        }}
      >
        {topRow.map((x, i) => {
          return (
            <div key={"qgz1" + i} className="quiz-image-cell">
              <div className="quiz-image" onClick={() => props.handleImageDivClick(i)}>
                <div className="img-aspect-container" id={"img-aspect-container-" + i}>
                  <div className="img-aspect-container-inside">
                    <div className="img-aspect-centering">
                      {x.imagePath ? (
                        <img
                          src={"/api/Asset/" + x.imagePath}
                          alt=""
                          className="Quizimg"
                          id={"quiz-image-" + i}
                          onLoad={() => updateImage(i, updatedManifest, pageContext)}
                        />
                      ) : (
                        <span className="icon-insert-image-icon" style={{ transform: PLACEHOLDER_IMAGE_SCALE }}></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {updatedManifest.choices[i].isCorrect ? (
                <RadioAnswered />
              ) : (
                <RadioUnanswered onClick={handleImageRadioClick(i)} />
              )}
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "16.66665% 33.33% 33.33% 16.66665%",
          gridTemplateRows: "100%",
        }}
      >
        {bottomRow.map((x, i) => {
          return (
            <div key={"qgz2" + i} className="quiz-image-cell" style={{ gridColumn: `${i + 2} / span 1` }}>
              <div className="quiz-image" onClick={() => props.handleImageDivClick(i + 3)}>
                <div className="img-aspect-container" id={"img-aspect-container-" + i}>
                  <div className="img-aspect-container-inside">
                    <div className="img-aspect-centering">
                      {x.imagePath ? (
                        <img
                          src={"/api/Asset/" + x.imagePath}
                          alt=""
                          className="Quizimg"
                          id={"quiz-image-" + i}
                          onLoad={() => updateImage(i, updatedManifest, pageContext)}
                        />
                      ) : (
                        <span className="icon-insert-image-icon" style={{ transform: PLACEHOLDER_IMAGE_SCALE }}></span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {updatedManifest.choices[i + 3].isCorrect ? (
                <RadioAnswered />
              ) : (
                <RadioUnanswered onClick={handleImageRadioClick(i + 3)} />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export function QuizPageManifestAssetCollector(pageManifest: IQuizPageManifest) {
  const assets: IDandPath[] = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    if (key === "image") {
      value.forEach((image: QuizAsset) => {
        if ("assetVersionId" in image && "imagePath" in image) {
          const { assetVersionId, imagePath } = image;
          const temp = new IDandPath(assetVersionId, imagePath);
          assets.push(temp);
        }
      });
    }
  }
  return assets;
}

export default QuizEditor;
