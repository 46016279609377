import { useContext } from "react";
import {
  CLEAR_ALL_VISIBLE_HOTSPOTS,
  SET_ALL_VISIBLE_HOTSPOTS,
  useInteractivityBuilderDispatch,
} from "../contexts/InteractivityBuilderProvider";
import { useInteracitvityHotspotDispatch } from "../contexts/InteractivityHotspotProvider";
import {
  useLessonPagesState,
  LessonPagesActions,
  useLessonPagesDispatch,
} from "../contexts/LessonPagesProvider/LessonPagesProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../contexts/ObjectsProvider";
import { InteractivityBuilder } from "../lib/interactivity";
import { PageContext } from "../routes/builderContexts";
import { useTimelineDispatch } from "../contexts/TimelineProvider/TimelineProvider";

export function useGoToPage(OLD_STATE_lessonPages: any[]) {
  const lessonPagesState = useLessonPagesState();
  const lessonPagesDispatch = useLessonPagesDispatch();
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const timelineDispatch = useTimelineDispatch();
  return (pageIndexToDisplay: number) => {
    const OLD_STATE_pageManifest = OLD_STATE_lessonPages?.[pageIndexToDisplay]?.pageManifest;

    // we save all edits made to the objects on the page before switching pages
    const scorm = objectsState.objectList.find((object) => object.type === "SCORM");
    lessonPagesDispatch({
      type: LessonPagesActions.SET_OBJECTS_ON_PAGE_MANIFEST,
      payload: {
        objects: objectsState.objectList,
        animatedObjects: objectsState.animatedObjects,
        images: objectsState.images,
        textBoxes: objectsState.textBoxes,
        tables: objectsState.tables,
        annotations: objectsState.annotations,
        videos: objectsState.videos,
        hotspots: objectsState.hotspots,
        scorm,
      },
    });
    // we switch the currently selected page from the lesson pages
    lessonPagesDispatch({
      type: LessonPagesActions.SET_CURRENT_PAGE_INDEX,
      payload: pageIndexToDisplay,
    });
    const newPageManifest = {
      ...lessonPagesState.lessonPages[pageIndexToDisplay].pageManifest,
      // tables: OLD_STATE_pageManifest.tables,
    };
    // we create a new object list from the new page manifest
    objectsDispatch({
      type: ObjectActionsType.SET_OBJECTS_FROM_PAGE_MANIFEST,
      payload: newPageManifest,
    });
    const sequenceLength = newPageManifest?.timeline?.sequenceLength ?? newPageManifest?.timeline?.timelineLength ?? 30;
    timelineDispatch({
      type: "SET_SEQUENCE_LENGTH",
      payload: sequenceLength,
    });
  };
}
