import React from "react";
import { IAnnotation } from "../../Annotation/models/IAnnotation";
import ISymbolStyle from "../../Symbol/models/ISymbolStyle";
import { formatFunctions } from "../WYSIWYGToolbarFunctions/WYSIWYGToolbarFunctions";
import { ReactComponent as LWicon } from "../../../assets/icons/line-width-icon.svg";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
type LineWidthType = {
  attributes: IAnnotation | ISymbolStyle;
  handleFormatChange(e: any, funct: any, value: any, keyValue: any): void;
};

const LineWidthButton = (props: LineWidthType) => {
  const { attributes, handleFormatChange } = props;
  const { selectedObjects, selectedObjectId } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  // if (attributes ?.strokeWidth === undefined || attributes ?.strokeWidth === null) { attributes.strokeWidth = 4 } else { attributes.strokeWidth = attributes ?.strokeWidth }
  let maxWidth = 9;
  const minWidth = 1;
  if (attributes) {
    switch ((attributes as IAnnotation).type) {
      case "lineArrow":
        maxWidth = 9;
        break;
      case "pointArrow":
        maxWidth = 9;
        break;
      case "solidLine":
        maxWidth = 9;
        break;
      case "dashedLine":
        maxWidth = 9;
        break;
      case "circle":
        maxWidth = 5;
        break;
      case "roundedSquare":
        maxWidth = 5;
        break;
      case "square":
        maxWidth = 5;
        break;
      case "triangle":
        maxWidth = 5;
        break;
      case "circledNumber":
        maxWidth = 5;
        break;
      case "circleLetter":
        maxWidth = 5;
        break;
      case "squareNumber":
        maxWidth = 5;
        break;
      case "squareLetter":
        maxWidth = 5;
        break;
      default:
        maxWidth = 9;
    }
  }

  const slider = {
    max: maxWidth,
    min: minWidth,
    value: selectedObjects[0]?.strokeWidth ?? attributes?.strokeWidth ?? 4,
  };

  const handleSliderChange = (event: any, data: number) => {
    if (selectedObjectId[0]) {
      objectsDispatch({
        type: ObjectActionsType.SET_STROKE_WIDTH,
        payload: { objectId: selectedObjectId[0], strokeWidth: data },
      });
    } else {
      handleFormatChange(event, formatFunctions.changeStrokeWidth, event.currentTarget.id, event.target.valueAsNumber);
    }
  };

  return (
    <React.Fragment>
      <div className="wysiwyg-toolbar-slider" id="lineStroke" data-tooltip-id="LineWidth">
        <Tooltip id="LineWidth" />
        <div className="svgHolder">
          <LWicon />
        </div>
        <input
          id="strokeWidth"
          type="range"
          className="toolbar-sliderStyle"
          min={slider.min}
          max={slider.max}
          step={1}
          value={slider.value}
          onChange={
            (event) => handleSliderChange(event, event.target.valueAsNumber)
            // handleFormatChange(
            //   event,
            //   formatFunctions.changeStrokeWidth,
            //   event.currentTarget.id,
            //   event.target.valueAsNumber,
            // )
            // objectsDispatch({
            //   type: ObjectActionsType.SET_STROKE_WIDTH,
            //   payload: { objectId: selectedObjectId[0], strokeWidth: event.target.valueAsNumber},
            // })
          }
        ></input>
        {/* <div className="toolbar-sliderValue"> {slider.value} </div> */}
        {/* <input type="number" className="toolbar-sliderValue" min={slider.min} max={slider.max} value={attributes.strokeWidth} onChange={(event) => (handleFormatChange(event, formatFunctions.changeStrokeWidth, 'strokeWidth', event.target.valueAsNumber))} /> */}
        <div className="toolbar-sliderValue"> {slider.value} </div>
        {/* {strokeWidthValue} </div> */}
      </div>
    </React.Fragment>
  );
};

export default LineWidthButton;
