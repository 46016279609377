import { useMemo } from "react";
import { useObjectsState } from "../contexts/ObjectsProvider";
import { useTimeline } from "../contexts/TimelineProvider/TimelineProvider";
import { getFramesNearCurrentTime } from "../objects/Images/Image";
import { useAnimatedObject } from "./useAnimatedObject";

export function useInterpolatedFrame(objectId: string) {
  const animatedObject = useAnimatedObject(objectId);
  const frames = animatedObject?.frames;
  const framesCacheId = animatedObject?.framesCacheId;
  const [timeline] = useTimeline();
  const currentTime = timeline.scrubbingCurrentTime;
  const interpolatedFrame = useMemo(() => getFramesNearCurrentTime(frames, currentTime), [framesCacheId, currentTime]);
  return {
    transformY: interpolatedFrame?.y,
    transformX: interpolatedFrame?.x,
    width: interpolatedFrame?.width,
    height: interpolatedFrame?.height,
    rotation: interpolatedFrame?.rotation,
    interpolatedOpacity: interpolatedFrame?.opacity,
  };
}
