import { createContext } from "react";
export interface AircraftForLesson {
  aircraftId: number | null;
  aircraftFamilyId: number | null;
  manufacturerId: number | null;
}
export interface fileListObject {
  file: any;
  name: string;
  managerId: number;
  uploading: boolean;
  dataBaseObject?: any | IAssetMetaData;
  metaDataIsDirty: boolean;
  updateFailed: boolean;
}
export interface uploadedFileObject {
  blobPath: string;
  assetId: number;
  managerId: number;
  dataBaseObject?: any | IAssetMetaData;
  metaDataIsDirty: boolean;
  updateFailed: boolean;
}
export interface IAssetMetaData {
  [index: string]: string | number | string[] | number[] | boolean | ITag[] | null;
  assetVersionId: number;
  manufacturerId: number[];
  aircraftId: number[];
  aircraftFamilyId: number[];
  configurationId: string[];
  name: string;
  description: string;
  isLibrary: boolean;
  LmsKey: number | null;
  tags: ITag[] | any;
}
export interface ITag {
  [index: string]: string;
  key: string;
}
export interface IAssetUploaderState {
  fileString: string;
  setFileString: (value: string) => void;
  filesUploaded: number;
  setFilesUploaded: (value: number) => void;
  selectedId: number;
  setSelectedID: (value: number) => void;
  ataIds: number[];
  setAtaIds: (value: number[]) => void;
  newFiles: fileListObject[];
  setNewFiles: (value: fileListObject[]) => void;
  uploadedAssets: uploadedFileObject[];

  setUploadedAssets: (value: uploadedFileObject[]) => void;
  pageList: number[];
  setPageList: (value: number[]) => void;
  versionName: string;
  setVersionName: (value: string) => void;
  canClickSave: boolean;
  setCanClickSave: (value: boolean) => void;
}
export interface IUploadManagerContext {
  aircraftData: any;
  setAircraftData: (value: any) => void;
  aircraftIds: any;
  setAircraftIds: (value: any) => void;
  ataIds: any;
  setAtaIds: (value: any) => void;
  uploadedAssets: any;
  newFiles: fileListObject[];
  lmsKey: number;
  isCpatUser: boolean;
  tailoredForLms: { lmsKey: number; name: string }[];
  removeAsset: (id: number) => void;
  assetReRender: boolean;
  setAssetReRender: (value: boolean) => void;
}
export const UploadManagerContext = createContext<IUploadManagerContext>({} as IUploadManagerContext);
