import _ from "lodash";
import React, { Dispatch, SetStateAction, useState, useRef, useEffect } from "react";
import { IIGA, IIGAHotSpot } from "../../models/IIGA";
import { blankHotSpot } from "../../helpers/initialSteps";

type CanvasType = {
  childDims: DOMRect;
  currentHotSpot?: IIGAHotSpot;
  currentStep: IIGA;
  handleHotspotChange: (hotSpot: IIGAHotSpot, childDims: DOMRect) => void;
  setHotSpot: (hotSpot: IIGAHotSpot) => void;
};

const HotSpotCanvas = (props: CanvasType) => {
  const { childDims, currentHotSpot, currentStep, handleHotspotChange, setHotSpot } = props;
  const canvasRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const [isDragging, setIsDragging]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [isLoading, setIsLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(true);
  const [newHotSpot, setNewHotSpot]: [IIGAHotSpot, Dispatch<SetStateAction<IIGAHotSpot>>] =
    useState<IIGAHotSpot>(blankHotSpot);
  const { height, width } = childDims;
  const hsCopy = _.cloneDeep(newHotSpot);
  let canvas = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;

  useEffect(() => {
    function doLoad() {
      if (typeof currentHotSpot !== "string" && currentHotSpot) {
        setNewHotSpot(currentHotSpot);
      }
      setIsLoading(false);
    }
    doLoad();
  }, [childDims]);

  useEffect(() => {
    if (currentHotSpot) {
      canvas = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;
      canvas?.clearRect(0, 0, width, height);
      if (canvas) {
        canvas.strokeStyle = "#adff2f";
        canvas.lineWidth = 3;
        canvas.setLineDash([10, 10]);
        //box shadow
        canvas.shadowColor = "rgba(0,0,0,0.4)";
        canvas.shadowBlur = 4;
        canvas.shadowOffsetY = 2;
      }
      canvas?.strokeRect(
        currentHotSpot.left,
        currentHotSpot.top,
        currentHotSpot.right - currentHotSpot.left,
        currentHotSpot.bottom - currentHotSpot.top,
      );

    }
  }, [childDims, currentStep]);

  useEffect(() => {
    if (!isLoading) {
      canvas = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;
      canvas?.clearRect(0, 0, width, height);
      canvas.strokeStyle = "#adff2f";
      canvas.lineWidth = 3;
      canvas.setLineDash([10, 10]);
      //box shadow
      canvas.shadowColor = "rgba(0,0,0,0.4)";
      canvas.shadowBlur = 4;
      canvas.shadowOffsetY = 2;
      canvas?.strokeRect(
        newHotSpot.left,
        newHotSpot.top,
        newHotSpot.right - newHotSpot.left,
        newHotSpot.bottom - newHotSpot.top,
      );
    }
  }, [isLoading]);

  const handleDrag = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (isDragging) {
      canvas.strokeStyle = "#adff2f";
      canvas.lineWidth = 3;
      canvas.setLineDash([10, 10]);
      //box shadow
      canvas.shadowColor = "rgba(0,0,0,0.4)";
      canvas.shadowBlur = 4;
      canvas.shadowOffsetY = 2;
      hsCopy.bottom = parseFloat((e.clientY - childDims.top).toFixed(2));
      hsCopy.right = parseFloat((e.clientX - childDims.left).toFixed(2));

      canvas.clearRect(0, 0, width, height);
      canvas.strokeRect(hsCopy.left, hsCopy.top, hsCopy.right - hsCopy.left, hsCopy.bottom - hsCopy.top);
      // canvas.fillRect(hsCopy.left, hsCopy.top, hsCopy.right - hsCopy.left, hsCopy.bottom - hsCopy.top);
      setNewHotSpot(hsCopy);
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    e.stopPropagation();
    hsCopy.top = parseFloat((e.clientY - childDims.top).toFixed(2));
    hsCopy.left = parseFloat((e.clientX - childDims.left).toFixed(2));

    setNewHotSpot(hsCopy);
    setIsDragging(true);
  };

  const handleDragEnd = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    e.stopPropagation();
    // canvas.fillStyle = 'rgba(159, 255, 10, .5)';
    canvas.strokeStyle = "#adff2f";
    canvas.lineWidth = 3;
    canvas.setLineDash([10, 10]);
    //box shadow
    canvas.shadowColor = "rgba(0,0,0,0.4)";
    canvas.shadowBlur = 4;
    canvas.shadowOffsetY = 2;
    canvas.clearRect(0, 0, width, height);
    canvas.strokeRect(hsCopy.left, hsCopy.top, hsCopy.right - hsCopy.left, hsCopy.bottom - hsCopy.top);
    // canvas.fillRect(hsCopy.left, hsCopy.top, hsCopy.right - hsCopy.left, hsCopy.bottom - hsCopy.top);
    setIsDragging(false);
    handleHotspotChange(hsCopy, childDims);
    setHotSpot(hsCopy);
  };

  if (isLoading) {
    return <></>;
  } else {
    return (
      <canvas
        id="iga-canvas"
        className="iga-addGraphicCanvas"
        ref={canvasRef}
        width={width}
        height={height}
        onMouseDown={(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => handleMouseDown(e)}
        onMouseUp={(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => handleDragEnd(e)}
        onMouseMove={(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => handleDrag(e)}
      />
    );
  }
};

export default HotSpotCanvas;
