export const colors: Array<string> = [
  "#FFFF00",
  "#FF0000",
  "#FFC0CB",
  "#00FF00",
  "#808080",
  "#00FFFF",
  "#A8B8C8",
  "#FFBF00",
  "#FF9900",
  "#FF00FF",
  "#00B500",
  "#969DA6",
  "#00CCCC",
  "#0000FF",
  "#F2DB18",
  "#CC0000",
  "#990099",
  "#72BE44",
  "#DADBDD",
  "#0091FF",
  "#283D55",
  "#CC7722",
  "#7C0A02",
  "#5E36FF",
  "#9FFF0A",
  "#4B4F58",
  "#FFFFFF",
  "#000000",
];
export const bgColors: Array<string> = [
  "#FFFF00",
  "#FF0000",
  "#FFC0CB",
  "#00FF00",
  "#808080",
  "#00FFFF",
  "#A8B8C8",
  "#FFBF00",
  "#FF9900",
  "#FF00FF",
  "#00B500",
  "#969DA6",
  "#00CCCC",
  "#0000FF",
  "#F2DB18",
  "#CC0000",
  "#990099",
  "#72BE44",
  "#DADBDD",
  "#0091FF",
  "#283D55",
  "#CC7722",
  "#7C0A02",
  "#5E36FF",
  "#9FFF0A",
  "#4B4F58",
  "#FFFFFF",
  "#000000",
  "transparent",
];
