import React from "react";
import { Frame } from "./Frame";
import { ObjectActionsType, useObjectsDispatch } from "../../../../../contexts/ObjectsProvider";
import { Frame as FrameType } from "../../../../../types";

export function FramesC({
  frames,
  start,
  end,
  objectId,
}: {
  frames: FrameType[];
  start: number;
  end: number | null;
  objectId: string;
}) {
  const objectsDispatch = useObjectsDispatch();
  return (
    <>
      {frames.map((frame: FrameType) => (
        <Frame
          key={frame.id}
          start={start}
          end={end}
          frame={frame}
          onDragEnd={({ timestamp }) => {
            // update the object with the new frame
            objectsDispatch({
              type: ObjectActionsType.UPDATE_OBJECT_FRAME,
              payload: {
                frame: {
                  id: frame.id,
                  timestamp: timestamp ?? frame.timestamp,
                },
                objectId,
              },
            });
          }}
        />
      ))}
    </>
  );
}

// forward ref of the Frames component
export const FramesFR = React.forwardRef<
  HTMLDivElement,
  {
    frames: FrameType[];
    start: number;
    end: number | null;
    objectId: string;
  }
>((props, ref) => {
  return (
    <div
      className="frames"
      ref={ref}
      style={{
        pointerEvents: "none",
        width: "100%",
        height: "100%",
      }}
    >
      <FramesC {...props} />
    </div>
  );
});
FramesFR.displayName = "FramesFR";
