import React from "react";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { TOP_STRIP_HEIGHT } from "../TimelinePanel";
import StampsGrid from "./StampsGrid";
import "./TopStrip.css";
export default function TopStrip() {
  const [timeline, timelineDispatch] = useTimeline();
  return (
    <div
      className="top-strip"
      style={{
        height: TOP_STRIP_HEIGHT,
      }}
    >
      <StampsGrid />
    </div>
  );
}
