import React, { useLayoutEffect, useRef, useState } from "react";

import {
  clippedSpaceFromUnitSpace,
  formatFullUnitForGrid,
  formatSubUnitForGrid,
  snapToGrid,
  useTimeline,
} from "../../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../../hooks/useDomRef";

import { zIndexes } from "../TimelinePanel";

import createGrid from "./createGrid";
import "./StampsGrid.css";
export default function StampsGrid() {
  const [timeline, timelineDispatch] = useTimeline();
  const {
    clippedSpace,
    widthInPx,
    scaledSpace: { leftPadding },
    sequenceLength,
    subUnitsPerUnit,
  } = timeline;
  const containerRef = useRef<HTMLDivElement>(null);
  const [fullSecondStampsContainer, setFullSecondsStampContainer] = useDomRef();
  useLayoutEffect(() => {
    if (!fullSecondStampsContainer) return;
    drawStamps({
      fps: subUnitsPerUnit,
      clippedSpaceRange: clippedSpace.range,
      clippedSpaceWidth: widthInPx,
      fullSecondStampsContainer,
      leftPadding: leftPadding,
    });
  }, [widthInPx, clippedSpace, subUnitsPerUnit, leftPadding, fullSecondStampsContainer]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        pointerEvents: "none",
        width: widthInPx,
        zIndex: zIndexes.currentFrameStamp,
      }}
    >
      <div className="the-stamps" style={{ width: widthInPx + "px" }}>
        <div className="full-second-stamps-container" ref={setFullSecondsStampContainer}></div>
      </div>
    </div>
  );
}

function drawStamps(options: {
  fullSecondStampsContainer: HTMLDivElement;
  fps: number;
  clippedSpaceWidth: number;
  leftPadding: number;
  clippedSpaceRange: { start: number; end: number };
}) {
  const opts = {
    clippedSpaceRange: options.clippedSpaceRange,
    clippedSpaceWidth: options.clippedSpaceWidth,
    fps: options.fps,
  };
  let innerHTML = "";
  createGrid(opts, (_posInUnitSpace, isFullUnit) => {
    const posInUnitSpace = snapToGrid(_posInUnitSpace, opts.fps);
    const posInClippedSpace = clippedSpaceFromUnitSpace(
      posInUnitSpace,
      options.leftPadding,
      { range: options.clippedSpaceRange },
      options.clippedSpaceWidth,
    );

    if (isFullUnit) {
      innerHTML += createStampClass(formatFullUnitForGrid(posInUnitSpace, opts.fps), posInClippedSpace, "full-unit");
    } else {
      innerHTML += createStampClass(formatSubUnitForGrid(posInUnitSpace, opts.fps), posInClippedSpace, "sub-unit");
    }
  });
  options.fullSecondStampsContainer.innerHTML = innerHTML;
}

function createStampClass(pos: string, x: number, type: "full-unit" | "sub-unit") {
  return `<span class="${type}" style="transform: translate3d(${x.toFixed(1)}px, -50%, 0);">${pos}</span>`;
}
