import React, { Dispatch, SetStateAction, KeyboardEvent, useEffect, useRef, useState, MouseEvent } from "react";
import { AnnotationProps, SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";
import { useMovableElementsPlaneState } from "../../../contexts/MovableElementsPlaneProvider";

export function SVGCirlce({
  x,
  y,
  objectId,
  onMouseDown,
  isDisplayed,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
}: SVGAnnotationProps) {
  const circleRef: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);

  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  const ellipseRef: React.RefObject<SVGEllipseElement> = useRef<SVGEllipseElement>(null);

  return (
    <svg
      // viewBox='0 0 100 auto'
      data-objectid={objectId}
      width="100"
      id={`circle-${objectId}`}
      name="circle"
      className="annotation"
      onMouseDown={(e: MouseEvent<SVGSVGElement>) => {
        onMouseDown(e, objectId);
      }}
      preserveAspectRatio="xMinYMin meet"
      tabIndex={0}
      style={{
        width: `${width.toFixed(2)}px`,
        height: `${height.toFixed(2)}px`,
        transform: transformString,
        zIndex: zIndex,
        opacity: isDisplayed ? opacity : 0.5,
        filter: isDisplayed ? "none" : "grayScale(100%)",
        // transform:`rotate(${annotation?.transform?.rotate?.toFixed(2)}deg)`
      }}
      ref={circleRef}
    >
      <HighlightAnnotation objectId={objectId} />
      <ellipse
        ref={ellipseRef}
        cx={width / 2}
        cy={height / 2}
        rx={width / 2 - 3 > 0 ? width / 2 - 3 : width / 2}
        ry={height / 2 - 3 > 0 ? height / 2 - 3 : height / 2}
        stroke={borderColor ? borderColor : "white"}
        fill={`${backgroundColor}` || "transparent"}
        strokeWidth={`${strokeWidth || "4"}`}
      ></ellipse>
    </svg>
  );
}
