import React, { useEffect, useLayoutEffect, useState } from "react";
import { useGlobalAudioState } from "../../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { TOP_STRIP_HEIGHT, WAVE_HEIGHT } from "../TimelinePanel";
import "./Left.css";
import { ReactComponent as X } from "../../../assets/icons/Common/remove-tag-icon.svg";
import { ReactComponent as Ghost } from "../../../assets/icons/timeline/ghost.svg";
import useRefAndState from "../../../hooks/useRefAndState";
import { useReceiveVerticalWheelEvent } from "../VerticalScrollContainer";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
const listenerOptions = {
  capture: true,
  passive: false,
};
export default function Left() {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const { audioOnPageExists } = useGlobalAudioState();
  const [ref, state] = useRefAndState<HTMLDivElement | null>(null);
  const receiveVerticalWheelEvent = useReceiveVerticalWheelEvent();

  // const filteredHotspots = hotspots?.filter((hp) => hp.delay);

  useLayoutEffect(() => {
    if (!state) {
      return;
    }
    const handleWheel = (e: WheelEvent) => {
      receiveVerticalWheelEvent(e);
      e.preventDefault();
      e.stopPropagation();
    };
    state.addEventListener("wheel", handleWheel, listenerOptions);

    return () => {
      state.removeEventListener("wheel", handleWheel, listenerOptions);
    };
  }, [state]);

  if (!objectsState.animatedObjects) {
    return null;
  }

  const audioMargin = audioOnPageExists ? WAVE_HEIGHT : 0;
  // TODO: this could be done each time we update the objects instead of each render
  const zIndexMap = Object.fromEntries(objectsState.objectList.map((o) => [o.objectId, o.zIndex ?? 0]));
  return (
    <div
      ref={ref}
      className="left"
      style={{
        position: "absolute",
        width: "30%",
        height: "100%",
        left: 0,
        zIndex: 2,
        borderRight: "1px solid gray",
        pointerEvents: "auto",
        cursor: "default",
      }}
    >
      <div
        style={{
          marginTop: TOP_STRIP_HEIGHT + audioMargin,
          paddingBottom: "10px",
        }}
      >
        <ul className="left-obj-list">
          {!!objectsState.animatedObjects.length &&
            [...objectsState.animatedObjects]
              .sort(
                // sort by arrangement
                (a, b) => zIndexMap[b.id] - zIndexMap[a.id],
              )
              .map((animatedObject) => {
                const obj = objectsState.objectList.find((o) => o.objectId === animatedObject.id);
                const objName = obj?.name ?? "";
                const objDisplayName = obj?.displayName ?? "";
                const isGhost = obj?.ghost ?? false;
                return (
                  <li
                    key={animatedObject.id + "object-in-timeline"}
                    className="object-in-timeline "
                    style={{ height: 28 }}
                    onClick={(e) => {
                      if (!animatedObject.id) return;
                      let type = ObjectActionsType.SET_SELECTED_OBJECT;
                      if (e.ctrlKey) {
                        type = ObjectActionsType.ADD_SELECTED_OBJECT;
                      }
                      objectsDispatch({
                        type,
                        payload: {
                          objectId: animatedObject.id,
                          domRef: e.currentTarget,
                        },
                      });
                    }}
                  >
                    <div className="w-1/4">{(objDisplayName ? objDisplayName : objName) ?? ""}</div>
                    <div className="w-3/4 flex justify-end items-center">
                      <span
                        className="h-full"
                        style={{ pointerEvents: "all", cursor: "pointer" }}
                        onClick={() => {
                          objectsDispatch({
                            type: ObjectActionsType.TOGGLE_OBJECT_GHOST,
                            objectId: animatedObject.id,
                          });
                        }}
                      >
                        <Ghost className={isGhost ? "ghost-icon-on" : ""} />
                      </span>
                      <span
                        style={{ pointerEvents: "all", cursor: "pointer" }}
                        onClick={() => {
                          objectsDispatch({
                            type: ObjectActionsType.DELETE_ANIMATED_OBJECT,
                            objectId: animatedObject.id,
                          });
                        }}
                      >
                        <X />
                      </span>
                    </div>
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
}

/**
 *
 */
