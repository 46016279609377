import React, { useEffect, useMemo, useRef } from "react";

import useDrag from "../../../hooks/useDrag";
// import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ReactComponent as Arrow } from "../../../assets/icons/timeline/audio-length-arrow-white.svg";

import "./SequenceLengthIndicator.css";
import { useDomRef } from "../../../hooks/useDomRef";

import {
  useTimeline,
  clippedSpaceFromUnitSpace,
  scaledSpaceToUnitSpace,
} from "../../../contexts/TimelineProvider/TimelineProvider";
import { useGlobalAudioState } from "../../../contexts/GlobalAudioProvider/GlobalAudioProvider";
import { OBJECT_ROW_HEIGHT, TOP_STRIP_HEIGHT, WAVE_HEIGHT } from "../TimelinePanel";
import { useInteractivityBuilderState } from "../../../contexts/InteractivityBuilderProvider";
import { LessonPagesActions, useLessonPagesDispatch } from "../../../contexts/LessonPagesProvider/LessonPagesProvider";

const RENDER_OUT_OF_VIEW_X = -10000;
const coverWidth = 1000;

export default function SequenceLengthIndicator() {
  const [ref, setRef] = useDomRef();
  const { audioLength, audioOnPageExists } = useGlobalAudioState();
  const [timeline, setTimeline] = useTimeline();
  const [isDragging] = useDragBulge(ref);
  const {
    clippedSpace,
    widthInPx,
    scaledSpace: { leftPadding },
    sequenceLength,
    sequencedObjectsAmount,
  } = timeline;
  if (audioLength > sequenceLength) {
  }
  useEffect(() => {}, [audioLength, sequenceLength]);
  const startInUnitSpace = sequenceLength;
  let startX = clippedSpaceFromUnitSpace(startInUnitSpace, leftPadding, clippedSpace, widthInPx);
  const endX = widthInPx;
  let scaleX: number, translateX: number;
  if (startX > endX) {
    translateX = 0;
    scaleX = 0;
  } else {
    if (startX < 0) {
      startX = 0;
    }
    translateX = startX;
    scaleX = (endX - startX) / coverWidth;
  }

  const draggerHeight = OBJECT_ROW_HEIGHT * sequencedObjectsAmount;
  const top = `${TOP_STRIP_HEIGHT + (audioOnPageExists ? WAVE_HEIGHT : 0)}px`;
  return (
    <>
      <div
        className={isDragging ? "dragging strip" : "strip"}
        ref={setRef}
        style={{
          top,
          height: draggerHeight,
          minHeight: OBJECT_ROW_HEIGHT,
          width: 20,
          transform: `translateX(${translateX === 0 ? RENDER_OUT_OF_VIEW_X : translateX}px)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Arrow />
      </div>
      {/* <div
        className="cover"
        style={{
          minHeight: OBJECT_ROW_HEIGHT,
          height: draggerHeight,
          top,
          width: startX,
        }}
      ></div> */}
    </>
  );
}
function useDragBulge(node: HTMLDivElement | null): [boolean] {
  const [timeline, setTimeline] = useTimeline();
  const { interactivityBuilderInstance } = useInteractivityBuilderState();
  const lessonDispatch = useLessonPagesDispatch();
  const { audioLength } = useGlobalAudioState();
  const { clippedSpace, widthInPx, sequenceLength } = timeline;
  const gestureHandlers = useMemo<Parameters<typeof useDrag>[1]>(() => {
    return {
      debugName: "LengthIndicator/useDragBulge",
      lockCSSCursorTo: "ew-resize",
      onDragStart() {
        const initialLength = sequenceLength;
        let finalUnitPosition: number;
        return {
          onDrag(dx) {
            const deltaInUnits = scaledSpaceToUnitSpace(dx, clippedSpace, widthInPx);
            finalUnitPosition = initialLength + deltaInUnits;
            if (finalUnitPosition < audioLength) {
              finalUnitPosition = audioLength;
            }
            setTimeline({
              type: "SET_SEQUENCE_LENGTH",
              payload: finalUnitPosition,
            });
          },
          onDragEnd(dragHappened) {
            if (!dragHappened || typeof finalUnitPosition === "undefined") {
              return;
            }
            console.log("onDragEnd - SequenceLengthIndicator");
            const snappedFinal = Math.round(finalUnitPosition);
            console.log("SET_SEQUENCE_LENGTH", snappedFinal);
            setTimeline({
              type: "SET_SEQUENCE_LENGTH",
              payload: snappedFinal,
            });
            lessonDispatch({
              type: LessonPagesActions.UPDATE_SEQUENCE_LENGTH,
              payload: snappedFinal,
            });
          },
        };
      },
    };
  }, [sequenceLength, widthInPx, clippedSpace.range.start, clippedSpace.range.end, audioLength]);

  return useDrag(node, gestureHandlers);
  // useLockFrameStampPosition(isDragging, -1)
}
